export const BadgeDifficultyType = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
};

export const badgeDifficultyTypes = [
  BadgeDifficultyType.BEGINNER,
  BadgeDifficultyType.INTERMEDIATE,
  BadgeDifficultyType.ADVANCED,
];
