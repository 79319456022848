export default {
  '(go live)': {
    en: '(go live)',
    et: '(go live)',
    ja: '(go live)',
    lt: '(go live)',
    ch: '(go live)',
    ph: '(go live)',
  },
  '*You are not authorized to edit this form.': {
    en: '*You are not authorized to edit this form.',
    et: '*You are not authorized to edit this form.',
    ja: '*You are not authorized to edit this form.',
    lt: '*You are not authorized to edit this form.',
    ch: '*You are not authorized to edit this form.',
    ph: '*You are not authorized to edit this form.',
  },
  'Add Option': {
    en: 'Add Option',
    et: 'Add Option',
    ja: 'Add Option',
    lt: 'Add Option',
    ch: 'Add Option',
    ph: 'Add Option',
  },
  'Add Question': {
    en: 'Add Question',
    et: 'Add Question',
    ja: 'Add Question',
    lt: 'Add Question',
    ch: 'Add Question',
    ph: 'Add Question',
  },
  'All Events': {
    en: 'All Events',
    et: 'All Events',
    ja: 'All Events',
    lt: 'All Events',
    ch: 'All Events',
    ph: 'All Events',
  },
  'All days are selected': {
    en: 'All days are selected',
    et: 'All days are selected',
    ja: 'All days are selected',
    lt: 'All days are selected',
    ch: 'All days are selected',
    ph: 'All days are selected',
  },
  Approved: {
    en: 'Approved',
    et: 'Approved',
    ja: 'Approved',
    lt: 'Approved',
    ch: 'Approved',
    ph: 'Approved',
  },
  'Are you sure you want to delete this event?': {
    en: 'Are you sure you want to delete this event?',
    et: 'Are you sure you want to delete this event?',
    ja: 'Are you sure you want to delete this event?',
    lt: 'Are you sure you want to delete this event?',
    ch: 'Are you sure you want to delete this event?',
    ph: 'Are you sure you want to delete this event?',
  },
  'Event Data': {
    en: 'Event Data',
    et: 'Event Data',
    ja: 'Event Data',
    lt: 'Event Data',
    ch: 'Event Data',
    ph: 'Event Data',
  },
  'Event Date': {
    en: 'Event Date',
    et: 'Event Date',
    ja: 'Event Date',
    lt: 'Event Date',
    ch: 'Event Date',
    ph: 'Event Date',
  },
  'Event added': {
    en: 'Event added',
    et: 'Event added',
    ja: 'Event added',
    lt: 'Event added',
    ch: 'Event added',
    ph: 'Event added',
  },
  'Event deleted': {
    en: 'Event deleted',
    et: 'Event deleted',
    ja: 'Event deleted',
    lt: 'Event deleted',
    ch: 'Event deleted',
    ph: 'Event deleted',
  },
  'Event modified': {
    en: 'Event modified',
    et: 'Event modified',
    ja: 'Event modified',
    lt: 'Event modified',
    ch: 'Event modified',
    ph: 'Event modified',
  },
  'Event Name': {
    en: 'Event Name',
    et: 'Event Name',
    ja: 'Event Name',
    lt: 'Event Name',
    ch: 'Event Name',
    ph: 'Event Name',
  },
  Branch: {
    en: 'Branch',
    et: 'Branch',
    ja: 'Branch',
    lt: 'Branch',
    ch: 'Branch',
    ph: 'Branch',
  },
  Cancel: {
    en: 'Cancel',
    ja: 'キャンセル',
    et: 'Tühista',
    lt: 'Cancel',
    ch: 'Cancel',
    ph: 'Cancel',
  },
  Cancelled: {
    en: 'Cancelled',
    et: 'Cancelled',
    ja: 'Cancelled',
    lt: 'Cancelled',
    ch: 'Cancelled',
    ph: 'Cancelled',
  },
  'Checkout Form': {
    en: 'Checkout Form',
    et: 'Checkout Form',
    ja: 'Checkout Form',
    lt: 'Checkout Form',
    ch: 'Checkout Form',
    ph: 'Checkout Form',
  },
  'Clear Search': {
    en: 'Clear Search',
    et: 'Clear Search',
    ja: 'Clear Search',
    lt: 'Clear Search',
    ch: 'Clear Search',
    ph: 'Clear Search',
  },
  'Content is malformed. Please tell a crew member about this': {
    en: 'Content is malformed. Please tell a crew member about this',
    et: 'Content is malformed. Please tell a crew member about this',
    ja: 'Content is malformed. Please tell a crew member about this',
    lt: 'Content is malformed. Please tell a crew member about this',
    ch: 'Content is malformed. Please tell a crew member about this',
    ph: 'Content is malformed. Please tell a crew member about this',
  },
  'Create an event session': {
    en: 'Create an event session',
    et: 'Create an event session',
    ja: 'Create an event session',
    lt: 'Create an event session',
    ch: 'Create an event session',
    ph: 'Create an event session',
  },
  'Customise Emails': {
    en: 'Customise Emails',
    et: 'Customise Emails',
    ja: 'Customise Emails',
    lt: 'Customise Emails',
    ch: 'Customise Emails',
    ph: 'Customise Emails',
  },
  'Danger Zone': {
    en: 'Danger Zone',
    et: 'Danger Zone',
    ja: 'Danger Zone',
    lt: 'Danger Zone',
    ch: 'Danger Zone',
    ph: 'Danger Zone',
  },
  Day: {
    en: 'Day',
    et: 'Day',
    ja: 'Day',
    lt: 'Day',
    ch: 'Day',
    ph: 'Day',
  },
  daysInWeek: {
    en: 'Days of Week',
    et: 'Days of Week',
    ja: 'Days of Week',
    lt: 'Days of Week',
    ch: 'Days of Week',
    ph: 'Days of Week',
  },
  bulkCreate: {
    en: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
    et: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
    ja: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
    lt: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
    ch: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
    ph: 'To bulk create events, please specify start and end date, together with the days in a week of the recurring events',
  },
  'Days selected cannot be 0': {
    en: 'Days selected cannot be 0',
    et: 'Days selected cannot be 0',
    ja: 'Days selected cannot be 0',
    lt: 'Days selected cannot be 0',
    ch: 'Days selected cannot be 0',
    ph: 'Days selected cannot be 0',
  },
  Delete: {
    en: 'Delete',
    et: 'Delete',
    ja: 'Delete',
    lt: 'Delete',
    ch: 'Delete',
    ph: 'Delete',
  },
  'Delete Event': {
    en: 'Delete Event',
    et: 'Delete Event',
    ja: 'Delete Event',
    lt: 'Delete Event',
    ch: 'Delete Event',
    ph: 'Delete Event',
  },
  'Deletes this event and': {
    en: 'Deletes this event and',
    et: 'Deletes this event and',
    ja: 'Deletes this event and',
    lt: 'Deletes this event and',
    ch: 'Deletes this event and',
    ph: 'Deletes this event and',
  },
  'all bookings': {
    en: 'all bookings',
    et: 'all bookings',
    ja: 'all bookings',
    lt: 'all bookings',
    ch: 'all bookings',
    ph: 'all bookings',
  },
  'for this event!': {
    en: 'for this event!',
    et: 'for this event!',
    ja: 'for this event!',
    lt: 'for this event!',
    ch: 'for this event!',
    ph: 'for this event!',
  },
  'Delete this event?': {
    en: 'Delete this event?',
    et: 'Delete this event?',
    ja: 'Delete this event?',
    lt: 'Delete this event?',
    ch: 'Delete this event?',
    ph: 'Delete this event?',
  },
  Done: {
    en: 'Done',
    et: 'Done',
    ja: 'Done',
    lt: 'Done',
    ch: 'Done',
    ph: 'Done',
  },
  Drafts: {
    en: 'Drafts',
    et: 'Drafts',
    ja: 'Drafts',
    lt: 'Drafts',
    ch: 'Drafts',
    ph: 'Drafts',
  },
  Duration: {
    en: 'Duration',
    et: 'Duration',
    ja: 'Duration',
    lt: 'Duration',
    ch: 'Duration',
    ph: 'Duration',
  },
  'Duration (in hours)': {
    en: 'Duration (in hours)',
    et: 'Duration (in hours)',
    ja: 'Duration (in hours)',
    lt: 'Duration (in hours)',
    ch: 'Duration (in hours)',
    ph: 'Duration (in hours)',
  },
  'Duration of event cannot be less than or equal to 0 hours': {
    en: 'Duration of event cannot be less than or equal to 0 hours',
    et: 'Duration of event cannot be less than or equal to 0 hours',
    ja: 'Duration of event cannot be less than or equal to 0 hours',
    lt: 'Duration of event cannot be less than or equal to 0 hours',
    ch: 'Duration of event cannot be less than or equal to 0 hours',
    ph: 'Duration of event cannot be less than or equal to 0 hours',
  },
  Edit: {
    en: 'Edit',
    et: 'Muuda',
    ja: '編集',
    lt: 'Edit',
    ch: 'Edit',
    ph: 'Edit',
  },
  'Edit an event session': {
    en: 'Edit an event session',
    et: 'Edit an event session',
    ja: 'Edit an event session',
    lt: 'Edit an event session',
    ch: 'Edit an event session',
    ph: 'Edit an event session',
  },
  'Email Templates': {
    en: 'Email Templates',
    et: 'Email Templates',
    ja: 'Email Templates',
    lt: 'Email Templates',
    ch: 'Email Templates',
    ph: 'Email Templates',
  },
  'End Date': {
    en: 'End Date',
    et: 'End Date',
    ja: 'End Date',
    lt: 'End Date',
    ch: 'End Date',
    ph: 'End Date',
  },
  'Enter emails separated by a comma': {
    en: 'Enter emails separated by a comma',
    et: 'Enter emails separated by a comma',
    ja: 'Enter emails separated by a comma',
    lt: 'Enter emails separated by a comma',
    ch: 'Enter emails separated by a comma',
    ph: 'Enter emails separated by a comma',
  },
  'Enter option': {
    en: 'Enter option',
    et: 'Enter option',
    ja: 'Enter option',
    lt: 'Enter option',
    ch: 'Enter option',
    ph: 'Enter option',
  },
  Event: {
    en: 'Event',
    et: 'Event',
    ja: 'Event',
    lt: 'Event',
    ch: 'Event',
    ph: 'Event',
  },
  'Event Name (Compulsory)': {
    en: 'Event Name (Compulsory)',
    et: 'Event Name (Compulsory)',
    ja: 'Event Name (Compulsory)',
    lt: 'Event Name (Compulsory)',
    ch: 'Event Name (Compulsory)',
    ph: 'Event Name (Compulsory)',
  },
  'Export All User Responses': {
    en: 'Export All User Responses',
    et: 'Export All User Responses',
    ja: 'Export All User Responses',
    lt: 'Export All User Responses',
    ch: 'Export All User Responses',
    ph: 'Export All User Responses',
  },
  'Export Approved User Responses': {
    en: 'Export Approved User Responses',
    et: 'Export Approved User Responses',
    ja: 'Export Approved User Responses',
    lt: 'Export Approved User Responses',
    ch: 'Export Approved User Responses',
    ph: 'Export Approved User Responses',
  },
  'Export All Checkout Responses': {
    en: 'Export All Checkout Responses',
    et: 'Export All Checkout Responses',
    ja: 'Export All Checkout Responses',
    lt: 'Export All Checkout Responses',
    ch: 'Export All Checkout Responses',
    ph: 'Export All Checkout Responses',
  },
  'Free Flow': {
    en: 'Free Flow',
    et: 'Free Flow',
    ja: 'Free Flow',
    lt: 'Free Flow',
    ch: 'Free Flow',
    ph: 'Free Flow',
  },
  'Tinker Time': {
    en: 'Tinker Time',
    et: 'Tinker Time',
    ja: 'Tinker Time',
    lt: 'Tinker Time',
    ch: 'Tinker Time',
    ph: 'Tinker Time',
  },
  'Free Flow Session': {
    en: 'Free Flow Session',
    et: 'Free Flow Session',
    ja: 'Free Flow Session',
    lt: 'Free Flow Session',
    ch: 'Free Flow Session',
    ph: 'Free Flow Session',
  },
  'Free Flow @ VIVISTOP': {
    en: 'Free Flow @ VIVISTOP',
    et: 'Free Flow @ VIVISTOP',
    ja: 'Free Flow @ VIVISTOP',
    lt: 'Free Flow @ VIVISTOP',
    ch: 'Free Flow @ VIVISTOP',
    ph: 'Free Flow @ VIVISTOP',
  },
  'Upcoming Events': {
    en: 'Upcoming Events',
    et: 'Upcoming Events',
    ja: 'Upcoming Events',
    lt: 'Upcoming Events',
    ch: 'Upcoming Events',
    ph: 'Upcoming Events',
  },
  'Invalid crew email': {
    en: 'Invalid crew email',
    et: 'Invalid crew email',
    ja: 'Invalid crew email',
    lt: 'Invalid crew email',
    ch: 'Invalid crew email',
    ph: 'Invalid crew email',
  },
  'Invalid event questions': {
    en: 'Invalid event questions',
    et: 'Invalid event questions',
    ja: 'Invalid event questions',
    lt: 'Invalid event questions',
    ch: 'Invalid event questions',
    ph: 'Invalid event questions',
  },
  'Is this a workshop?': {
    en: 'Is this a workshop?',
    et: 'Is this a workshop?',
    ja: 'Is this a workshop?',
    lt: 'Is this a workshop?',
    ch: 'Is this a workshop?',
    ph: 'Is this a workshop?',
  },
  firstComeFirstServe: {
    en: ' Is this event first-come-first-serve? (auto approval)',
    et: ' Is this event first-come-first-serve? (auto approval)',
    ja: ' Is this event first-come-first-serve? (auto approval)',
    lt: ' Is this event first-come-first-serve? (auto approval)',
    ch: ' Is this event first-come-first-serve? (auto approval)',
    ph: ' Is this event first-come-first-serve? (auto approval)',
  },
  'Is this workshop online?': {
    en: 'Is this workshop online?',
    et: 'Is this workshop online?',
    ja: 'Is this workshop online?',
    lt: 'Is this workshop online?',
    ch: 'Is this workshop online?',
    ph: 'Is this workshop online?',
  },
  'Maximum Slots': {
    en: 'Maximum Slots',
    et: 'Maximum Slots',
    ja: 'Maximum Slots',
    lt: 'Maximum Slots',
    ch: 'Maximum Slots',
    ph: 'Maximum Slots',
  },
  'Maximum slots cannot be 0': {
    en: 'Maximum slots cannot be 0',
    et: 'Maximum slots cannot be 0',
    ja: 'Maximum slots cannot be 0',
    lt: 'Maximum slots cannot be 0',
    ch: 'Maximum slots cannot be 0',
    ph: 'Maximum slots cannot be 0',
  },
  "Members' responses downloaded as a CSV file": {
    en: "Members' responses downloaded as a CSV file",
    et: "Members' responses downloaded as a CSV file",
    ja: "Members' responses downloaded as a CSV file",
    lt: "Members' responses downloaded as a CSV file",
    ch: "Members' responses downloaded as a CSV file",
    ph: "Members' responses downloaded as a CSV file",
  },
  'New Event': {
    en: 'New Event',
    et: 'New Event',
    ja: 'New Event',
    lt: 'New Event',
    ch: 'New Event',
    ph: 'New Event',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  'No event was added. Please check the days selected': {
    en: 'No event was added. Please check the days selected',
    et: 'No event was added. Please check the days selected',
    ja: 'No event was added. Please check the days selected',
    lt: 'No event was added. Please check the days selected',
    ch: 'No event was added. Please check the days selected',
    ph: 'No event was added. Please check the days selected',
  },
  'No days selected': {
    en: 'No days selected',
    et: 'No days selected',
    ja: 'No days selected',
    lt: 'No days selected',
    ch: 'No days selected',
    ph: 'No days selected',
  },
  'Notify the crew below upon event registration and cancellation (optional)': {
    en: 'Notify the crew below upon event registration and cancellation (optional)',
    et: 'Notify the crew below upon event registration and cancellation (optional)',
    ja: 'Notify the crew below upon event registration and cancellation (optional)',
    lt: 'Notify the crew below upon event registration and cancellation (optional)',
    ch: 'Notify the crew below upon event registration and cancellation (optional)',
    ph: 'Notify the crew below upon event registration and cancellation (optional)',
  },
  Options: {
    en: 'Options',
    et: 'Options',
    ja: 'Options',
    lt: 'Options',
    ch: 'Options',
    ph: 'Options',
  },
  'Please schedule a time to publish or uncheck schedule to publish': {
    en: 'Please schedule a time to publish or uncheck schedule to publish',
    et: 'Please schedule a time to publish or uncheck schedule to publish',
    ja: 'Please schedule a time to publish or uncheck schedule to publish',
    lt: 'Please schedule a time to publish or uncheck schedule to publish',
    ch: 'Please schedule a time to publish or uncheck schedule to publish',
    ph: 'Please schedule a time to publish or uncheck schedule to publish',
  },
  Publish: {
    en: 'Publish',
    et: 'Publish',
    ja: 'Publish',
    lt: 'Publish',
    ch: 'Publish',
    ph: 'Publish',
  },
  'Published on': {
    en: 'Published on',
    et: 'Published on',
    ja: 'Published on',
    lt: 'Published on',
    ch: 'Published on',
    ph: 'Published on',
  },
  'QUESTION MISSING': {
    en: 'QUESTION MISSING',
    et: 'QUESTION MISSING',
    ja: 'QUESTION MISSING',
    lt: 'QUESTION MISSING',
    ch: 'QUESTION MISSING',
    ph: 'QUESTION MISSING',
  },
  Question: {
    en: 'Question',
    et: 'Question',
    ja: 'Question',
    lt: 'Question',
    ch: 'Question',
    ph: 'Question',
  },
  Rejected: {
    en: 'Rejected',
    et: 'Rejected',
    ja: 'Rejected',
    lt: 'Rejected',
    ch: 'Rejected',
    ph: 'Rejected',
  },
  Reminder: {
    en: 'Reminder',
    et: 'Reminder',
    ja: 'Reminder',
    lt: 'Reminder',
    ch: 'Reminder',
    ph: 'Reminder',
  },
  Save: {
    en: 'Save',
    et: 'Salvesta',
    ja: '保存',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  'Save Draft': {
    en: 'Save Draft',
    et: 'Save Draft',
    ja: 'Save Draft',
    lt: 'Save Draft',
    ch: 'Save Draft',
    ph: 'Save Draft',
  },
  Search: {
    en: 'Search',
    et: 'Otsing',
    ja: '探す',
    lt: 'Search',
    ch: 'Search',
    ph: 'Search',
  },
  Select: {
    en: 'Select',
    et: 'Select',
    ja: 'Select',
    lt: 'Select',
    ch: 'Select',
    ph: 'Select',
  },
  'Select all': {
    en: 'Select all',
    et: 'Select all',
    ja: 'Select all',
    lt: 'Select all',
    ch: 'Select all',
    ph: 'Select all',
  },
  'Select days between start and end dates...': {
    en: 'Select days between start and end dates...',
    et: 'Select days between start and end dates...',
    ja: 'Select days between start and end dates...',
    lt: 'Select days between start and end dates...',
    ch: 'Select days between start and end dates...',
    ph: 'Select days between start and end dates...',
  },
  'Session will be available to members on:': {
    en: 'Session will be available to members on:',
    et: 'Session will be available to members on:',
    ja: 'Session will be available to members on:',
    lt: 'Session will be available to members on:',
    ch: 'Session will be available to members on:',
    ph: 'Session will be available to members on:',
  },
  'Slots Filled': {
    en: 'Slots Filled',
    et: 'Slots Filled',
    ja: 'Slots Filled',
    lt: 'Slots Filled',
    ch: 'Slots Filled',
    ph: 'Slots Filled',
  },
  'Start Date and Time': {
    en: 'Start Date and Time',
    et: 'Start Date and Time',
    ja: 'Start Date and Time',
    lt: 'Start Date and Time',
    ch: 'Start Date and Time',
    ph: 'Start Date and Time',
  },
  'Start date cannot be after end date': {
    en: 'Start date cannot be after end date',
    et: 'Start date cannot be after end date',
    ja: 'Start date cannot be after end date',
    lt: 'Start date cannot be after end date',
    ch: 'Start date cannot be after end date',
    ph: 'Start date cannot be after end date',
  },
  'The current event is not a workshop': {
    en: 'The current event is not a workshop',
    et: 'The current event is not a workshop',
    ja: 'The current event is not a workshop',
    lt: 'The current event is not a workshop',
    ch: 'The current event is not a workshop',
    ph: 'The current event is not a workshop',
  },
  'The name of the workshop is required': {
    en: 'The name of the workshop is required',
    et: 'The name of the workshop is required',
    ja: 'The name of the workshop is required',
    lt: 'The name of the workshop is required',
    ch: 'The name of the workshop is required',
    ph: 'The name of the workshop is required',
  },
  'This event is a workshop': {
    en: 'This event is a workshop',
    et: 'This event is a workshop',
    ja: 'This event is a workshop',
    lt: 'This event is a workshop',
    ch: 'This event is a workshop',
    ph: 'This event is a workshop',
  },
  'This event is not a workshop': {
    en: 'This event is not a workshop',
    et: 'This event is not a workshop',
    ja: 'This event is not a workshop',
    lt: 'This event is not a workshop',
    ch: 'This event is not a workshop',
    ph: 'This event is not a workshop',
  },
  'This event is not online': {
    en: 'This event is not online',
    et: 'This event is not online',
    ja: 'This event is not online',
    lt: 'This event is not online',
    ch: 'This event is not online',
    ph: 'This event is not online',
  },
  'This is a new event': {
    en: 'This is a new event',
    et: 'This is a new event',
    ja: 'This is a new event',
    lt: 'This is a new event',
    ch: 'This is a new event',
    ph: 'This is a new event',
  },
  'This workshop is online': {
    en: 'This workshop is online',
    et: 'This workshop is online',
    ja: 'This workshop is online',
    lt: 'This workshop is online',
    ch: 'This workshop is online',
    ph: 'This workshop is online',
  },
  Time: {
    en: 'Time',
    et: 'Time',
    ja: 'Time',
    lt: 'Time',
    ch: 'Time',
    ph: 'Time',
  },
  'To Be Released': {
    en: 'To Be Released',
    et: 'To Be Released',
    ja: 'To Be Released',
    lt: 'To Be Released',
    ch: 'To Be Released',
    ph: 'To Be Released',
  },
  'Total Slots': {
    en: 'Total Slots',
    et: 'Total Slots',
    ja: 'Total Slots',
    lt: 'Total Slots',
    ch: 'Total Slots',
    ph: 'Total Slots',
  },
  Type: {
    en: 'Type',
    et: 'Type',
    ja: 'Type',
    lt: 'Type',
    ch: 'Type',
    ph: 'Type',
  },
  'Upload Event Image (Recommended Image Size 720x480)': {
    en: 'Upload Event Image (Recommended Image Size 720x480)',
    et: 'Upload Event Image (Recommended Image Size 720x480)',
    ja: 'Upload Event Image (Recommended Image Size 720x480)',
    lt: 'Upload Event Image (Recommended Image Size 720x480)',
    ch: 'Upload Event Image (Recommended Image Size 720x480)',
    ph: 'Upload Event Image (Recommended Image Size 720x480)',
  },
  Workshop: {
    en: 'Workshop',
    et: 'Workshop',
    ja: 'Workshop',
    lt: 'Workshop',
    ch: 'Workshop',
    ph: 'Workshop',
  },
  'Event Description (optional)': {
    en: 'Event Description (optional)',
    et: 'Event Description (optional)',
    ja: 'Event Description (optional)',
    lt: 'Event Description (optional)',
    ch: 'Event Description (optional)',
    ph: 'Event Description (optional)',
  },
  'Event Image': {
    en: 'Event Image',
    et: 'Event Image',
    ja: 'Event Image',
    lt: 'Event Image',
    ch: 'Event Image',
    ph: 'Event Image',
  },
  'Event Form': {
    en: 'Event Form',
    et: 'Event Form',
    ja: 'Event Form',
    lt: 'Event Form',
    ch: 'Event Form',
    ph: 'Event Form',
  },
  'Event Title: {{wsTitle}}': {
    en: 'Event Title: {{wsTitle}}',
    et: 'Event Title: {{wsTitle}}',
    ja: 'Event Title: {{wsTitle}}',
    lt: 'Event Title: {{wsTitle}}',
    ch: 'Event Title: {{wsTitle}}',
    ph: 'Event Title: {{wsTitle}}',
  },
  "all members' bookings": {
    en: "all members' bookings",
    et: "all members' bookings",
    ja: "all members' bookings",
    lt: "all members' bookings",
    ch: "all members' bookings",
    ph: "all members' bookings",
  },
  description: {
    en: 'description',
    et: 'description',
    ja: 'description',
    lt: 'description',
    ch: 'description',
    ph: 'description',
  },
  'for this event': {
    en: 'for this event',
    et: 'for this event',
    ja: 'for this event',
    lt: 'for this event',
    ch: 'for this event',
    ph: 'for this event',
  },
  hrs: {
    en: 'hrs',
    et: 'hrs',
    ja: 'hrs',
    lt: 'hrs',
    ch: 'hrs',
    ph: 'hrs',
  },
  multiple: {
    en: 'multiple',
    et: 'multiple',
    ja: 'multiple',
    lt: 'multiple',
    ch: 'multiple',
    ph: 'multiple',
  },
  'ready to publish?': {
    en: 'ready to publish?',
    et: 'ready to publish?',
    ja: 'ready to publish?',
    lt: 'ready to publish?',
    ch: 'ready to publish?',
    ph: 'ready to publish?',
  },
  'schedule a time to publish? (if unchecked, event will go live immediately)': {
    en: 'schedule a time to publish? (if unchecked, event will go live immediately)',
    et: 'schedule a time to publish? (if unchecked, event will go live immediately)',
    ja: 'schedule a time to publish? (if unchecked, event will go live immediately)',
    lt: 'schedule a time to publish? (if unchecked, event will go live immediately)',
    ch: 'schedule a time to publish? (if unchecked, event will go live immediately)',
    ph: 'schedule a time to publish? (if unchecked, event will go live immediately)',
  },
  single: {
    en: 'single',
    et: 'single',
    ja: 'single',
    lt: 'single',
    ch: 'single',
    ph: 'single',
  },
  text: {
    en: 'text',
    et: 'text',
    ja: 'text',
    lt: 'text',
    ch: 'text',
    ph: 'text',
  },
  rating: {
    en: 'rating',
    et: 'rating',
    ja: 'rating',
    lt: 'rating',
    ch: 'rating',
    ph: 'rating',
  },
  'the current booking is not a workshop': {
    en: 'the current booking is not a workshop',
    et: 'the current booking is not a workshop',
    ja: 'the current booking is not a workshop',
    lt: 'the current booking is not a workshop',
    ch: 'the current booking is not a workshop',
    ph: 'the current booking is not a workshop',
  },
  'your question': {
    en: 'your question',
    et: 'your question',
    ja: 'your question',
    lt: 'your question',
    ch: 'your question',
    ph: 'your question',
  },
  'Event Access': {
    en: 'Event Access',
    et: 'Event Access',
    ja: 'Event Access',
    lt: 'Event Access',
    ch: 'Event Access',
    ph: 'Event Access',
  },
  'Choose here': {
    en: 'Choose here',
    et: 'Choose here',
    ja: 'Choose here',
    lt: 'Choose here',
    ch: 'Choose here',
    ph: 'Choose here',
  },
  'Branch Only Event': {
    en: 'Branch Only Event',
    et: 'Branch Only Event',
    ja: 'Branch Only Event',
    lt: 'Branch Only Event',
    ch: 'Branch Only Event',
    ph: 'Branch Only Event',
  },
  'Countrywide Event': {
    en: 'Countrywide Event',
    et: 'Countrywide Event',
    ja: 'Countrywide Event',
    lt: 'Countrywide Event',
    ch: 'Countrywide Event',
    ph: 'Countrywide Event',
  },
  'Global Event': {
    en: 'Global Event',
    et: 'Global Event',
    ja: 'Global Event',
    lt: 'Global Event',
    ch: 'Global Event',
    ph: 'Global Event',
  },
  'Public Events': {
    en: 'Public Events',
    et: 'Public Events',
    ja: 'Public Events',
    lt: 'Public Events',
    ch: 'Public Events',
    ph: 'Public Events',
  },
  'Only members can view and book': {
    en: 'Only members can view and book',
    et: 'Only members can view and book',
    ja: 'Only members can view and book',
    lt: 'Only members can view and book',
    ch: 'Only members can view and book',
    ph: 'Only members can view and book',
  },
  'Public can view, but only members can book': {
    en: 'Public can view, but only members can book',
    et: 'Public can view, but only members can book',
    ja: 'Public can view, but only members can book',
    lt: 'Public can view, but only members can book',
    ch: 'Public can view, but only members can book',
    ph: 'Public can view, but only members can book',
  },
  'Public and members can view and book': {
    en: 'Public and members can view and book',
    et: 'Public and members can view and book',
    ja: 'Public and members can view and book',
    lt: 'Public and members can view and book',
    ch: 'Public and members can view and book',
    ph: 'Public and members can view and book',
  },
  'Only public can view and book': {
    en: 'Only public can view and book',
    et: 'Only public can view and book',
    ja: 'Only public can view and book',
    lt: 'Only public can view and book',
    ch: 'Only public can view and book',
    ph: 'Only public can view and book',
  },
  'Member Events': {
    en: 'Member Events',
    et: 'Member Events',
    ja: 'Member Events',
    lt: 'Member Events',
    ch: 'Member Events',
    ph: 'Member Events',
  },
  'Event Public Access': {
    en: 'Event Public Access',
    et: 'Event Public Access',
    ja: 'Event Public Access',
    lt: 'Event Public Access',
    ch: 'Event Public Access',
    ph: 'Event Public Access',
  },
  Events: {
    en: 'Events',
    et: 'Events',
    ja: 'Events',
    lt: 'Events',
    ch: 'Events',
    ph: 'Events',
  },
  'No feedback record found': {
    en: 'No feedback record found',
    et: 'No feedback record found',
    ja: 'No feedback record found',
    lt: 'No feedback record found',
    ch: 'No feedback record found',
    ph: 'No feedback record found',
  },
  'Event Registration Questions': {
    en: 'Event Registration Questions',
    et: 'Event Registration Questions',
    ja: 'Event Registration Questions',
    lt: 'Event Registration Questions',
    ch: 'Event Registration Questions',
    ph: 'Event Registration Questions',
  },
  noQuestion: {
    en: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
    et: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
    ja: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
    lt: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
    ch: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
    ph: 'No questions have been added. To inquire about participants upon event registration, please add your question here.',
  },
  'No event image added': {
    en: 'No event image added',
    et: 'No event image added',
    ja: 'No event image added',
    lt: 'No event image added',
    ch: 'No event image added',
    ph: 'No event image added',
  },
  'Event Checkout Questions': {
    en: 'Event Checkout Questions',
    et: 'Event Checkout Questions',
    ja: 'Event Checkout Questions',
    lt: 'Event Checkout Questions',
    ch: 'Event Checkout Questions',
    ph: 'Event Checkout Questions',
  },
  noCheckoutQuestion: {
    en: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
    et: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
    ja: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
    lt: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
    ch: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
    ph: 'No questions have been added. To gather feedback from participants upon checkout, please add your question here.',
  },
  'Are you sure you want to delete this facilitator?': {
    en: 'Are you sure you want to delete this facilitator?',
    et: 'Are you sure you want to delete this facilitator?',
    ja: 'Are you sure you want to delete this facilitator?',
    lt: 'Are you sure you want to delete this facilitator?',
    ch: 'Are you sure you want to delete this facilitator?',
    ph: 'Are you sure you want to delete this facilitator?',
  },
  'Please add a user or name for this facilitator': {
    en: 'Please add a user or name for this facilitator',
    et: 'Please add a user or name for this facilitator',
    ja: 'Please add a user or name for this facilitator',
    lt: 'Please add a user or name for this facilitator',
    ch: 'Please add a user or name for this facilitator',
    ph: 'Please add a user or name for this facilitator',
  },
  'Event Facilitators': {
    en: 'Event Facilitators',
    et: 'Event Facilitators',
    ja: 'Event Facilitators',
    lt: 'Event Facilitators',
    ch: 'Event Facilitators',
    ph: 'Event Facilitators',
  },
  facilitatorLimit: {
    en: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
    et: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
    ja: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
    lt: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
    ch: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
    ph: 'Add facilitators to show on the event listing. (Max {{count}} facilitators)',
  },
  'Add Facilitators': {
    en: 'Add Facilitators',
    et: 'Add Facilitators',
    ja: 'Add Facilitators',
    lt: 'Add Facilitators',
    ch: 'Add Facilitators',
    ph: 'Add Facilitators',
  },
  'Only 10 facilitators can be added': {
    en: 'Only 10 facilitators can be added',
    et: 'Only 10 facilitators can be added',
    ja: 'Only 10 facilitators can be added',
    lt: 'Only 10 facilitators can be added',
    ch: 'Only 10 facilitators can be added',
    ph: 'Only 10 facilitators can be added',
  },
  'New Facilitator': {
    en: 'New Facilitator',
    et: 'New Facilitator',
    ja: 'New Facilitator',
    lt: 'New Facilitator',
    ch: 'New Facilitator',
    ph: 'New Facilitator',
  },
  'Edit Facilitator': {
    en: 'Edit Facilitator',
    et: 'Edit Facilitator',
    ja: 'Edit Facilitator',
    lt: 'Edit Facilitator',
    ch: 'Edit Facilitator',
    ph: 'Edit Facilitator',
  },
  Facilitator: {
    en: 'Facilitator',
    et: 'Facilitator',
    ja: 'Facilitator',
    lt: 'Facilitator',
    ch: 'Facilitator',
    ph: 'Facilitator',
  },
  Skills: {
    en: 'Skills',
    et: 'Skills',
    ja: 'Skills',
    lt: 'Skills',
    ch: 'Skills',
    ph: 'Skills',
  },
  Confirm: {
    en: 'Confirm',
    et: 'Confirm',
    ja: 'Confirm',
    lt: 'Confirm',
    ch: 'Confirm',
    ph: 'Confirm',
  },
  'Or, simply add a name below': {
    en: 'Or, simply add a name below',
    et: 'Or, simply add a name below',
    ja: 'Or, simply add a name below',
    lt: 'Or, simply add a name below',
    ch: 'Or, simply add a name below',
    ph: 'Or, simply add a name below',
  },
  Name: {
    en: 'Name',
    et: 'Name',
    ja: 'Name',
    lt: 'Name',
    ch: 'Name',
    ph: 'Name',
  },
  skills: {
    en: 'e.g. 3D printing, sewing...',
    et: 'e.g. 3D printing, sewing...',
    ja: 'e.g. 3D printing, sewing...',
    lt: 'e.g. 3D printing, sewing...',
    ch: 'e.g. 3D printing, sewing...',
    ph: 'e.g. 3D printing, sewing...',
  },
  selectUsers: {
    en: 'Select from users...',
    et: 'Select from users...',
    ja: 'Select from users...',
    lt: 'Select from users...',
    ch: 'Select from users...',
    ph: 'Select from users...',
  },
  'Event Registration Cut Off Date/Time': {
    en: 'Event Registration Cut Off Date/Time',
    et: 'Event Registration Cut Off Date/Time',
    ja: 'Event Registration Cut Off Date/Time',
    lt: 'Event Registration Cut Off Date/Time',
    ch: 'Event Registration Cut Off Date/Time',
    ph: 'Event Registration Cut Off Date/Time',
  },
  'The date and time of the event are required': {
    en: 'The date and time of the event are required',
    et: 'The date and time of the event are required',
    ja: 'The date and time of the event are required',
    lt: 'The date and time of the event are required',
    ch: 'The date and time of the event are required',
    ph: 'The date and time of the event are required',
  },
  'Event Start Time': {
    en: 'Event Start Time',
    et: 'Event Start Time',
    ja: 'Event Start Time',
    lt: 'Event Start Time',
    ch: 'Event Start Time',
    ph: 'Event Start Time',
  },
  'Event End Time': {
    en: 'Event End Time',
    et: 'Event End Time',
    ja: 'Event End Time',
    lt: 'Event End Time',
    ch: 'Event End Time',
    ph: 'Event End Time',
  },
  '1 Hour Before Start of Event': {
    en: '1 Hour Before Start of Event',
    et: '1 Hour Before Start of Event',
    ja: '1 Hour Before Start of Event',
    lt: '1 Hour Before Start of Event',
    ch: '1 Hour Before Start of Event',
    ph: '1 Hour Before Start of Event',
  },
  '1 Hour After Start of Event': {
    en: '1 Hour After Start of Event',
    et: '1 Hour After Start of Event',
    ja: '1 Hour After Start of Event',
    lt: '1 Hour After Start of Event',
    ch: '1 Hour After Start of Event',
    ph: '1 Hour After Start of Event',
  },
  '12AM on Event Day': {
    en: '12AM on Event Day',
    et: '12AM on Event Day',
    ja: '12AM on Event Day',
    lt: '12AM on Event Day',
    ch: '12AM on Event Day',
    ph: '12AM on Event Day',
  },
  '23:59PM on Event Day': {
    en: '23:59PM on Event Day',
    et: '23:59PM on Event Day',
    ja: '23:59PM on Event Day',
    lt: '23:59PM on Event Day',
    ch: '23:59PM on Event Day',
    ph: '23:59PM on Event Day',
  },
  'Set Cutoff Date and Time': {
    en: 'Set Cutoff Date and Time',
    et: 'Set Cutoff Date and Time',
    ja: 'Set Cutoff Date and Time',
    lt: 'Set Cutoff Date and Time',
    ch: 'Set Cutoff Date and Time',
    ph: 'Set Cutoff Date and Time',
  },
};
