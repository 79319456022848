export default {
  privacyPolicy: {
    en: 'Privacy Policy',
    ja: 'プライバシーポリシー',
    et: 'Privaatsuspoliitika',
    lt: 'Privacy Policy',
    ch: 'Privacy Policy',
    ph: 'Privacy Policy',
  },
  termsAndConditions: {
    en: 'Terms and Conditions',
    ja: '規約と条件',
    et: 'Kasutustingimused',
    lt: 'Terms and Conditions',
    ch: 'Terms and Conditions',
    ph: 'Terms and Conditions',
  },
  newVersion: {
    en: 'New Version',
    ja: '新しいバージョン',
    et: 'Uus versioon',
    lt: 'New Version',
    ch: 'New Version',
    ph: 'New Version',
  },
  versions: {
    en: 'Versions',
    ja: 'バージョン',
    et: 'Versioonid',
    lt: 'Versions',
    ch: 'Versions',
    ph: 'Versions',
  },
  viewingVersion: {
    en: 'Viewing Version',
    ja: 'バージョンご覧',
    et: 'Vaatamise versioon',
    lt: 'Viewing Version',
    ch: 'Viewing Version',
    ph: 'Viewing Version',
  },
  editingVersion: {
    en: 'Editing Version',
    ja: 'バージョン編集中',
    et: 'Muutmise versioon',
    lt: 'Editing Version',
    ch: 'Editing Version',
    ph: 'Editing Version',
  },
  version: {
    en: 'Version',
    ja: 'バージョン',
    et: 'Versioon',
    lt: 'Version',
    ch: 'Version',
    ph: 'Version',
  },
  createdDate: {
    en: 'Created date',
    ja: '作成した日付',
    et: 'Loomise kuupäev',
    lt: 'Created date',
    ch: 'Created date',
    ph: 'Created date',
  },
  launchedDate: {
    en: 'Launched date',
    ja: 'ラウンチ日付',
    et: 'Avamise kuupäev',
    lt: 'Launched date',
    ch: 'Launched date',
    ph: 'Launched date',
  },
  deprecatedDate: {
    en: 'Deprecated date',
    ja: '非推奨日付',
    et: 'Sugemise kuupäev',
    lt: 'Deprecated date',
    ch: 'Deprecated date',
    ph: 'Deprecated date',
  },
  status: {
    en: 'Status',
    ja: '状態「Status」',
    et: 'Staatus',
    lt: 'Status',
    ch: 'Status',
    ph: 'Status',
  },
  scheduledDate: {
    en: 'Scheduled Date',
    ja: '予約リリース日付',
    et: 'Planeeritud kuupäev',
    lt: 'Scheduled Date',
    ch: 'Scheduled Date',
    ph: 'Scheduled Date',
  },
  languages: {
    en: 'Languages',
    ja: '言語',
    et: 'Keeled',
    lt: 'Languages',
    ch: 'Languages',
    ph: 'Languages',
  },
  addLanguage: {
    en: 'Add Language',
    ja: '言語追加する',
    et: 'Lisa keel',
    lt: 'Add Language',
    ch: 'Add Language',
    ph: 'Add Language',
  },
  newDocument: {
    en: 'New Document',
    ja: '新しい書類',
    et: 'Uus dokument',
    lt: 'New Document',
    ch: 'New Document',
    ph: 'New Document',
  },
  language: {
    en: 'Language',
    ja: '言語',
    et: 'Keel',
    lt: 'Language',
    ch: 'Language',
    ph: 'Language',
  },
  viewingDocument: {
    en: 'Viewing Document',
    ja: '書類ご覧',
    et: 'Vaatamise dokument',
    lt: 'Viewing Document',
    ch: 'Viewing Document',
    ph: 'Viewing Document',
  },
  editingDocument: {
    en: 'Editing Document',
    ja: '書類編集中',
    et: 'Muutmise dokument',
    lt: 'Editing Document',
    ch: 'Editing Document',
    ph: 'Editing Document',
  },
  languageLocale: {
    en: 'Language / Locale',
    ja: '言語/ロケール',
    et: 'Keel / Asukoht',
    lt: 'Language / Locale',
    ch: 'Language / Locale',
    ph: 'Language / Locale',
  },
  updatedDate: {
    en: 'Updated Date',
    ja: '編集された日付',
    et: 'Muutmise kuupäev',
    lt: 'Updated Date',
    ch: 'Updated Date',
    ph: 'Updated Date',
  },
  noticeTextHTML: {
    en: 'Notice Text HTML',
    ja: '通知キストHTML',
    et: 'Teate tekst HTML',
    lt: 'Notice Text HTML',
    ch: 'Notice Text HTML',
    ph: 'Notice Text HTML',
  },
  noticeText: {
    en: 'Notice Text',
    ja: '通知キスト',
    et: 'Teate tekst',
    lt: 'Notice Text',
    ch: 'Notice Text',
    ph: 'Notice Text',
  },
  noticeLinkText: {
    en: 'Notice Link Text',
    ja: '通知リンクテキスト',
    et: 'Teate lingi tekst',
    lt: 'Notice Link Text',
    ch: 'Notice Link Text',
    ph: 'Notice Link Text',
  },
  noticeLinkUrl: {
    en: 'Notice Link Url',
    ja: '通知リンクURL',
    et: 'Teate linki aadress',
    lt: 'Notice Link Url',
    ch: 'Notice Link Url',
    ph: 'Notice Link Url',
  },
  content: {
    en: 'Content',
    ja: 'コンテンツ',
    et: 'Sisu',
    lt: 'Content',
    ch: 'Content',
    ph: 'Content',
  },
  newVersionType: {
    en: 'What type of version would you like to create?',
    ja: 'どんなバージョン作りたいですか？',
    et: 'Millist liiki versiooni sa soovid luua?',
    lt: 'What type of version would you like to create?',
    ch: 'What type of version would you like to create?',
    ph: 'What type of version would you like to create?',
  },
  major: {
    en: 'Major',
    ja: 'メイジャー',
    et: 'Peamine',
    lt: 'Major',
    ch: 'Major',
    ph: 'Major',
  },
  minor: {
    en: 'Minor',
    ja: 'マイナー',
    et: 'Keskmine',
    lt: 'Minor',
    ch: 'Minor',
    ph: 'Minor',
  },
  patch: {
    en: 'Patch',
    ja: 'パーチ',
    et: 'Mikro',
    lt: 'Patch',
    ch: 'Patch',
    ph: 'Patch',
  },
  custom: {
    en: 'Custom',
    ja: 'カスタム',
    et: 'Muu',
    lt: 'Custom',
    ch: 'Custom',
    ph: 'Custom',
  },
};
