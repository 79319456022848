export const BookingStatusType = {
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

export const bookingStatusTypes = [
  BookingStatusType.SUBMITTED,
  BookingStatusType.APPROVED,
  BookingStatusType.REJECTED,
  BookingStatusType.CANCELLED,
];
