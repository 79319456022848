export default {
  myAccount: {
    en: 'My Account',
    ja: 'マイアカウント',
    et: 'Minu konto',
    lt: 'My Account',
    ch: 'My Account',
    ph: 'My Account',
  },
  profile: {
    en: 'Profile',
    ja: 'プロフィール',
    et: 'Profiil',
    lt: 'Profile',
    ch: 'Profile',
    ph: 'Profile',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mail',
    lt: 'Email',
    ch: 'Email',
    ph: 'Email',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Parool',
    lt: 'Password',
    ch: 'Password',
    ph: 'Password',
  },
  changeUsername: {
    en: 'Change your Username',
    ja: 'ユーザー名変更',
    et: 'Muuda oma kasutajanime',
    lt: 'Change your Username',
    ch: 'Change your Username',
    ph: 'Change your Username',
  },
  changeEmail: {
    en: 'Change your Email',
    ja: 'メール変更',
    et: 'Muuda oma e-maili',
    lt: 'Change your Email',
    ch: 'Change your Email',
    ph: 'Change your Email',
  },
  changePassword: {
    en: 'Set Password',
    ja: 'パスワードセット',
    et: 'Määra parool',
    lt: 'Set Password',
    ch: 'Set Password',
    ph: 'Set Password',
  },
  newUsername: {
    en: 'New Username',
    ja: '新ユーザー名',
    et: 'Uus kasutajanimi',
    lt: 'New Username',
    ch: 'New Username',
    ph: 'New Username',
  },
  newEmail: {
    en: 'New Email',
    ja: '新メール',
    et: 'Uus e-mail',
    lt: 'New Email',
    ch: 'New Email',
    ph: 'New Email',
  },
  newPassword: {
    en: 'New Password',
    ja: '新パスワード',
    et: 'Uus parool',
    lt: 'New Password',
    ch: 'New Password',
    ph: 'New Password',
  },
  setNewUsername: {
    en: 'Set New Username',
    ja: '新ユーザー名設定する',
    et: 'Määra uus kasutajanimi',
    lt: 'Set New Username',
    ch: 'Set New Username',
    ph: 'Set New Username',
  },
  setNewEmail: {
    en: 'Set New Email',
    ja: '新メール設定する',
    et: 'Määra uus e-mail',
    lt: 'Set New Email',
    ch: 'Set New Email',
    ph: 'Set New Email',
  },
  setNewPassword: {
    en: 'Set New Password',
    ja: '新パスワード設定する',
    et: 'Määra uus parool',
    lt: 'Set New Password',
    ch: 'Set New Password',
    ph: 'Set New Password',
  },
  currentPassword: {
    en: 'Current Password',
    ja: '現在のパスワード',
    et: 'Praegune parool',
    lt: 'Current Password',
    ch: 'Current Password',
    ph: 'Current Password',
  },
  forgotPassword: {
    en: 'Forgot?',
    ja: '忘れた？',
    et: 'Unustasid parooli?',
    lt: 'Forgot?',
    ch: 'Forgot?',
    ph: 'Forgot?',
  },
  successUsername: {
    en: 'Username Changed',
    ja: 'ユーザー名変更しました。',
    et: 'Kasutajanimi muudetud',
    lt: 'Username Changed',
    ch: 'Username Changed',
    ph: 'Username Changed',
  },
  successEmail: {
    en: 'An email has been sent to {{email}}, please check it and proceed from there. ',
    ja: '{{email}}にメール送れいました。ご確認ください。',
    et: 'E-mail on saadetud aadressile {{email}}, palun kontrolli ja jätka sealt',
    lt: 'An email has been sent to {{email}}, please check it and proceed from there. ',
    ch: 'An email has been sent to {{email}}, please check it and proceed from there. ',
    ph: 'An email has been sent to {{email}}, please check it and proceed from there. ',
  },
  successPassword: {
    en: 'Password Changed',
    ja: 'パスワード変更しました。',
    et: 'Prool muudetud',
    lt: 'Password Changed',
    ch: 'Password Changed',
    ph: 'Password Changed',
  },
  linkSnsAccountsHeader: {
    en: 'Link SNS Accounts',
    ja: 'SNSアカウント追加',
    et: 'Lingi SNS kontod',
    lt: 'Link SNS Accounts',
    ch: 'Link SNS Accounts',
    ph: 'Link SNS Accounts',
  },
  linkSnsAccountsText: {
    en: 'Link your social media accounts to easily log in',
    ja: 'SNSアカウントリンクすればログインがやりやすくなれます。',
    et: 'Lingi oma sotsiaalmeedia kontod, et lihtsasti sisse logida',
    lt: 'Link your social media accounts to easily log in',
    ch: 'Link your social media accounts to easily log in',
    ph: 'Link your social media accounts to easily log in',
  },
  dangerZone: {
    en: 'Danger Zone',
    ja: '危険ゾーン',
    et: 'Ohutsoon',
    lt: 'Danger Zone',
    ch: 'Danger Zone',
    ph: 'Danger Zone',
  },
  deleteAccount: {
    en: 'Delete Account',
    ja: 'アカウント解除',
    et: 'Kustuta konto',
    lt: 'Delete Account',
    ch: 'Delete Account',
    ph: 'Delete Account',
  },
  deleteAccountWarning: {
    en: 'This action is unreversible. Please be certain. ',
    ja: 'このアクションは元に戻すことができません。 確認してください。',
    et: 'See tegevus on lõplik, kas oled kindel?',
    lt: 'This action is unreversible. Please be certain. ',
    ch: 'This action is unreversible. Please be certain. ',
    ph: 'This action is unreversible. Please be certain. ',
  },
  deleteAccountText: {
    en: 'To proceed with deleting this account. Please type in your username, email and password for the account. ',
    ja: 'このアカウントの削除を続行するために、アカウントのユーザー名、メールアドレス、パスワードを入力してください。',
    et: 'Konto kustutamiseks sisesta palun oma kasutajanimi, email ja parool.',
    lt: 'To proceed with deleting this account. Please type in your username, email and password for the account. ',
    ch: 'To proceed with deleting this account. Please type in your username, email and password for the account. ',
    ph: 'To proceed with deleting this account. Please type in your username, email and password for the account. ',
  },
  deleteAccountForever: {
    en: 'Delete Account Forever',
    ja: '永久にアカウント削除',
    et: 'Kustuta konto lõplikult',
    lt: 'Delete Account Forever',
    ch: 'Delete Account Forever',
    ph: 'Delete Account Forever',
  },
  accountType: {
    en: 'Account Type',
    ja: 'アカウントタイプ',
    et: 'Konto tüüp',
    lt: 'Account Type',
    ch: 'Account Type',
    ph: 'Account Type',
  },
  upgrade: {
    en: 'Upgrade',
    ja: 'アップグレードする',
    et: 'Uuenda',
    lt: 'Upgrade',
    ch: 'Upgrade',
    ph: 'Upgrade',
  },
  accountDeleted: {
    en: 'Account Deleted.',
    ja: 'Account Deleted.',
    et: 'Account Deleted.',
    lt: 'Account Deleted.',
    ch: 'Account Deleted',
    ph: 'Account Deleted',
  },
};
