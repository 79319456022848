export default {
  'Address:': {
    en: 'Address:',
    ja: 'Address:',
    et: 'Address:',
    lt: 'Adresas:',
    ch: 'Address:',
    ph: 'Address:',
  },
  'Add Second Guardian': {
    en: 'Add Second Guardian',
    ja: 'Add Second Guardian',
    et: 'Add Second Guardian',
    lt: 'Pridėti antrąjį globėją',
    ch: 'Add Second Guardian',
    ph: 'Add Second Guardian',
  },
  'Agreement to use VIVISTOP': {
    en: 'Agreement to use VIVISTOP',
    ja: 'Agreement to use VIVISTOP',
    et: 'Agreement to use VIVISTOP',
    lt: 'Sutartis naudoti VIVISTOP',
    ch: 'Agreement to use VIVISTOP',
    ph: 'Agreement to use VIVISTOP',
  },
  'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in': {
    en: 'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in',
    ja: 'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in',
    et: 'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in',
    lt: 'Vaiko nuotrauka ar filmuota medžiaga bus saugoma 5 metus nuo nuotraukos ar vaizdo įrašo padarymo. Informacija apie jūsų teisę atšaukti sutikimą bei kitas teises pateikiama',
    ch: 'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in',
    ph: 'A photograph or video of the child will be kept for 5 years from the date the photograph or video was taken. Information about your right to withdraw consent and other rights can be found in',
  },
  'and to the': {
    en: 'and to the',
    ja: 'and to the',
    et: 'and to the',
    lt: 'ir su',
    ch: 'and to the',
    ph: 'and to the',
  },
  'As a Guardian / Parent, I consent and/or agree to the following:': {
    en: 'As a Guardian / Parent, I consent and/or agree to the following:',
    ja: 'As a Guardian / Parent, I consent and/or agree to the following:',
    et: 'As a Guardian / Parent, I consent and/or agree to the following:',
    lt: 'Aš, kaip globėjas / tėvas, sutinku su šiais dalykais:',
    ch: 'As a Guardian / Parent, I consent and/or agree to the following:',
    ph: 'As a Guardian / Parent, I consent and/or agree to the following:',
  },
  'At least {{count}} characters required': {
    en: 'At least {{count}} characters required',
    ja: 'At least {{count}} characters required',
    et: 'At least {{count}} characters required',
    lt: 'Reikalingi bent {{count}} simboliai',
    ch: 'At least {{count}} characters required',
    ph: 'At least {{count}} characters required',
  },
  'Both guardians cannot have the same email address': {
    en: 'Both guardians cannot have the same email address',
    ja: 'Both guardians cannot have the same email address',
    et: 'Both guardians cannot have the same email address',
    lt: 'Abu globėjai negali turėti to paties el. pašto adreso',
    ch: 'Both guardians cannot have the same email address',
    ph: 'Both guardians cannot have the same email address',
  },
  'By signing below, I am certifying that:': {
    en: 'By signing below, I am certifying that:',
    ja: 'By signing below, I am certifying that:',
    et: 'By signing below, I am certifying that:',
    lt: 'Pasirašydamas toliau patvirtinu, kad:',
    ch: 'By signing below, I am certifying that:',
    ph: 'By signing below, I am certifying that:',
  },
  'characters minimum': {
    en: '{{count}} characters minimum',
    ja: '{{count}} characters minimum',
    et: '{{count}} characters minimum',
    lt: 'Mažiausiai {{count}} simboliai',
    ch: '{{count}} characters minimum',
    ph: '{{count}} characters minimum',
  },
  "Child's data:": {
    en: "Child's data:",
    ja: "Child's data:",
    et: "Child's data:",
    lt: 'Pasirinkite čia',
    ch: "Child's data:",
    ph: "Child's data:",
  },
  'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"': {
    en: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
    ja: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
    et: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
    lt: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
    ch: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
    ph: 'Children who would like to access VIVISTOP will need to register as a "VIVINAUT"',
  },
  'Choose here': {
    en: 'Choose here',
    ja: 'Choose here',
    et: 'Choose here',
    lt: 'Vaiko duomenys:',
    ch: 'Choose here',
    ph: 'Choose here',
  },
  'Click here to sign': {
    en: 'Click here to sign',
    ja: 'Click here to sign',
    et: 'Click here to sign',
    lt: 'Spustelėkite čia, kad pasirašytumėte',
    ch: 'Click here to sign',
    ph: 'Click here to sign',
  },
  'Confirmation email sent': {
    en: 'Confirmation email sent',
    ja: 'Confirmation email sent',
    et: 'Confirmation email sent',
    lt: 'Patvirtinimo el. laiškas išsiųstas',
    ch: 'Confirmation email sent',
    ph: 'Confirmation email sent',
  },
  'Confirm Password*': {
    en: 'Confirm Password*',
    ja: 'Confirm Password*',
    et: 'Confirm Password*',
    lt: 'Patvirtinti slaptažodį*',
    ch: 'Confirm Password*',
    ph: 'Confirm Password*',
  },
  'Create My Account': {
    en: 'Create My Account',
    ja: 'Create My Account',
    et: 'Create My Account',
    lt: 'Sukurti mano paskyra',
    ch: 'Create My Account',
    ph: 'Create My Account',
  },
  'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!': {
    en: 'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!',
    ja: 'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!',
    et: 'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!',
    lt: 'Ar turite brolių ir seserų, kurie taip pat yra VIVINAUTAI? Jei taip, praneškite mums jų vardus!',
    ch: 'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!',
    ph: 'Do you have siblings who are also VIVINAUTs? If yes, please let us know their names!',
  },
  "Don't forget our email may be hiding in your spam folder!": {
    en: "Don't forget our email may be hiding in your spam folder!",
    ja: "Don't forget our email may be hiding in your spam folder!",
    et: "Don't forget our email may be hiding in your spam folder!",
    lt: 'Nepamirškite, kad mūsų el. laiškas gali būti paslėptas jūsų šlamšto aplanke!',
    ch: "Don't forget our email may be hiding in your spam folder!",
    ph: "Don't forget our email may be hiding in your spam folder!",
  },
  'E-mail Address:': {
    en: 'E-mail Address:',
    ja: 'E-mail Address:',
    et: 'E-mail Address:',
    lt: 'El. pašto adresas:',
    ch: 'E-mail Address:',
    ph: 'E-mail Address:',
  },
  Father: {
    en: 'Father',
    ja: 'Father',
    et: 'Father',
    lt: 'Tėvas',
    ch: 'Father',
    ph: 'Father',
  },
  Female: {
    en: 'Female',
    ja: 'Female',
    et: 'Female',
    lt: 'Moteris',
    ch: 'Female',
    ph: 'Female',
  },
  "First Guardian's Particulars": {
    en: "First Guardian's Particulars",
    ja: "First Guardian's Particulars",
    et: "First Guardian's Particulars",
    lt: 'Globėjų Detalės',
    ch: "First Guardian's Particulars",
    ph: "First Guardian's Particulars",
  },
  'Full Name:': {
    en: 'Full Name:',
    ja: 'Full Name:',
    et: 'Full Name:',
    lt: 'Vardas ir pavardė:',
    ch: 'Full Name:',
    ph: 'Full Name:',
  },
  Grandparent: {
    en: 'Grandparent',
    ja: 'Grandparent',
    et: 'Grandparent',
    lt: 'Senelis',
    ch: 'Grandparent',
    ph: 'Grandparent',
  },
  "Guardian's Email*": {
    en: "Guardian's Email*",
    ja: "Guardian's Email*",
    et: "Guardian's Email*",
    lt: 'Globėjų El. pašto adresas*',
    ch: "Guardian's Email*",
    ph: "Guardian's Email*",
  },
  "Guardian's Full Name*": {
    en: "Guardian's Full Name*",
    ja: "Guardian's Full Name*",
    et: "Guardian's Full Name*",
    lt: 'Globėjų Vardas ir pavardė*',
    ch: "Guardian's Full Name*",
    ph: "Guardian's Full Name*",
  },
  "Guardian's Particulars": {
    en: "Guardian's Particulars",
    ja: "Guardian's Particulars",
    et: "Guardian's Particulars",
    lt: 'Globėjų Detalės',
    ch: "Guardian's Particulars",
    ph: "Guardian's Particulars",
  },
  "Guardian's Phone Number*": {
    en: "Guardian's Phone Number*",
    ja: "Guardian's Phone Number*",
    et: "Guardian's Phone Number*",
    lt: 'Globėjų Telefono numeris*',
    ch: "Guardian's Phone Number*",
    ph: "Guardian's Phone Number*",
  },
  "Guardian's Signature": {
    en: "Guardian's Signature",
    ja: "Guardian's Signature",
    et: "Guardian's Signature",
    lt: 'Globėjų Parašas',
    ch: "Guardian's Signature",
    ph: "Guardian's Signature",
  },
  "Guardian's Signature:": {
    en: "Guardian's Signature:",
    ja: "Guardian's Signature:",
    et: "Guardian's Signature:",
    lt: 'Globėjų Parašas:',
    ch: "Guardian's Signature:",
    ph: "Guardian's Signature:",
  },
  'Guardian / Parent data:': {
    en: 'Guardian / Parent data:',
    ja: 'Guardian / Parent data:',
    et: 'Guardian / Parent data:',
    lt: 'Globėjų / tėvų duomenys:',
    ch: 'Guardian / Parent data:',
    ph: 'Guardian / Parent data:',
  },
  'Information about your right to withdraw consent and other rights can be found in': {
    en: 'Information about your right to withdraw consent and other rights can be found in',
    ja: 'Information about your right to withdraw consent and other rights can be found in',
    et: 'Information about your right to withdraw consent and other rights can be found in',
    lt: 'Informacija apie jūsų teisę atšaukti sutikimą bei kitas teises pateikiama',
    ch: 'Information about your right to withdraw consent and other rights can be found in',
    ph: 'Information about your right to withdraw consent and other rights can be found in',
  },
  'I agree to': {
    en: 'I agree to',
    ja: 'I agree to',
    et: 'I agree to',
    lt: 'Sutinku',
    ch: 'I agree to',
    ph: 'I agree to',
  },
  "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:": {
    en: "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:",
    ja: "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:",
    et: "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:",
    lt: 'Sutinku gauti pranešimus ir informaciją apie VIVITA veiklą bei organizuojamus renginius. Informacija apie jūsų teisę atšaukti sutikimą bei kitas teises pateikiama VIVITA privatumo pranešime:',
    ch: "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:",
    ph: "I agree to receive notifications and information about VIVITA's activities and events. Information about your right to withdraw consent and other rights can be found in VIVITA's privacy notice:",
  },
  'I consent and give permission for my Child to start using the': {
    en: 'I consent and give permission for my Child to start using the',
    ja: 'I consent and give permission for my Child to start using the',
    et: 'I consent and give permission for my Child to start using the',
    lt: 'Sutinku ir leidžiu savo vaikui pradėti naudotis',
    ch: 'I consent and give permission for my Child to start using the',
    ph: 'I consent and give permission for my Child to start using the',
  },
  'I consent and give permission for my Child to start using the VIVIBOOM educational portal.': {
    en: 'I consent and give permission for my Child to start using the VIVIBOOM educational portal.',
    ja: 'I consent and give permission for my Child to start using the VIVIBOOM educational portal.',
    et: 'I consent and give permission for my Child to start using the VIVIBOOM educational portal.',
    lt: 'Sutinku ir leidžiu savo vaikui pradėti naudotis VIVIBOOM švietimo portalu.',
    ch: 'I consent and give permission for my Child to start using the VIVIBOOM educational portal.',
    ph: 'I consent and give permission for my Child to start using the VIVIBOOM educational portal.',
  },
  'I consent and give permission to VIVITA to': {
    en: 'I consent and give permission to VIVITA to',
    ja: 'I consent and give permission to VIVITA to',
    et: 'I consent and give permission to VIVITA to',
    lt: 'Sutinku ir leidžiu',
    ch: 'I consent and give permission to VIVITA to',
    ph: 'I consent and give permission to VIVITA to',
  },
  "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.": {
    en: "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    ja: "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    et: "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    lt: 'Sutinku ir leidžiu VIVITA fotografuoti ir (arba) filmuoti mano nepilnametį (-ius) vaiką (-us), dalyvaujantį (-čius) VIVITA seminaruose ir užsiėmimuose, ir sutinku, kad VIVITA šias nuotraukas ir (arba) filmuotą medžiagą skelbtų bet kokiu teisėtu būdu, įskaitant viešinimą, socialinę žiniasklaidą ir interneto turinį. Vaiko nuotrauka ar filmuota medžiaga bus saugoma 5 metus nuo nuotraukos ar vaizdo įrašo padarymo. Informacija apie jūsų teisę atšaukti sutikimą bei kitas teises pateikiama VIVITA privatumo pranešime:',
    ch: "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    ph: "I consent and give permission to VIVITA to take photographs and/or video of my minor child(ren) while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
  },
  'I further certify that:': {
    en: 'I further certify that:',
    ja: 'I further certify that:',
    et: 'I further certify that:',
    lt: 'Taip pat patvirtinu, kad:',
    ch: 'I further certify that:',
    ph: 'I further certify that:',
  },
  'I have read and agree to the': {
    en: 'I have read and agree to the',
    ja: 'I have read and agree to the',
    et: 'I have read and agree to the',
    lt: 'Aš perskaičiau ir sutinku su',
    ch: 'I have read and agree to the',
    ph: 'I have read and agree to the',
  },
  'I have read and agree to': {
    en: 'I have read and agree to',
    ja: 'I have read and agree to',
    et: 'I have read and agree to',
    lt: 'Perskaiciau ir sutinku',
    ch: 'I have read and agree to',
    ph: 'I have read and agree to',
  },
  'Legal guardian': {
    en: 'Legal guardian',
    ja: 'Legal guardian',
    et: 'Legal guardian',
    lt: 'Teisinis Globėjas',
    ch: 'Legal guardian',
    ph: 'Legal guardian',
  },
  "Let's get you all set up so you can start your No Limits journey with us!": {
    en: "Let's get you all set up so you can start your No Limits journey with us!",
    ja: "Let's get you all set up so you can start your No Limits journey with us!",
    et: "Let's get you all set up so you can start your No Limits journey with us!",
    lt: 'Parengsime jums viską, kad galėtumėte pradėti savo No Limits kelionę su mumis!',
    ch: "Let's get you all set up so you can start your No Limits journey with us!",
    ph: "Let's get you all set up so you can start your No Limits journey with us!",
  },
  'Liability Waiver and Release': {
    en: 'Liability Waiver and Release',
    ja: 'Liability Waiver and Release',
    et: 'Liability Waiver and Release',
    lt: 'Atsakomybės Atsisakymu',
    ch: 'Liability Waiver and Release',
    ph: 'Liability Waiver and Release',
  },
  Male: {
    en: 'Male',
    ja: 'Male',
    et: 'Male',
    lt: 'Patinas',
    ch: 'Male',
    ph: 'Male',
  },
  'Membership Terms and Conditions': {
    en: 'Membership Terms and Conditions',
    ja: 'Membership Terms and Conditions',
    et: 'Membership Terms and Conditions',
    lt: 'VIVITA Narystės sąlygos',
    ch: 'Membership Terms and Conditions',
    ph: 'Membership Terms and Conditions',
  },
  'Membership Terms': {
    en: 'Membership Terms',
    ja: 'Membership Terms',
    et: 'Membership Terms',
    lt: 'Narystės sąlygos',
    ch: 'Membership Terms',
    ph: 'Membership Terms',
  },
  "Member's Signature:": {
    en: "Member's Signature:",
    ja: "Member's Signature:",
    et: "Member's Signature:",
    lt: 'Nario Parašas:',
    ch: "Member's Signature:",
    ph: "Member's Signature:",
  },
  Mother: {
    en: 'Mother',
    ja: 'Mother',
    et: 'Mother',
    lt: 'Motina',
    ch: 'Mother',
    ph: 'Mother',
  },
  Next: {
    en: 'Next',
    ja: 'Next',
    et: 'Next',
    lt: 'Kitas',
    ch: 'Next',
    ph: 'Next',
  },
  'Non-binary': {
    en: 'Non-binary',
    ja: 'Non-binary',
    et: 'Non-binary',
    lt: 'Ne dvejetainis',
    ch: 'Non-binary',
    ph: 'Non-binary',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  Other: {
    en: 'Other',
    ja: 'Other',
    et: 'Other',
    lt: 'Kita',
    ch: 'Other',
    ph: 'Other',
  },
  Parent: {
    en: 'Parent',
    ja: 'Parent',
    et: 'Parent',
    lt: 'Tėvas',
    ch: 'Parent',
    ph: 'Parent',
  },
  'Password*': {
    en: 'Password*',
    ja: 'パスワード*',
    et: 'Parool*',
    lt: 'Slaptažodis*',
    ch: 'Password*',
    ph: 'Password*',
  },
  'Passwords Mismatched': {
    en: 'Passwords Mismatched',
    ja: 'Passwords Mismatched',
    et: 'Passwords Mismatched',
    lt: 'Neatitinka slaptažodžiai',
    ch: 'Passwords Mismatched',
    ph: 'Passwords Mismatched',
  },
  'Phone number:': {
    en: 'Phone number:',
    ja: 'Phone number:',
    et: 'Phone number:',
    lt: 'Telefono numeris:',
    ch: 'Phone number:',
    ph: 'Phone number:',
  },
  'Please add a comma between each sibling': {
    en: 'Please add a comma between each sibling',
    ja: 'Please add a comma between each sibling',
    et: 'Please add a comma between each sibling',
    lt: 'Tarp kiekvieno brolio ir sesers pridėkite kablelį',
    ch: 'Please add a comma between each sibling',
    ph: 'Please add a comma between each sibling',
  },
  'Please agree with ALL the Terms and Conditions': {
    en: 'Please agree with ALL the Terms and Conditions',
    ja: 'Please agree with ALL the Terms and Conditions',
    et: 'Please agree with ALL the Terms and Conditions',
    lt: 'Prašome sutikti su VISomis taisyklėmis ir sąlygomis',
    ch: 'Please agree with ALL the Terms and Conditions',
    ph: 'Please agree with ALL the Terms and Conditions',
  },
  "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.": {
    en: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    et: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    ja: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    lt: 'Norėdami pradėti naudotis VIVIBOOM, atidarykite jums atsiųstą el. laišką ir patvirtinkite mūsų el. pašto adresą.',
    ch: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    ph: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
  },
  "Please provide Guardian's Signature": {
    en: "Please provide Guardian's Signature",
    ja: "Please provide Guardian's Signature",
    et: "Please provide Guardian's Signature",
    lt: 'Pateikite globėjo parašą',
    ch: "Please provide Guardian's Signature",
    ph: "Please provide Guardian's Signature",
  },
  'Privacy Policy': {
    en: 'Privacy Policy',
    ja: 'Privacy Policy',
    et: 'Privacy Policy',
    lt: 'Privatumo Politika',
    ch: 'Privacy Policy',
    ph: 'Privacy Policy',
  },
  "receive notifications and information about VIVITA's activities and events": {
    en: "receive notifications and information about VIVITA's activities and events",
    ja: "receive notifications and information about VIVITA's activities and events",
    et: "receive notifications and information about VIVITA's activities and events",
    lt: 'gauti pranešimus ir informaciją apie VIVITA veiklą bei organizuojamus renginius',
    ch: "receive notifications and information about VIVITA's activities and events",
    ph: "receive notifications and information about VIVITA's activities and events",
  },
  'Prefer not to respond': {
    en: 'Prefer not to respond',
    ja: 'Prefer not to respond',
    et: 'Prefer not to respond',
    lt: 'Geriau neatsakyti',
    ch: 'Prefer not to respond',
    ph: 'Prefer not to respond',
  },
  'Registry code / number:': {
    en: 'Registry code / number:',
    ja: 'Registry code / number:',
    et: 'Registry code / number:',
    lt: 'Registro kodas / numeris:',
    ch: 'Registry code / number:',
    ph: 'Registry code / number:',
  },
  'Relationship*': {
    en: 'Relationship*',
    ja: 'Relationship*',
    et: 'Relationship*',
    lt: 'Santykiai*',
    ch: 'Relationship*',
    ph: 'Relationship*',
  },
  'Relationship to Child:': {
    en: 'Relationship to Child:',
    ja: 'Relationship to Child:',
    et: 'Relationship to Child:',
    lt: 'Santykis su vaiku:',
    ch: 'Relationship to Child:',
    ph: 'Relationship to Child:',
  },
  'Residential Address*': {
    en: 'Residential Address*',
    ja: 'Residential Address*',
    et: 'Residential Address*',
    lt: 'Adresas*',
    ch: 'Residential Address*',
    ph: 'Residential Address*',
  },
  'Remove Second Guardian': {
    en: 'Remove Second Guardian',
    ja: 'Remove Second Guardian',
    et: 'Remove Second Guardian',
    lt: 'Pašalinkite antrąjį globėją',
    ch: 'Remove Second Guardian',
    ph: 'Remove Second Guardian',
  },
  'School:': {
    en: 'School:',
    ja: 'School:',
    et: 'School:',
    lt: 'Mokykla:',
    ch: 'School:',
    ph: 'School:',
  },
  'School*': {
    en: 'School*',
    ja: 'School*',
    et: 'School*',
    lt: 'Mokykla*',
    ch: 'School*',
    ph: 'School*',
  },
  "Second Guardian's Email*": {
    en: "Second Guardian's Email*",
    ja: "Second Guardian's Email*",
    et: "Second Guardian's Email*",
    lt: 'Antrasis Globėjų El. pašto adresas*',
    ch: "Second Guardian's Email*",
    ph: "Second Guardian's Email*",
  },
  "Second Guardian's Full Name*": {
    en: "Second Guardian's Full Name*",
    ja: "Second Guardian's Full Name*",
    et: "Second Guardian's Full Name*",
    lt: 'Antrasis Globėjų Vardas ir pavardė*',
    ch: "Second Guardian's Full Name*",
    ph: "Second Guardian's Full Name*",
  },
  "Second Guardian's Particulars": {
    en: "Second Guardian's Phone Number",
    ja: "Second Guardian's Phone Number",
    et: "Second Guardian's Phone Number",
    lt: 'Antrasis Globėjų Detalės',
    ch: "Second Guardian's Phone Number",
    ph: "Second Guardian's Phone Number",
  },
  "Second Guardian's Phone Number*": {
    en: "Second Guardian's Phone Number*",
    ja: "Second Guardian's Phone Number*",
    et: "Second Guardian's Phone Number*",
    lt: 'Antrasis Globėjų Telefono numeris*',
    ch: "Second Guardian's Phone Number*",
    ph: "Second Guardian's Phone Number*",
  },
  'Signature:': {
    en: 'Signature:',
    ja: 'Signature:',
    et: 'Signature:',
    lt: 'Parašas:',
    ch: 'Signature:',
    ph: 'Signature:',
  },
  'Studio:': {
    en: 'Studio:',
    ja: 'Studio:',
    et: 'Studio:',
    lt: 'Studija:',
    ch: 'Studio:',
    ph: 'Studio:',
  },
  'take photographs and/or video of my minor child(ren)': {
    en: 'take photographs and/or video of my minor child(ren)',
    ja: 'take photographs and/or video of my minor child(ren)',
    et: 'take photographs and/or video of my minor child(ren)',
    lt: 'VIVITA fotografuoti ir (arba) filmuoti mano nepilnametį (-ius) vaiką (-us),',
    ch: 'take photographs and/or video of my minor child(ren)',
    ph: 'take photographs and/or video of my minor child(ren)',
  },
  'Thank you for signing up!': {
    en: 'Thank you for signing up!',
    ja: 'Thank you for signing up!',
    et: 'Thank you for signing up!',
    lt: 'Dėkojame, kad užsiregistravote!',
    ch: 'Thank you for signing up!',
    ph: 'Thank you for signing up!',
  },
  'The date selected does not exist': {
    en: 'The date selected does not exist',
    ja: 'The date selected does not exist',
    et: 'The date selected does not exist',
    lt: 'Pasirinkta data neegzistuoja',
    ch: 'The date selected does not exist',
    ph: 'The date selected does not exist',
  },
  'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the': {
    en: 'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the',
    ja: 'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the',
    et: 'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the',
    lt: 'Šioms Narystės taisyklėms ir sąlygoms taikomi ir jos aiškinamos paga',
    ch: 'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the',
    ph: 'These Membership Terms & Conditions shall be governed by and construed in accordance with the laws of the',
  },
  'Username*': {
    en: 'Username*',
    ja: 'ユーザー名*',
    et: 'Kasutajanimi*',
    lt: 'Vartotojo Vardas*',
    ch: 'Username*',
    ph: 'Username*',
  },
  'VIVIBOOM educational portal.': {
    en: 'VIVIBOOM educational portal.',
    ja: 'VIVIBOOM educational portal.',
    et: 'VIVIBOOM educational portal.',
    lt: 'VIVIBOOM švietimo portalu.',
    ch: 'VIVIBOOM educational portal.',
    ph: 'VIVIBOOM educational portal.',
  },
  'VIVIBOOM Username and Password': {
    en: 'VIVIBOOM Username and Password',
    ja: 'VIVIBOOM Username and Password',
    et: 'VIVIBOOM Username and Password',
    lt: 'VIVIBOOM Vartotojo Vardas ir Slaptažodis',
    ch: 'VIVIBOOM Username and Password',
    ph: 'VIVIBOOM Username and Password',
  },
  'VIVIVNAUT Agreement': {
    en: 'VIVIVNAUT Agreement',
    ja: 'VIVIVNAUT Agreement',
    et: 'VIVIVNAUT Agreement',
    lt: 'VIVIVNAUT Sutartis',
    ch: 'VIVIBOOM Agreement',
    ph: 'VIVIBOOM Agreement',
  },
  "VIVINAUT's Date of Birth*": {
    en: "VIVINAUT's Date of Birth*",
    ja: "VIVINAUT's Date of Birth*",
    et: "VIVINAUT's Date of Birth*",
    lt: "VIVINAUTO's Gimimo Data*",
    ch: "VIVINAUT's Date of Birth*",
    ph: "VIVINAUT's Date of Birth*",
  },
  "VIVINAUT's Education Level*": {
    en: "VIVINAUT's Education Level*",
    ja: "VIVINAUT's Education Level*",
    et: "VIVINAUT's Education Level*",
    lt: "VIVINAUTO's Švietimo Lygis*",
    ch: "VIVINAUT's Education Level*",
    ph: "VIVINAUT's Education Level*",
  },
  "VIVINAUT's Email (optional)": {
    en: "VIVINAUT's Email (optional)",
    ja: "VIVINAUT's Email (optional)",
    et: "VIVINAUT's Email (optional)",
    lt: "VIVINAUTO's El. pašto adresa (neprivaloma)",
    ch: "VIVINAUT's Email (optional)",
    ph: "VIVINAUT's Email (optional)",
  },
  "VIVINAUT's Family Name*": {
    en: "VIVINAUT's Family Name*",
    ja: "VIVINAUT's Family Name*",
    et: "VIVINAUT's Family Name*",
    lt: "VIVINAUTO's Pavardė*",
    ch: "VIVINAUT's Family Name*",
    ph: "VIVINAUT's Family Name*",
  },
  "VIVINAUT's First Name*": {
    en: "VIVINAUT's First Name*",
    ja: "VIVINAUT's First Name*",
    et: "VIVINAUT's First Name*",
    lt: "VIVINAUTO's Pavardė*",
    ch: "VIVINAUT's First Name*",
    ph: "VIVINAUT's First Name*",
  },
  "VIVINAUT's Gender*": {
    en: "VIVINAUT's Gender*",
    ja: "VIVINAUT's Gender*",
    et: "VIVINAUT's Gender*",
    lt: "VIVINAUTO's Lytis*",
    ch: "VIVINAUT's Gender*",
    ph: "VIVINAUT's Gender*",
  },
  "VIVINAUT's Given Name*": {
    en: "VIVINAUT's Given Name*",
    ja: "VIVINAUT's Given Name*",
    et: "VIVINAUT's Given Name*",
    lt: "VIVINAUTO's Vardas*",
    ch: "VIVINAUT's Given Name*",
    ph: "VIVINAUT's Given Name*",
  },
  "VIVINAUT's Last Name*": {
    en: "VIVINAUT's Last Name*",
    ja: "VIVINAUT's Last Name*",
    et: "VIVINAUT's Last Name*",
    lt: "VIVINAUTO's Vardas*",
    ch: "VIVINAUT's Last Name*",
    ph: "VIVINAUT's Last Name*",
  },
  "VIVINAUT's Particulars": {
    en: "VIVINAUT's Particulars",
    ja: "VIVINAUT's Particulars",
    et: "VIVINAUT's Particulars",
    lt: "VIVINAUTO's Detalės",
    ch: "VIVINAUT's Particulars",
    ph: "VIVINAUT's Particulars",
  },
  "VIVINAUT's Phone Number (optional)": {
    en: "VIVINAUT's Phone Number (optional)",
    ja: "VIVINAUT's Phone Number (optional)",
    et: "VIVINAUT's Phone Number (optional)",
    lt: "VIVINAUTO's Telefono numeris (neprivaloma)",
    ch: "VIVINAUT's Phone Number (optional)",
    ph: "VIVINAUT's Phone Number (optional)",
  },
  "VIVINAUT's Preferred Name": {
    en: "VIVINAUT's Preferred Name",
    ja: "VIVINAUT's Preferred Name",
    et: "VIVINAUT's Preferred Name",
    lt: "VIVINAUTO's Pageidaujamas Vardas",
    ch: "VIVINAUT's Preferred Name",
    ph: "VIVINAUT's Preferred Name",
  },
  "VIVINAUT's School Grade*": {
    en: "VIVINAUT's School Grade*",
    ja: "VIVINAUT's School Grade*",
    et: "VIVINAUT's School Grade*",
    lt: 'VIVINAUT mokyklos klasė*',
    ch: "VIVINAUT's School Grade*",
    ph: "VIVINAUT's School Grade*",
  },
  'VIVINAUT Agreement': {
    en: 'VIVINAUT Agreement',
    ja: 'VIVINAUT Agreement',
    et: 'VIVINAUT Agreement',
    lt: 'VIVINAUTO Sutartis',
    ch: 'VIVINAUT Agreement',
    ph: 'VIVINAUT Agreement',
  },
  'VIVINAUT AGREEMENT': {
    en: 'VIVINAUT AGREEMENT',
    ja: 'VIVINAUT AGREEMENT',
    et: 'VIVINAUT AGREEMENT',
    lt: 'VIVINAUTO SUTARTIS',
    ch: 'VIVINAUT AGREEMENT',
    ph: 'VIVINAUT AGREEMENT',
  },
  "VIVITA's Internal Rules regarding online services": {
    en: "VIVITA's Internal Rules regarding online services",
    ja: "VIVITA's Internal Rules regarding online services",
    et: "VIVITA's Internal Rules regarding online services",
    lt: 'VIVITA vidaus taisykles, susijusias su internetinėmis paslaugomis',
    ch: "VIVITA's Internal Rules regarding online services",
    ph: "VIVITA's Internal Rules regarding online services",
  },
  'VIVITA Membership Terms and Conditions': {
    en: 'VIVITA Membership Terms and Conditions',
    ja: 'VIVITA Membership Terms and Conditions',
    et: 'VIVITA Membership Terms and Conditions',
    lt: 'VIVITA Narystės sąlygos',
    ch: 'VIVITA Membership Terms and Conditions',
    ph: 'VIVITA Membership Terms and Conditions',
  },
  'Name of Parent / Guardian:': {
    en: 'Name of Parent / Guardian:',
    ja: 'Name of Parent / Guardian:',
    et: 'Name of Parent / Guardian:',
    lt: 'VIVITA subjekto pavadinimas:',
    ch: 'Name of Parent / Guardian:',
    ph: 'Name of Parent / Guardian:',
  },
  "VIVITA's privacy notice": {
    en: "VIVITA's privacy notice",
    ja: "VIVITA's privacy notice",
    et: "VIVITA's privacy notice",
    lt: 'VIVITA privatumo pranešime',
    ch: "VIVITA's privacy notice",
    ph: "VIVITA's privacy notice",
  },
  'WELCOME TO': {
    en: 'WELCOME TO',
    ja: 'WELCOME TO',
    et: 'WELCOME TO',
    lt: 'Sveiki atvykę į',
    ch: 'WELCOME TO',
    ph: 'WELCOME TO',
  },
  "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.": {
    en: "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    ja: "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    et: "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    lt: 'dalyvaujantį (-čius) VIVITA seminaruose ir užsiėmimuose, ir sutinku, kad VIVITA šias nuotraukas ir (arba) filmuotą medžiagą skelbtų bet kokiu teisėtu būdu, įskaitant viešinimą, socialinę žiniasklaidą ir interneto turinį.',
    ch: "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
    ph: "while participating in VIVITA's workshops and activities, and consent to the publication of these photographs and/or video by VIVITA for any legal use, including for publicity, social media, and web content.",
  },
  'years of age': {
    en: 'years of age',
    ja: 'years of age',
    et: 'years of age',
    lt: 'metų',
    ch: 'years of age',
    ph: 'years of age',
  },
  'YES! I want to be a VIVINAUT!': {
    en: 'YES! I want to be a VIVINAUT!',
    ja: 'YES! I want to be a VIVINAUT!',
    et: 'YES! I want to be a VIVINAUT!',
    lt: 'TAIP! Aš noriu būti VIVINAUTAS!',
    ch: 'YES! I want to be a VIVINAUT!',
    ph: 'YES! I want to be a VIVINAUT!',
  },
  '(1) I have read this document, understand it, and sign voluntarily and without any inducement.': {
    en: '(1) I have read this document, understand it, and sign voluntarily and without any inducement.',
    ja: '(1) I have read this document, understand it, and sign voluntarily and without any inducement.',
    et: '(1) I have read this document, understand it, and sign voluntarily and without any inducement.',
    lt: '(1) perskaičiau šį dokumentą, jį suprantu ir pasirašau savanoriškai ir be jokių įkalbinėjimų.',
    ch: '(1) I have read this document, understand it, and sign voluntarily and without any inducement.',
    ph: '(1) I have read this document, understand it, and sign voluntarily and without any inducement.',
  },
  '(2) I am at least 18 years of age and am of sound mind.': {
    en: '(2) I am at least 18 years of age and am of sound mind.',
    ja: '(2) I am at least 18 years of age and am of sound mind.',
    et: '(2) I am at least 18 years of age and am of sound mind.',
    lt: '(2) man yra ne mažiau kaip 18 metų ir esu veiksnus.',
    ch: '(2) I am at least 18 years of age and am of sound mind.',
    ph: '(2) I am at least 18 years of age and am of sound mind.',
  },
  '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.': {
    en: '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.',
    ja: '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.',
    et: '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.',
    lt: '(3) esu pirmiau išvardytų nepilnamečių vaikų teisėtas globėjas ir pasirašau šią sutartį jų vardu.',
    ch: '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.',
    ph: '(3) I am the legal guardian of the minor child(ren) listed above and sign this Agreement on their behalf.',
  },
  "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and": {
    en: "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and",
    ja: "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and",
    et: "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and",
    lt: '(4) pirmiau nurodytas (-i) nepilnametis (-iai) vaikas (-ai) yra sveikas (-i) ir neturi jokių sveikatos sutrikimų, kurie trukdytų jam (jiems) saugiai dalyvauti VIVITA veikloje, ir ',
    ch: "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and",
    ph: "(4) The minor child(ren) listed above is in good health and has no conditions or impairments which would preclude his/her safe participation in VIVITA's workshop and activities and",
  },
  '(5) The minor child(ren) listed above is at least': {
    en: '(5) The minor child(ren) listed above is at least',
    ja: '(5) The minor child(ren) listed above is at least',
    et: '(5) The minor child(ren) listed above is at least',
    lt: '(5) pirmiau nurodytas (-i) nepilnametis (-iai) vaikas (-ai) yra ne jaunesnis (-i) kaip devynerių metų.',
    ch: '(5) The minor child(ren) listed above is at least',
    ph: '(5) The minor child(ren) listed above is at least',
  },
};
