// Email related stuff

export default {
  verifyEmail: {
    en: 'Verify Email',
    ja: 'メール確認',
    et: 'Kinnita E-mail',
    lt: 'Verify Email',
  },
  verifyEmailText: {
    en: "Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.",
    ja: 'ログインする前に、メールアドレスを確認する必要があります。 下にメールを入力して、別の確認リンクを受け取ることができます。',
    et: 'Enne kui sa saad sisse logida, pead kinnitama oma e-maili aadressi. Kirjuta oma e-mail siia uuesti, kui soovid uuesti linki saada.',
    lt: "Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.",
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-maili aadress',
    lt: 'Email',
    ch: 'Email',
    ph: 'Email',
  },
  sendVerificationCode: {
    en: 'Send Verification Code',
    ja: '確認コードを送信する',
    et: 'Saada kinnituskood',
    lt: 'Send Verification Code',
    ch: 'Send Verification Code',
    ph: 'Send Verification Code',
  },
  emailSentText: {
    en: 'Email sent, please check your email. ',
    ja: 'メールを送信しました。メールをご確認ください。',
    et: 'Kiri saadetud, kontrolli oma e-maile',
    lt: 'Email sent, please check your email. ',
    ch: 'Email sent, please check your email. ',
    ph: 'Email sent, please check your email. ',
  },
  verifyingToken: {
    en: 'Verifying Token',
    ja: 'トークン確認中',
    et: 'Kinnituskood',
    lt: 'Verifying Token',
    ch: 'Verifying Token',
    ph: 'Verifying Token',
  },
  verifyTokenSuccess: {
    en: 'Success, Email verified. Logging you in!',
    ja: '成功、メールを確認しました。 ログインしています！',
    et: 'Päring õnnestus, e-mail on kinnitatud. Sind logitakse sisse',
    lt: 'Success, Email verified. Logging you in!',
    ch: 'Success, Email verified. Logging you in!',
    ph: 'Success, Email verified. Logging you in!',
  },
  resetTokenSent: {
    en: 'Reset Token Email Sent.',
    ja: 'Reset Token Email Sent.',
    et: 'Reset Token Email Sent.',
    lt: 'Reset Token Email Sent.',
    ch: 'Reset Token Email Sent.',
    ph: 'Reset Token Email Sent.',
  },
  resetPassword: {
    en: 'Reset Password',
    ja: 'パスワードリセット',
    et: 'Vaheta parool',
    lt: 'Reset Password',
    ch: 'Reset Password',
    ph: 'Reset Password',
  },
  resetPasswordText: {
    en: 'Forgot your password? Type in your email below to receive a reset link.',
    ja: 'パスワードをお忘れですか？ リセットリンクを受け取るには、下にメールを入力してください。',
    et: 'Unustasid parooli? Siseta oma aadress, et saada parooli vahetamise link.',
    lt: 'Forgot your password? Type in your email below to receive a reset link.',
    ch: 'Forgot your password? Type in your email below to receive a reset link.',
    ph: 'Forgot your password? Type in your email below to receive a reset link.',
  },
  sendResetPasswordLinkButton: {
    en: 'Send Reset Password Link',
    ja: 'パスワードリセットリンク送信する',
    et: 'Saada parooli vahetamise link',
    lt: 'Send Reset Password Link',
    ch: 'Send Reset Password Link',
    ph: 'Send Reset Password Link',
  },
  sentResetPasswordLink: {
    en: 'Reset Password Email sent! Please check your email. ',
    ja: 'パスワードのリセットメールを送信しました！ メールを確認してください。',
    et: 'Parooli vahetamise link saadetud, kontrolli oma e-maili.',
    lt: 'Reset Password Email sent! Please check your email. ',
    ch: 'Reset Password Email sent! Please check your email. ',
    ph: 'Reset Password Email sent! Please check your email. ',
  },
  setNewPassword: {
    en: 'Set New Password',
    ja: '新しいパスワードを設定',
    et: 'Määra uus parool',
    lt: 'Set New Password',
    ch: 'Set New Password',
    ph: 'Set New Password',
  },
  newPassword: {
    en: 'New Password',
    ja: '新しいパスワード',
    et: 'Uus parool',
    lt: 'New Password',
    ch: 'New Password',
    ph: 'New Password',
  },
  setPasswordButton: {
    en: 'Set Password',
    ja: 'パスワード設定する',
    et: 'Määra parool',
    lt: 'Set Password',
    ch: 'Set Password',
    ph: 'Set Password',
  },
  setPasswordSuccess: {
    en: 'Successfully Set New Password!',
    ja: '新しいパスワードを設定しました！',
    et: 'Parooli vahetamine õnnestus',
    lt: 'Successfully Set New Password!',
    ch: 'Successfully Set New Password!',
    ph: 'Successfully Set New Password!',
  },

  verifyEmailChange: {
    en: 'Verify Email Change',
    ja: 'メールの変更確認',
    et: 'Kinnita e-maili aadressi vahetus',
    lt: 'Verify Email Change',
    ch: 'Verify Email Change',
    ph: 'Verify Email Change',
  },
  verifyEmailChangeSuccess: {
    en: 'Success! Email Changed. toast, Redirecting you',
    ja: '成功！ メールが変更されました',
    et: 'E-maili vahetus õnnestus, suunan ümber',
    lt: 'Success! Email Changed. toast, Redirecting you',
    ch: 'Success! Email Changed. toast, Redirecting you',
    ph: 'Success! Email Changed. toast, Redirecting you',
  },
  tokenInvalid: {
    en: 'Either that token is invalid or expired. ',
    ja: 'トークンは無効か期限切れです。',
    et: 'Kood on aegunud või pole õige',
    lt: 'Either that token is invalid or expired. ',
    ch: 'Either that token is invalid or expired. ',
    ph: 'Either that token is invalid or expired. ',
  },
  verifyEmailChangeErrorText: {
    en: 'Looks like something went wrong. Please request a new reset password link. ',
    ja: '問題が発生したようです。 新しいパスワードのリセットリンクをリクエストしてください',
    et: 'Midagi läks viltu. Küsi endale uus parooli vahetamise link.',
    lt: 'Looks like something went wrong. Please request a new reset password link. ',
    ch: 'Looks like something went wrong. Please request a new reset password link. ',
    ph: 'Looks like something went wrong. Please request a new reset password link. ',
  },
  verifyEmailChangeErrorListText1: {
    en: "1. If you're not signed in already, sign in. ",
    ja: '1. まだサインインしていない場合は、サインインしてください。',
    et: '1. Kui sa pole veel sisse logitud, logi sisse',
    lt: "1. If you're not signed in already, sign in. ",
    ch: "1. If you're not signed in already, sign in. ",
    ph: "1. If you're not signed in already, sign in. ",
  },
  verifyEmailChangeErrorListText2: {
    en: '2. Go to My account. ',
    ja: '2. マイアカウントに移動します。',
    et: '2. Mine Minu konto-le',
    lt: '2. Go to My account. ',
    ch: '2. Go to My account. ',
    ph: '2. Go to My account. ',
  },
  verifyEmailChangeErrorListText3: {
    en: '3. Click on the edit button next to email. ',
    ja: '3. メールの横にある編集ボタンをクリックします。',
    et: '3. Vajuta Muuda nuppu E-maili juures',
    lt: '3. Click on the edit button next to email. ',
    ch: '3. Click on the edit button next to email. ',
    ph: '3. Click on the edit button next to email. ',
  },
  verifyEmailChangeErrorListText4: {
    en: "4. Type in the email you'd like to change your account to. ",
    ja: '4. アカウントを変更するメールを入力します。',
    et: '4. Siseta e-maili aadress, mida soovid edaspidi kasutada',
    lt: "4. Type in the email you'd like to change your account to. ",
    ch: "4. Type in the email you'd like to change your account to. ",
    ph: "4. Type in the email you'd like to change your account to. ",
  },

};
