export default {
  All: {
    en: 'All',
    et: 'Kõik',
    ja: '全て',
    lt: 'All',
    ch: 'All',
    ph: 'All',
  },
  Author: {
    en: 'Author',
    et: 'Autor',
    ja: '作者',
    lt: 'Author',
    ch: 'Author',
    ph: 'Author',
  },
  'Comment ID': {
    en: 'Comment ID',
    et: 'Comment ID',
    ja: 'Comment ID',
    lt: 'Comment ID',
    ch: 'Comment ID',
    ph: 'Comment ID',
  },
  'Comment deleted': {
    en: 'Comment deleted',
    et: 'Comment deleted',
    ja: 'Comment deleted',
    lt: 'Comment deleted',
    ch: 'Comment deleted',
    ph: 'Comment deleted',
  },
  'Comment unflagged': {
    en: 'Comment unflagged',
    et: 'Comment unflagged',
    ja: 'Comment unflagged',
    lt: 'Comment unflagged',
    ch: 'Comment unflagged',
    ph: 'Comment unflagged',
  },
  Comments: {
    en: 'Comments',
    et: 'Kommentaarid',
    ja: 'コメント',
    lt: 'Comments',
    ch: 'Comments',
    ph: 'Comments',
  },
  'Created Date': {
    en: 'Created Date',
    et: 'Loomise kuupäev',
    ja: '作成日',
    lt: 'Created Date',
    ch: 'Created Date',
    ph: 'Created Date',
  },
  'DELETE! Are you absolutely certain that you want to DELETE this comment?': {
    en: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    et: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ja: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    lt: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ch: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ph: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
  },
  'Delete Comment': {
    en: 'Delete Comment',
    et: 'Delete Comment',
    ja: 'Delete Comment',
    lt: 'Delete Comment',
    ch: 'Delete Comment',
    ph: 'Delete Comment',
  },
  Flagged: {
    en: 'Flagged',
    et: 'Flagged',
    ja: 'Flagged',
    lt: 'Flagged',
    ch: 'Flagged',
    ph: 'Flagged',
  },
  Likes: {
    en: 'Likes',
    et: 'Likes',
    ja: 'Likes',
    lt: 'Likes',
    ch: 'Likes',
    ph: 'Likes',
  },
  No: {
    en: 'No',
    et: 'Ei',
    ja: 'いいえ',
    lt: 'No',
    ch: 'No',
    ph: 'No',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  'Not Flagged': {
    en: 'Not Flagged',
    et: 'Not Flagged',
    ja: 'Not Flagged',
    lt: 'Not Flagged',
    ch: 'Not Flagged',
    ph: 'Not Flagged',
  },
  Project: {
    en: 'Project',
    et: 'Project',
    ja: 'Project',
    lt: 'Project',
    ch: 'Project',
    ph: 'Project',
  },
  'Project Name': {
    en: 'Project Name',
    et: 'Project Name',
    ja: 'Project Name',
    lt: 'Project Name',
    ch: 'Project Name',
    ph: 'Project Name',
  },
  'Project Title': {
    en: 'Project Title',
    et: 'Project Title',
    ja: 'Project Title',
    lt: 'Project Title',
    ch: 'Project Title',
    ph: 'Project Title',
  },
  'Select Projects': {
    en: 'Select Projects',
    et: 'Select Projects',
    ja: 'Select Projects',
    lt: 'Select Projects',
    ch: 'Select Projects',
    ph: 'Select Projects',
  },
  'Select Users': {
    en: 'Select Users',
    et: 'Select Users',
    ja: 'Select Users',
    lt: 'Select Users',
    ch: 'Select Users',
    ph: 'Select Users',
  },
  Text: {
    en: 'Text',
    et: 'Text',
    ja: 'Text',
    lt: 'Text',
    ch: 'Text',
    ph: 'Text',
  },
  'Unflag Comment': {
    en: 'Unflag Comment',
    et: 'Unflag Comment',
    ja: 'Unflag Comment',
    lt: 'Unflag Comment',
    ch: 'Unflag Comment',
    ph: 'Unflag Comment',
  },
  'Updated Date': {
    en: 'Updated Date',
    ja: '編集された日付',
    et: 'Muutmise kuupäev',
    lt: 'Updated Date',
    ch: 'Updated Date',
    ph: 'Updated Date',
  },
  User: {
    en: 'User',
    et: 'User',
    ja: 'User',
    lt: 'User',
    ch: 'User',
    ph: 'User',
  },
  Username: {
    en: 'Username',
    et: 'Username',
    ja: 'Username',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  'View Comment': {
    en: 'View Comment',
    et: 'View Comment',
    ja: 'View Comment',
    lt: 'View Comment',
    ch: 'View Comment',
    ph: 'View Comment',
  },
  Yes: {
    en: 'Yes',
    et: 'jah',
    ja: 'はい',
    lt: 'Yes',
    ch: 'Yes',
    ph: 'Yes',
  },
};
