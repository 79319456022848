export default {
  'Back to main': {
    en: 'Back to main',
    ja: 'Back to main',
    et: 'Back to main',
    lt: 'Back to main',
    ch: 'Back to main',
    ph: 'Back to main',
  },
  day_one: {
    en: '{{count}} day ',
    ja: '{{count}} day ',
    et: '{{count}} day ',
    lt: '{{count}} day ',
    ph: '{{count}} day ',
    ch: '{{count}} day ',
  },
  day_other: {
    en: '{{count}} days ',
    ja: '{{count}} days ',
    et: '{{count}} days ',
    lt: '{{count}} days ',
    ph: '{{count}} days ',
    ch: '{{count}} days ',
  },
  hour_one: {
    en: '{{count}} hr ',
    ja: '{{count}} hr ',
    et: '{{count}} hr ',
    lt: '{{count}} hr ',
    ph: '{{count}} hr ',
    ch: '{{count}} hr ',
  },
  hour_other: {
    en: '{{count}} hrs ',
    ja: '{{count}} hrs ',
    et: '{{count}} hrs ',
    lt: '{{count}} hrs ',
    ph: '{{count}} hrs ',
    ch: '{{count}} hrs ',
  },
  minute_one: {
    en: '{{count}} min ',
    ja: '{{count}} min ',
    et: '{{count}} min ',
    lt: '{{count}} min ',
    ph: '{{count}} min ',
    ch: '{{count}} min ',
  },
  minute_other: {
    en: '{{count}} mins ',
    ja: '{{count}} mins ',
    et: '{{count}} mins ',
    lt: '{{count}} mins ',
    ph: '{{count}} mins ',
    ch: '{{count}} mins ',
  },
};
