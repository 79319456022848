export default {
  edit: {
    en: 'Edit',
    ja: '編集',
    et: 'Muuda',
    lt: 'Edit',
    ch: 'Edit',
    ph: 'Edit',
  },
  save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  create: {
    en: 'Create',
    ja: '作成',
    et: 'Loo',
    lt: 'Create',
    ch: 'Create',
    ph: 'Create',
  },
  remove: {
    en: 'Remove',
    ja: '解除',
    et: 'Kustuta',
    lt: 'Remove',
    ch: 'Remove',
    ph: 'Remove',
  },
  requestErr: {
    en: 'Request Error: Refresh the page to try again. Contact us if this continues. ',
    ja: 'リクエストエラー：ページを更新して再試行してください。 これが続く場合はお問い合わせください。',
    et: 'Päringu viga: Lae leht uuesti. Võta ühendust, kui see viga jätkub.',
    lt: 'Request Error: Refresh the page to try again. Contact us if this continues. ',
    ch: 'Request Error: Refresh the page to try again. Contact us if this continues. ',
    ph: 'Request Error: Refresh the page to try again. Contact us if this continues. ',
  },
  January: {
    en: 'January',
    ja: '一月',
    et: 'Jaanuar',
    lt: 'Sausio mėn',
    ch: 'January',
    ph: 'January',
  },
  February: {
    en: 'February',
    ja: '二月',
    et: 'Veebruar',
    lt: 'Vasario mėn',
    ch: 'February',
    ph: 'February',
  },
  March: {
    en: 'March',
    ja: '三月',
    et: 'Märts',
    lt: 'Kovas',
    ch: 'March',
    ph: 'March',
  },
  April: {
    en: 'April',
    ja: '４月',
    et: 'Aprill',
    lt: 'Balandis',
    ch: 'April',
    ph: 'April',
  },
  May: {
    en: 'May',
    ja: '５月',
    et: 'Mai',
    lt: 'Gegužė',
    ch: 'May',
    ph: 'May',
  },
  June: {
    en: 'June',
    ja: '６月',
    et: 'Juuni',
    lt: 'Birželio mėn',
    ch: 'June',
    ph: 'June',
  },
  July: {
    en: 'July',
    ja: '７月',
    et: 'Juuli',
    lt: 'Liepos mėn',
    ch: 'July',
    ph: 'July',
  },
  August: {
    en: 'August',
    ja: '８月',
    et: 'August',
    lt: 'Rugpjūtis',
    ch: 'August',
    ph: 'August',
  },
  September: {
    en: 'September',
    ja: '９月',
    et: 'September',
    lt: 'Rugsėjis',
    ch: 'September',
    ph: 'September',
  },
  October: {
    en: 'October',
    ja: '１０月',
    et: 'Oktoober',
    lt: 'Spalio mėn',
    ch: 'October',
    ph: 'October',
  },
  November: {
    en: 'November',
    ja: '１１月',
    et: 'November',
    lt: 'Lapkritis',
    ch: 'November',
    ph: 'November',
  },
  December: {
    en: 'December',
    ja: '１２月',
    et: 'Detsember',
    lt: 'Gruodį',
    ch: 'December',
    ph: 'December',
  },
  autoLogout: {
    en: 'Your session has expired. Please log in again.',
    ja: 'Your session has expired. Please log in again.',
    et: 'Your session has expired. Please log in again.',
    lt: 'Your session has expired. Please log in again.',
    ch: 'Your session has expired. Please log in again.',
    ph: 'Your session has expired. Please log in again.',
  },
  unauthorizedPage: {
    en: 'You are not authorized to view this page.',
    ja: 'You are not authorized to view this page.',
    et: 'You are not authorized to view this page.',
    lt: 'You are not authorized to view this page.',
    ch: 'You are not authorized to view this page.',
    ph: 'You are not authorized to view this page.',
  },
  Year: {
    en: 'Year',
    ja: 'Year',
    et: 'Year',
    lt: 'Metai',
    ch: 'Year',
    ph: 'Year',
  },
  Month: {
    en: 'Month',
    ja: 'Month',
    et: 'Month',
    lt: 'Mėnuo',
    ch: 'Month',
    ph: 'Month',
  },
  Day: {
    en: 'Day',
    ja: 'Day',
    et: 'Day',
    lt: 'Diena',
    ch: 'Day',
    ph: 'Day',
  },
};
