export default {
  'Activate Wallet': {
    en: 'Activate Wallet',
    ja: 'Activate Wallet',
    et: 'Activate Wallet',
    lt: 'Activate Wallet',
    ch: 'Activate Wallet',
    ph: 'Activate Wallet',
  },
  'Add New Reward': {
    en: 'Add New Reward',
    ja: 'Add New Reward',
    et: 'Add New Reward',
    lt: 'Add New Reward',
    ch: 'Add New Reward',
    ph: 'Add New Reward',
  },
  All: {
    en: 'All',
    ja: 'All',
    et: 'All',
    lt: 'All',
    ch: 'All',
    ph: 'All',
  },
  'All Rewards': {
    en: 'All Rewards',
    ja: 'All Rewards',
    et: 'All Rewards',
    lt: 'All Rewards',
    ch: 'All Rewards',
    ph: 'All Rewards',
  },
  Amount: {
    en: 'Amount',
    ja: 'Amount',
    et: 'Amount',
    lt: 'Amount',
    ch: 'Amount',
    ph: 'Amount',
  },
  Balance: {
    en: 'Balance',
    ja: 'Balance',
    et: 'Balance',
    lt: 'Balance',
    ch: 'Balance',
    ph: 'Balance',
  },
  Branch: {
    en: 'Branch',
    ja: 'Branch',
    et: 'Branch',
    lt: 'Branch',
    ch: 'Branch',
    ph: 'Branch',
  },
  Claimed: {
    en: 'Claimed',
    ja: 'Claimed',
    et: 'Claimed',
    lt: 'Claimed',
    ch: 'Claimed',
    ph: 'Claimed',
  },
  'Claimed By': {
    en: 'Claimed By',
    ja: 'Claimed By',
    et: 'Claimed By',
    lt: 'Claimed By',
    ch: 'Claimed By',
    ph: 'Claimed By',
  },
  'Created At': {
    en: 'Created At',
    ja: 'Created At',
    et: 'Created At',
    lt: 'Created At',
    ch: 'Created At',
    ph: 'Created At',
  },
  'Created Date': {
    en: 'Created Date',
    ja: '作成日',
    et: 'Loomise kuupäev',
    lt: 'Created Date',
    ch: 'Created Date',
    ph: 'Created Date',
  },
  'Create Reward': {
    en: 'Create Reward',
    ja: 'Create Reward',
    et: 'Create Reward',
    lt: 'Create Reward',
    ch: 'Create Reward',
    ph: 'Create Reward',
  },
  'Create Wallet': {
    en: 'Create Wallet',
    ja: 'Create Wallet',
    et: 'Create Wallet',
    lt: 'Create Wallet',
    ch: 'Create Wallet',
    ph: 'Create Wallet',
  },
  'Deactivate Wallet': {
    en: 'Deactivate Wallet',
    ja: 'Deactivate Wallet',
    et: 'Deactivate Wallet',
    lt: 'Deactivate Wallet',
    ch: 'Deactivate Wallet',
    ph: 'Deactivate Wallet',
  },
  Description: {
    en: 'Description',
    ja: 'Description',
    et: 'Description',
    lt: 'Description',
    ch: 'Description',
    ph: 'Description',
  },
  'DELETE! Are you absolutely certain that you want to DELETE this comment?': {
    en: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ja: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    et: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    lt: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ch: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
    ph: 'DELETE! Are you absolutely certain that you want to DELETE this comment?',
  },
  'Delete Comment': {
    en: 'Delete Comment',
    ja: 'Delete Comment',
    et: 'Delete Comment',
    lt: 'Delete Comment',
    ch: 'Delete Comment',
    ph: 'Delete Comment',
  },
  'Delete Reward': {
    en: 'Delete Reward',
    ja: 'Delete Reward',
    et: 'Delete Reward',
    lt: 'Delete Reward',
    ch: 'Delete Reward',
    ph: 'Delete Reward',
  },
  'Download QR Code': {
    en: 'Download QR Code',
    ja: 'Download QR Code',
    et: 'Download QR Code',
    lt: 'Download QR Code',
    ch: 'Download QR Code',
    ph: 'Download QR Code',
  },
  'Enter reward code...': {
    en: 'Enter reward code...',
    ja: 'Enter reward code...',
    et: 'Enter reward code...',
    lt: 'Enter reward code...',
    ch: 'Enter reward code...',
    ph: 'Enter reward code...',
  },
  Expired: {
    en: 'Expired',
    ja: 'Expired',
    et: 'Expired',
    lt: 'Expired',
    ch: 'Expired',
    ph: 'Expired',
  },
  'Expire At': {
    en: 'Expire At',
    ja: 'Expire At',
    et: 'Expire At',
    lt: 'Expire At',
    ch: 'Expire At',
    ph: 'Expire At',
  },
  'Expire At (Optional)': {
    en: 'Expire At (Optional)',
    ja: 'Expire At (Optional)',
    et: 'Expire At (Optional)',
    lt: 'Expire At (Optional)',
    ch: 'Expire At (Optional)',
    ph: 'Expire At (Optional)',
  },
  'Find User': {
    en: 'Find User',
    ja: 'Find User',
    et: 'Find User',
    lt: 'Find User',
    ch: 'Find User',
    ph: 'Find User',
  },
  'Generate New Code': {
    en: 'Generate New Code',
    ja: 'Generate New Code',
    et: 'Generate New Code',
    lt: 'Generate New Code',
    ch: 'Generate New Code',
    ph: 'Generate New Code',
  },
  New: {
    en: 'New',
    ja: 'New',
    et: 'New',
    lt: 'New',
    ch: 'New',
    ph: 'New',
  },
  No: {
    en: 'No',
    ja: 'いいえ',
    et: 'Ei',
    lt: 'No',
    ch: 'No',
    ph: 'No',
  },
  'No Expiry Date': {
    en: 'No Expiry Date',
    ja: 'No Expiry Date',
    et: 'No Expiry Date',
    lt: 'No Expiry Date',
    ch: 'No Expiry Date',
    ph: 'No Expiry Date',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  'Please create reward code': {
    en: 'Please create reward code',
    ja: 'Please create reward code',
    et: 'Please create reward code',
    lt: 'Please create reward code',
    ch: 'Please create reward code',
    ph: 'Please create reward code',
  },
  'Please determine reward amount': {
    en: 'Please determine reward amount',
    ja: 'Please determine reward amount',
    et: 'Please determine reward amount',
    lt: 'Please determine reward amount',
    ch: 'Please determine reward amount',
    ph: 'Please determine reward amount',
  },
  Receiver: {
    en: 'Receiver',
    ja: 'Receiver',
    et: 'Receiver',
    lt: 'Receiver',
    ch: 'Receiver',
    ph: 'Receiver',
  },
  'Receiver Username': {
    en: 'Receiver Username',
    ja: 'Receiver Username',
    et: 'Receiver Username',
    lt: 'Receiver Username',
    ch: 'Receiver Username',
    ph: 'Receiver Username',
  },
  'Receiver Wallet ID': {
    en: 'Receiver Wallet ID',
    ja: 'Receiver Wallet ID',
    et: 'Receiver Wallet ID',
    lt: 'Receiver Wallet ID',
    ch: 'Receiver Wallet ID',
    ph: 'Receiver Wallet ID',
  },
  Rewards: {
    en: 'Rewards',
    ja: 'Rewards',
    et: 'Rewards',
    lt: 'Rewards',
    ch: 'Rewards',
    ph: 'Rewards',
  },
  'Reward added': {
    en: 'Reward added',
    ja: 'Reward added',
    et: 'Reward added',
    lt: 'Reward added',
    ch: 'Reward added',
    ph: 'Reward added',
  },
  'Reward Amount': {
    en: 'Reward Amount',
    ja: 'Reward Amount',
    et: 'Reward Amount',
    lt: 'Reward Amount',
    ch: 'Reward Amount',
    ph: 'Reward Amount',
  },
  'Reward Assets': {
    en: 'Reward Assets',
    ja: 'Reward Assets',
    et: 'Reward Assets',
    lt: 'Reward Assets',
    ch: 'Reward Assets',
    ph: 'Reward Assets',
  },
  'Reward deleted': {
    en: 'Reward deleted',
    ja: 'Reward deleted',
    et: 'Reward deleted',
    lt: 'Reward deleted',
    ch: 'Reward deleted',
    ph: 'Reward deleted',
  },
  'Reward Details': {
    en: 'Reward Details',
    ja: 'Reward Details',
    et: 'Reward Details',
    lt: 'Reward Details',
    ch: 'Reward Details',
    ph: 'Reward Details',
  },
  'Rewards Function have been disabled for this branch': {
    en: 'Rewards Function have been disabled for this branch',
    ja: 'Rewards Function have been disabled for this branch',
    et: 'Rewards Function have been disabled for this branch',
    lt: 'Rewards Function have been disabled for this branch',
    ch: 'Rewards Function have been disabled for this branch',
    ph: 'Rewards Function have been disabled for this branch',
  },
  'Reward modified': {
    en: 'Reward modified',
    ja: 'Reward modified',
    et: 'Reward modified',
    lt: 'Reward modified',
    ch: 'Reward modified',
    ph: 'Reward modified',
  },
  'Reward Code': {
    en: 'Reward Code',
    ja: 'Reward Code',
    et: 'Reward Code',
    lt: 'Reward Code',
    ch: 'Reward Code',
    ph: 'Reward Code',
  },
  'Reward ID': {
    en: 'Reward ID',
    ja: 'Reward ID',
    et: 'Reward ID',
    lt: 'Reward ID',
    ch: 'Reward ID',
    ph: 'Reward ID',
  },
  'Reward Link': {
    en: 'Reward Link',
    ja: 'Reward Link',
    et: 'Reward Link',
    lt: 'Reward Link',
    ch: 'Reward Link',
    ph: 'Reward Link',
  },
  'Reward QR Code': {
    en: 'Reward QR Code',
    ja: 'Reward QR Code',
    et: 'Reward QR Code',
    lt: 'Reward QR Code',
    ch: 'Reward QR Code',
    ph: 'Reward QR Code',
  },
  'Rewards Settings': {
    en: 'Rewards Settings',
    ja: 'Rewards Settings',
    et: 'Rewards Settings',
    lt: 'Rewards Settings',
    ch: 'Rewards Settings',
    ph: 'Rewards Settings',
  },
  Save: {
    en: 'Save',
    ja: 'Save',
    et: 'Save',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  Search: {
    en: 'Search',
    ja: 'Search',
    et: 'Search',
    lt: 'Search',
    ch: 'Search',
    ph: 'Search',
  },
  'Select Branch': {
    en: 'Select Branch',
    ja: 'Select Branch',
    et: 'Select Branch',
    lt: 'Select Branch',
    ch: 'Select Branch',
    ph: 'Select Branch',
  },
  'Select Users': {
    en: 'Select Users',
    ja: 'Select Users',
    et: 'Select Users',
    lt: 'Select Users',
    ch: 'Select Users',
    ph: 'Select Users',
  },
  Sender: {
    en: 'Sender',
    ja: 'Sender',
    et: 'Sender',
    lt: 'Sender',
    ch: 'Sender',
    ph: 'Sender',
  },
  'Sender Wallet ID': {
    en: 'Sender Wallet ID',
    ja: 'Sender Wallet ID',
    et: 'Sender Wallet ID',
    lt: 'Sender Wallet ID',
    ch: 'Sender Wallet ID',
    ph: 'Sender Wallet ID',
  },
  'Sender Username': {
    en: 'Sender Username',
    ja: 'Sender Username',
    et: 'Sender Username',
    lt: 'Sender Username',
    ch: 'Sender Username',
    ph: 'Sender Username',
  },
  'Start Date': {
    en: 'Start Date',
    ja: 'Start Date',
    et: 'Start Date',
    lt: 'Start Date',
    ch: 'Start Date',
    ph: 'Start Date',
  },
  Status: {
    en: 'Status',
    ja: 'Status',
    et: 'Status',
    lt: 'Status',
    ch: 'Status',
    ph: 'Status',
  },
  'Successfully Changed': {
    en: 'Successfully Changed',
    ja: 'Successfully Changed',
    et: 'Successfully Changed',
    lt: 'Successfully Changed',
    ch: 'Successfully Changed',
    ph: 'Successfully Changed',
  },
  Text: {
    en: 'Text',
    ja: 'Text',
    et: 'Text',
    lt: 'Text',
    ch: 'Text',
    ph: 'Text',
  },
  'This reward has not been claimed yet!': {
    en: 'This reward has not been claimed yet!',
    ja: 'This reward has not been claimed yet!',
    et: 'This reward has not been claimed yet!',
    lt: 'This reward has not been claimed yet!',
    ch: 'This reward has not been claimed yet!',
    ph: 'This reward has not been claimed yet!',
  },
  Transactions: {
    en: 'Transactions',
    ja: 'Transactions',
    et: 'Transactions',
    lt: 'Transactions',
    ch: 'Transactions',
    ph: 'Transactions',
  },
  'Transaction Amount': {
    en: 'Transaction Amount',
    ja: 'Transaction Amount',
    et: 'Transaction Amount',
    lt: 'Transaction Amount',
    ch: 'Transaction Amount',
    ph: 'Transaction Amount',
  },
  'Transaction Date': {
    en: 'Transaction Date',
    ja: 'Transaction Date',
    et: 'Transaction Date',
    lt: 'Transaction Date',
    ch: 'Transaction Date',
    ph: 'Transaction Date',
  },
  'Transaction ID': {
    en: 'Transaction ID',
    ja: 'Transaction ID',
    et: 'Transaction ID',
    lt: 'Transaction ID',
    ch: 'Transaction ID',
    ph: 'Transaction ID',
  },
  'Transaction Type': {
    en: 'Transaction Type',
    ja: 'Transaction Type',
    et: 'Transaction Type',
    lt: 'Transaction Type',
    ch: 'Transaction Type',
    ph: 'Transaction Type',
  },
  Type: {
    en: 'Type',
    ja: 'Type',
    et: 'Type',
    lt: 'Type',
    ch: 'Type',
    ph: 'Type',
  },
  'Updated At': {
    en: 'Updated At',
    ja: 'Updated At',
    et: 'Updated At',
    lt: 'Updated At',
    ch: 'Updated At',
    ph: 'Updated At',
  },
  'Updated Date': {
    en: 'Updated Date',
    ja: 'Updated Date',
    et: 'Updated Date',
    lt: 'Updated Date',
    ch: 'Updated Date',
    ph: 'Updated Date',
  },
  User: {
    en: 'User',
    ja: 'User',
    et: 'User',
    lt: 'User',
    ch: 'User',
    ph: 'User',
  },
  Username: {
    en: 'Username',
    ja: 'Username',
    et: 'Username',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  'View Reward': {
    en: 'View Reward',
    ja: 'View Reward',
    et: 'View Reward',
    lt: 'View Reward',
    ch: 'View Reward',
    ph: 'View Reward',
  },
  'View Transaction': {
    en: 'View Transaction',
    ja: 'View Transaction',
    et: 'View Transaction',
    lt: 'View Transaction',
    ch: 'View Transaction',
    ph: 'View Transaction',
  },
  'View Wallet': {
    en: 'View Wallet',
    ja: 'View Wallet',
    et: 'View Wallet',
    lt: 'View Wallet',
    ch: 'View Wallet',
    ph: 'View Wallet',
  },
  Vivicoin: {
    en: 'Vivicoin',
    ja: 'Vivicoin',
    et: 'Vivicoin',
    lt: 'Vivicoin',
    ch: 'Vivicoin',
    ph: 'Vivicoin',
  },
  Wallet: {
    en: 'Wallet',
    ja: 'Wallet',
    et: 'Wallet',
    lt: 'Wallet',
    ch: 'Wallet',
    ph: 'Wallet',
  },
  'Wallet ID': {
    en: 'Wallet ID',
    ja: 'Wallet ID',
    et: 'Wallet ID',
    lt: 'Wallet ID',
    ch: 'Wallet ID',
    ph: 'Wallet ID',
  },
  'Wallet Owner': {
    en: 'Wallet Owner',
    ja: 'Wallet Owner',
    et: 'Wallet Owner',
    lt: 'Wallet Owner',
    ch: 'Wallet Owner',
    ph: 'Wallet Owner',
  },
};
