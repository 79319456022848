export default {
  login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Login',
    ch: 'Login',
    ph: 'Login',
  },
  createAccount: {
    en: 'Create Account',
    ja: 'アカウント登録',
    et: 'Loo konto',
    lt: 'Create Account',
    ch: 'Create Account',
    ph: 'Create Account',
  },
  usernameOrEmail: {
    en: 'Username or Email',
    ja: 'ユーザー名またはメール',
    et: 'Kasutajanimi või e-mail',
    lt: 'Username or Email',
    ch: 'Username or Email',
    ph: 'Username or Email',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mail',
    lt: 'Email',
    ch: 'Email',
    ph: 'Email',
  },
  signUpDisclaimer: {
    en: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ja: '[アカウント登録]をクリックすると、<0>利用規約</0>と<1>プライバシーポリシー</1>に同意したことになります。',
    et: 'Vajutades "Loo konto" nõustud sa <0>Kasutamise tingimuste</0> ja <1>Privaatsuspoliitikaga</1>.',
    lt: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ch: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ph: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Parool',
    lt: 'Password',
    ch: 'Password',
    ph: 'Password',
  },
  troubleLoggingIn: {
    en: 'Forgot your password?',
    ja: 'パスワードをお忘れですか？',
    et: 'Unustasid salasõna?',
    lt: 'Pamiršai slaptažodį?',
    ph: 'Forgot your password?',
    ch: '忘记密码了吗？',
  },
  or: {
    en: 'or',
    ja: 'または',
    et: 'või',
    lt: 'or',
    ch: 'or',
    ph: 'or',
  },
  signupSuccess: {
    nice: {
      en: 'Success! Registered your account. Please check your email. ',
      ja: '成功! アカウント登録できました. メールを確認してください。 ',
      et: 'Konto registreerimine õnnestus! Vaata oma e-maili.',
      lt: 'Success! Registered your account. Please check your email. ',
      ch: 'Success! Registered your account. Please check your email. ',
      ph: 'Success! Registered your account. Please check your email. ',
    },
    checkJunkMail: {
      en: "Check your junk/spam mail if it's not in your inbox. ",
      ja: '受信トレイに入ってなかった場合は迷惑メールでも確認してください。',
      et: 'Vaata oma spämmi kausta, kui sa e-kirja ei leia.',
      lt: "Check your junk/spam mail if it's not in your inbox. ",
      ch: "Check your junk/spam mail if it's not in your inbox. ",
      ph: "Check your junk/spam mail if it's not in your inbox. ",
    },
    didntReceiveEmail: {
      en: "If you didn't receive an email. <0>Resend Verification Email</0>",
      ja: 'メールもらってない場合。<0>確認メールを再送する</0>',
      et: 'Juhul kui e-mail ei saabunud võid <0>saata kontrollkirja uuesti</0>',
      lt: "If you didn't receive an email. <0>Resend Verification Email</0>",
      ch: "If you didn't receive an email. <0>Resend Verification Email</0>",
      ph: "If you didn't receive an email. <0>Resend Verification Email</0>",
    },
    alreadyHaveAccount: {
      en: 'If you think you may have an account with us already. <0>Reset Password</0>',
      ja: 'すでにアカウントあるかもと言う方はこちら。<0>パスワードを再設定する</0>',
      et: 'Kui sul konto juba olemas on siis proovi <0>vahetada parooli</0>',
      lt: 'If you think you may have an account with us already. <0>Reset Password</0>',
      ch: 'If you think you may have an account with us already. <0>Reset Password</0>',
      ph: 'If you think you may have an account with us already. <0>Reset Password</0>',
    },
  },
  sns: {
    loading: {
      en: 'Loading {{sns}} resources',
      ja: '{{sns}}データ読み込み中',
      et: 'Laen {{sns}} resursse',
      lt: 'Loading {{sns}} resources',
      ch: 'Loading {{sns}} resources',
      ph: 'Loading {{sns}} resources',
    },
    failedToLoad: {
      en: 'Failed to Load {{sns}} resources',
      ja: '{{sns}}データ読み込み失敗した',
      et: 'Ei õnnestunud laadida {{sns}} resursse',
      lt: 'Failed to Load {{sns}} resources',
      ch: 'Failed to Load {{sns}} resources',
      ph: 'Failed to Load {{sns}} resources',
    },
    connect: {
      en: 'Connect with {{sns}}',
      ja: '{{sns}}で続く',
      et: 'Ühendu kasutades {{sns}}',
      lt: 'Connect with {{sns}}',
      ch: 'Connect with {{sns}}',
      ph: 'Connect with {{sns}}',
    },
    link: {
      en: 'Link account with {{sns}}',
      ja: '{{sns}}と繋ぐ',
      et: 'Liida konto {{sns}}-ga',
      lt: 'Link account with {{sns}}',
      ch: 'Link account with {{sns}}',
      ph: 'Link account with {{sns}}',
    },
    unlink: {
      en: 'Unlink Account',
      ja: 'アカウントリンク解除する',
      et: 'Ühenda konto lahti',
      lt: 'Unlink Account',
      ch: 'Unlink Account',
      ph: 'Unlink Account',
    },
    setPass: {
      en: 'Please set a password before unlinking your SNS account. ',
      ja: 'パスワードを設定してからSNSアカウントリンク解除できます。',
      et: 'Palun määra parool, enne kui ühendad konto SNS-ist lahti',
      lt: 'Please set a password before unlinking your SNS account. ',
      ch: 'Please set a password before unlinking your SNS account. ',
      ph: 'Please set a password before unlinking your SNS account. ',
    },
    unlinkSuccess: {
      en: 'Success, unlinked your account! ',
      ja: '成功、SNSアカウントリンク解除しました。',
      et: 'Valmis! Konto lahti ühendamine õnnestus',
      lt: 'Success, unlinked your account! ',
      ch: 'Success, unlinked your account! ',
      ph: 'Success, unlinked your account! ',
    },
  },
  logInSuccess: {
    en: 'Success! Logging you in 😎',
    ja: '成功！ ログインしています 😎',
    et: 'Valmis! Login sind sisse 😎',
    lt: 'Success! Logging you in 😎',
    ch: 'Success! Logging you in 😎',
    ph: 'Success! Logging you in 😎',
  },
  pleaseVerifyYourEmail: {
    en: 'Please verify your email.',
    ja: 'メールアドレスを確認してください。',
    et: 'Kontrolli oma e-maili',
    lt: 'Please verify your email.',
    ch: 'Please verify your email.',
    ph: 'Please verify your email.',
  },
  pleaseVerifyYourEmailLinkText: {
    en: 'To request a new email verification code, click here',
    ja: '新しいメール確認コードをリクエストするには、ここをクリックしてください',
    et: 'Uue kinnituskoodi saamiseks e-mailiga vajuta siia',
    lt: 'To request a new email verification code, click here',
    ch: 'To request a new email verification code, click here',
    ph: 'To request a new email verification code, click here',
  },
  Member: {
    en: 'Member',
    ja: 'メンバー',
    et: 'Liige',
    lt: 'Narys',
    ph: 'Member',
    ch: '会员',
  },
  Institution: {
    en: 'Institution',
    ja: '機関',
    et: 'Institutsioon',
    lt: 'institucija',
    ph: 'Institution',
    ch: '机构',
  },
  signInAs: {
    en: 'Sign in as admin',
    ja: 'どのようにしてログインしたいですか？',
    et: 'Logi sisse admin',
    lt: 'Prisijunkite kaip admin',
    ph: 'Sign in as admin',
    ch: '你想如何登陆？',
  },
  searchInstitutionInput: {
    en: 'Search school/institution',
    ja: '学校/教育機関を探す',
    et: 'Otsige kooli/asutust',
    lt: 'Ieškoti mokyklos/įstaigos',
    ph: 'Search school/institution',
    ch: '搜寻学校/机构',
  },
  searchInstitution: {
    en: 'Search for your school / institution here and tap the link to access the login page.',
    ja: 'ここで学校/教育機関を検索し、リンクをタップしてログイン ページにアクセスします。',
    et: 'Otsige siit oma kooli/asutust ja puudutage sisselogimislehele pääsemiseks linki.',
    lt: 'Čia ieškokite savo mokyklos / institucijos ir bakstelėkite nuorodą, kad pasiektumėte prisijungimo puslapį.',
    ph: 'Search for your school / institution here and tap the link to access the login page.',
    ch: '在此处搜索您的学校/机构，然后点击链接访问登录页面。',
  },
  vivitaLogin: {
    en: 'VIVITA Login',
    ja: 'VIVITA ログイン',
    et: 'VIVITA Logi sisse',
    lt: 'VIVITA Prisijunk',
    ph: 'VIVITA Login',
    ch: 'VIVITA 登录',
  },
  'Admin Portal Login': {
    en: 'Admin Portal Login',
    ja: 'Admin Portal Login',
    et: 'Admin Portal Login',
    lt: 'Admin Portal Login',
    ph: 'Admin Portal Login',
    ch: 'Admin Portal Login',
  },
};
