function arduinoCode(vaultCode, serviceUUID, characteristicUUID) {
  const code = `// Install ArduinoBLE library under Library Manager to compile this code
    #include <ArduinoBLE.h>
    #include <Servo.h>
    
    // Replace the three variable with your device setup. The UUID properties can be generated by UUID v4 generator
    #define PERIPHERAL_NAME '${vaultCode}'
    #define SERVICE_UUID '${serviceUUID}'
    #define CHARACTERISTIC_INPUT_UUID '${characteristicUUID}'
    #define UNLOCK_CODE '2'
    
    BLEService ledService(SERVICE_UUID); // create service
    
    // create switch characteristic and allow remote device to read and write
    BLEByteCharacteristic switchCharacteristic(CHARACTERISTIC_INPUT_UUID, BLERead | BLEWrite);
    
    // servo for lock mechanism
    Servo servoMain;
    Servo servoLeft;
    Servo servoRight;
    
    const int initialPosMain = 90;    // initial the servo position
    const int unlockedPosMain = 0;    // unlocked the servo position
    
    const int initialPosLeft = 0;    // initial the servo position
    const int unlockedPosLeft = 90;    // unlocked the servo position
    
    const int initialPosRight = 90;    // initial the servo position
    const int unlockedPosRight = 0;    // unlocked the servo position
    
    const int servoPinMain = 9;
    const int servoPinLeft = 10;
    const int servoPinRight = 11;
    
    const int ledPin = LED_BUILTIN; // pin to use for the LED
    
    void setup() {
      Serial.begin(9600);
      
      pinMode(ledPin, OUTPUT); // use the LED pin as an output
    
      // begin initialization
      if (!BLE.begin()) {
        Serial.println("starting Bluetooth® Low Energy module failed!");
    
        while (1);
      }
      BLE.setDeviceName(PERIPHERAL_NAME);
      // set the local name peripheral advertises
      BLE.setLocalName(PERIPHERAL_NAME);
      // set the UUID for the service this peripheral advertises
      BLE.setAdvertisedService(ledService);
    
      // add the characteristic to the service
      ledService.addCharacteristic(switchCharacteristic);
    
      // add service
      BLE.addService(ledService);
    
      // assign event handlers for connected, disconnected to peripheral
      BLE.setEventHandler(BLEConnected, blePeripheralConnectHandler);
      BLE.setEventHandler(BLEDisconnected, blePeripheralDisconnectHandler);
    
      // assign event handlers for characteristic
      switchCharacteristic.setEventHandler(BLEWritten, switchCharacteristicWritten);
      // set an initial value for the characteristic
      switchCharacteristic.setValue(0);
    
      // start advertising
      BLE.advertise();
    
      // initialize servo
      servoMain.attach(servoPinMain);
      servoLeft.attach(servoPinLeft);
      servoRight.attach(servoPinRight);
    
      Serial.println(("Bluetooth® device active, waiting for connections..."));
    
      servoMain.write(initialPosMain);
      servoLeft.write(initialPosLeft);
      servoRight.write(initialPosRight);
    }
    
    void loop() {
      // poll for Bluetooth® Low Energy events
      BLE.poll();
    }
    
    void blePeripheralConnectHandler(BLEDevice central) {
      // central connected event handler
      Serial.print("Connected event, central: ");
      Serial.println(central.address());
    }
    
    void blePeripheralDisconnectHandler(BLEDevice central) {
      // central disconnected event handler
      Serial.print("Disconnected event, central: ");
      Serial.println(central.address());
    }
    
    void switchCharacteristicWritten(BLEDevice central, BLECharacteristic characteristic) {
      char value = switchCharacteristic.value();
      Serial.println("Characteristic event, written: " + value);
      servoMain.write(value == UNLOCK_CODE ? unlockedPosMain : initialPosMain);
    
      delay(500);
      
      servoLeft.write(value == UNLOCK_CODE ? unlockedPosLeft : initialPosLeft);
      servoRight.write(value == UNLOCK_CODE ? unlockedPosRight : initialPosRight);
    
      characteristic.writeValue(value == UNLOCK_CODE ? "ok" : "no");
    
      delay(10000);
    
      servoLeft.write(initialPosLeft);
      servoRight.write(initialPosRight);
    
      delay(500);
      
      servoMain.write(initialPosMain);
    }`;

  return code;
}

export default arduinoCode;
