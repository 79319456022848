export default {
  Approved: {
    en: 'Approved',
    ja: 'Approved',
    et: 'Approved',
    lt: 'Approved',
    ch: 'Approved',
    ph: 'Approved',
  },
  Attended: {
    en: 'Attended',
    ja: 'Attended',
    et: 'Attended',
    lt: 'Attended',
    ch: 'Attended',
    ph: 'Attended',
  },
  'Booking Date': {
    en: 'Booking Date',
    ja: 'Booking Date',
    et: 'Booking Date',
    lt: 'Booking Date',
    ch: 'Booking Date',
    ph: 'Booking Date',
  },
  'Booking Status': {
    en: 'Booking Status',
    ja: 'Booking Status',
    et: 'Booking Status',
    lt: 'Booking Status',
    ch: 'Booking Status',
    ph: 'Booking Status',
  },
  Branch: {
    en: 'Branch',
    ja: 'Branch',
    et: 'Branch',
    lt: 'Branch',
    ch: 'Branch',
    ph: 'Branch',
  },
  Cancelled: {
    en: 'Cancelled',
    ja: 'Cancelled',
    et: 'Cancelled',
    lt: 'Cancelled',
    ch: 'Cancelled',
    ph: 'Cancelled',
  },
  'Choose a member': {
    en: 'Choose a member',
    ja: 'Choose a member',
    et: 'Choose a member',
    lt: 'Choose a member',
    ch: 'Choose a member',
    ph: 'Choose a member',
  },
  'Choose an event slot': {
    en: 'Choose an event slot',
    ja: 'Choose an event slot',
    et: 'Choose an event slot',
    lt: 'Choose an event slot',
    ch: 'Choose an event slot',
    ph: 'Choose an event slot',
  },
  'Choose branch of member': {
    en: 'Choose branch of member',
    ja: 'Choose branch of member',
    et: 'Choose branch of member',
    lt: 'Choose branch of member',
    ch: 'Choose branch of member',
    ph: 'Choose branch of member',
  },
  Email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-maili aadress',
    lt: 'Email',
    ch: 'Email',
    ph: 'Email',
  },
  'End Date': {
    en: 'End Date',
    ja: 'End Date',
    et: 'End Date',
    lt: 'End Date',
    ch: 'End Date',
    ph: 'End Date',
  },
  'Enter event title...': {
    en: 'Enter event title...',
    ja: 'Enter event title...',
    et: 'Enter event title...',
    lt: 'Enter event title...',
    ch: 'Enter event title...',
    ph: 'Enter event title...',
  },
  'Enter family name...': {
    en: 'Enter family name...',
    ja: 'Enter family name...',
    et: 'Enter family name...',
    lt: 'Enter family name...',
    ch: 'Enter family name...',
    ph: 'Enter family name...',
  },
  'Enter given name...': {
    en: 'Enter given name...',
    ja: 'Enter given name...',
    et: 'Enter given name...',
    lt: 'Enter given name...',
    ch: 'Enter given name...',
    ph: 'Enter given name...',
  },
  'Enter email...': {
    en: 'Enter email...',
    ja: 'Enter email...',
    et: 'Enter email...',
    lt: 'Enter email...',
    ch: 'Enter email...',
    ph: 'Enter email...',
  },
  'Enter phone number...': {
    en: 'Enter phone number...',
    ja: 'Enter phone number...',
    et: 'Enter phone number...',
    lt: 'Enter phone number...',
    ch: 'Enter phone number...',
    ph: 'Enter phone number...',
  },
  'Enter username...': {
    en: 'Enter username...',
    ja: 'Enter username...',
    et: 'Enter username...',
    lt: 'Enter username...',
    ch: 'Enter username...',
    ph: 'Enter username...',
  },
  'Event Category': {
    en: 'Event Category',
    ja: 'Event Category',
    et: 'Event Category',
    lt: 'Event Category',
    ch: 'Event Category',
    ph: 'Event Category',
  },
  'Event Date and Time': {
    en: 'Event Date and Time',
    ja: 'Event Date and Time',
    et: 'Event Date and Time',
    lt: 'Event Date and Time',
    ch: 'Event Date and Time',
    ph: 'Event Date and Time',
  },
  Export: {
    en: 'Export',
    ja: 'Export',
    et: 'Export',
    lt: 'Export',
    ch: 'Export',
    ph: 'Export',
  },
  'Free Flow': {
    en: 'Free Flow',
    ja: 'Free Flow',
    et: 'Free Flow',
    lt: 'Free Flow',
    ch: 'Free Flow',
    ph: 'Free Flow',
  },
  'Include any notes about this particular approval/rejection/cancellation here...':
    {
      en: 'Include any notes about this particular approval/rejection/cancellation here...',
      ja: 'Include any notes about this particular approval/rejection/cancellation here...',
      et: 'Include any notes about this particular approval/rejection/cancellation here...',
      lt: 'Include any notes about this particular approval/rejection/cancellation here...',
      ch: 'Include any notes about this particular approval/rejection/cancellation here...',
      ph: 'Include any notes about this particular approval/rejection/cancellation here...',
    },
  'Include any notes about this particular booking creation here...': {
    en: 'Include any notes about this particular booking creation here...',
    ja: 'Include any notes about this particular booking creation here...',
    et: 'Include any notes about this particular booking creation here...',
    lt: 'Include any notes about this particular booking creation here...',
    ch: 'Include any notes about this particular booking creation here...',
    ph: 'Include any notes about this particular booking creation here...',
  },
  'Late Cancellation': {
    en: 'Late Cancellation',
    ja: 'Late Cancellation',
    et: 'Late Cancellation',
    lt: 'Late Cancellation',
    ch: 'Late Cancellation',
    ph: 'Late Cancellation',
  },
  'Less Filters': {
    en: 'Less Filters',
    ja: 'Less Filters',
    et: 'Less Filters',
    lt: 'Less Filters',
    ch: 'Less Filters',
    ph: 'Less Filters',
  },
  'Make a booking for a user': {
    en: 'Make a booking for a user',
    ja: 'Make a booking for a user',
    et: 'Make a booking for a user',
    lt: 'Make a booking for a user',
    ch: 'Make a booking for a user',
    ph: 'Make a booking for a user',
  },
  'Make a booking as public': {
    en: 'Make a booking as public',
    ja: 'Make a booking as public',
    et: 'Make a booking as public',
    lt: 'Make a booking as public',
    ch: 'Make a booking as public',
    ph: 'Make a booking as public',
  },
  'More Filters': {
    en: 'More Filters',
    ja: 'More Filters',
    et: 'More Filters',
    lt: 'More Filters',
    ch: 'More Filters',
    ph: 'More Filters',
  },
  'New User Booking': {
    en: 'New User Booking',
    ja: 'New User Booking',
    et: 'New User Booking',
    lt: 'New User Booking',
    ch: 'New User Booking',
    ph: 'New User Booking',
  },
  'New Public Booking': {
    en: 'New Public Booking',
    ja: 'New Public Booking',
    et: 'New Public Booking',
    lt: 'New Public Booking',
    ch: 'New Public Booking',
    ph: 'New Public Booking',
  },
  No: {
    en: 'No',
    ja: 'いいえ',
    et: 'Ei',
    lt: 'No',
    ch: 'No',
    ph: 'No',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  'Please select event': {
    en: 'Please select event',
    ja: 'Please select event',
    et: 'Please select event',
    lt: 'Please select event',
    ch: 'Please select event',
    ph: 'Please select event',
  },
  'Please select user': {
    en: 'Please select user',
    ja: 'Please select user',
    et: 'Please select user',
    lt: 'Please select user',
    ch: 'Please select user',
    ph: 'Please select user',
  },
  'Quota left for this user for': {
    en: 'Quota left for this user for',
    ja: 'Quota left for this user for',
    et: 'Quota left for this user for',
    lt: 'Quota left for this user for',
    ch: 'Quota left for this user for',
    ph: 'Quota left for this user for',
  },
  Rejected: {
    en: 'Rejected',
    ja: 'Rejected',
    et: 'Rejected',
    lt: 'Rejected',
    ch: 'Rejected',
    ph: 'Rejected',
  },
  'Reminder Sent': {
    en: 'Reminder Sent',
    ja: 'Reminder Sent',
    et: 'Reminder Sent',
    lt: 'Reminder Sent',
    ch: 'Reminder Sent',
    ph: 'Reminder Sent',
  },
  Reset: {
    en: 'Reset',
    ja: 'Reset',
    et: 'Reset',
    lt: 'Reset',
    ch: 'Reset',
    ph: 'Reset',
  },
  Save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  Search: {
    en: 'Search',
    ja: '探す',
    et: 'Otsing',
    lt: 'Search',
    ch: 'Search',
    ph: 'Search',
  },
  'Specific date and time?': {
    en: 'Specific date and time?',
    ja: 'Specific date and time?',
    et: 'Specific date and time?',
    lt: 'Specific date and time?',
    ch: 'Specific date and time?',
    ph: 'Specific date and time?',
  },
  'Start Date': {
    en: 'Start Date',
    ja: 'Start Date',
    et: 'Start Date',
    lt: 'Start Date',
    ch: 'Start Date',
    ph: 'Start Date',
  },
  Status: {
    en: 'Status',
    ja: 'Status',
    et: 'Status',
    lt: 'Status',
    ch: 'Status',
    ph: 'Status',
  },
  'Status Message (if any)': {
    en: 'Status Message (if any)',
    ja: 'Status Message (if any)',
    et: 'Status Message (if any)',
    lt: 'Status Message (if any)',
    ch: 'Status Message (if any)',
    ph: 'Status Message (if any)',
  },
  Submitted: {
    en: 'Submitted',
    ja: 'Submitted',
    et: 'Submitted',
    lt: 'Submitted',
    ch: 'Submitted',
    ph: 'Submitted',
  },
  'This booking is not for a workshop.': {
    en: 'This booking is not for a workshop.',
    ja: 'This booking is not for a workshop.',
    et: 'This booking is not for a workshop.',
    lt: 'This booking is not for a workshop.',
    ch: 'This booking is not for a workshop.',
    ph: 'This booking is not for a workshop.',
  },
  'This user has used up all their quota for the month, ignore monthly quota and still book for them?':
    {
      en: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
      ja: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
      et: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
      lt: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
      ch: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
      ph: 'This user has used up all their quota for the month, ignore monthly quota and still book for them?',
    },
  'Title of event': {
    en: 'Title of event',
    ja: 'Title of event',
    et: 'Title of event',
    lt: 'Title of event',
    ch: 'Title of event',
    ph: 'Title of event',
  },
  'User Booking Search Parameters (optional)': {
    en: 'User Booking Search Parameters (optional)',
    ja: 'User Booking Search Parameters (optional)',
    et: 'User Booking Search Parameters (optional)',
    lt: 'User Booking Search Parameters (optional)',
    ch: 'User Booking Search Parameters (optional)',
    ph: 'User Booking Search Parameters (optional)',
  },
  'Booking Search Parameters (optional)': {
    en: 'Booking Search Parameters (optional)',
    ja: 'Booking Search Parameters (optional)',
    et: 'Booking Search Parameters (optional)',
    lt: 'Booking Search Parameters (optional)',
    ch: 'Booking Search Parameters (optional)',
    ph: 'Booking Search Parameters (optional)',
  },
  'Public Booking Search Parameters (optional)': {
    en: 'Public Booking Search Parameters (optional)',
    ja: 'Public Booking Search Parameters (optional)',
    et: 'Public Booking Search Parameters (optional)',
    lt: 'Public Booking Search Parameters (optional)',
    ch: 'Public Booking Search Parameters (optional)',
    ph: 'Public Booking Search Parameters (optional)',
  },
  'Visitor Feedback': {
    en: 'Visitor Feedback',
    ja: 'Visitor Feedback',
    et: 'Visitor Feedback',
    lt: 'Visitor Feedback',
    ch: 'Visitor Feedback',
    ph: 'Visitor Feedback',
  },
  'User Feedback': {
    en: 'User Feedback',
    ja: 'User Feedback',
    et: 'User Feedback',
    lt: 'User Feedback',
    ch: 'User Feedback',
    ph: 'User Feedback',
  },
  Bookings: {
    en: 'Bookings',
    ja: 'Bookings',
    et: 'Bookings',
    lt: 'Bookings',
    ch: 'Bookings',
    ph: 'Bookings',
  },
  'User Bookings': {
    en: 'User Bookings',
    ja: 'User Bookings',
    et: 'User Bookings',
    lt: 'User Bookings',
    ch: 'User Bookings',
    ph: 'User Bookings',
  },
  'All Bookings': {
    en: 'All Bookings',
    ja: 'All Bookings',
    et: 'All Bookings',
    lt: 'All Bookings',
    ch: 'All Bookings',
    ph: 'All Bookings',
  },
  'Public Bookings': {
    en: 'Public Bookings',
    ja: 'Public Bookings',
    et: 'Public Bookings',
    lt: 'Public Bookings',
    ch: 'Public Bookings',
    ph: 'Public Bookings',
  },
  'User booking added': {
    en: 'User booking added',
    ja: 'User booking added',
    et: 'User booking added',
    lt: 'User booking added',
    ch: 'User booking added',
    ph: 'User booking added',
  },
  'User booking modified': {
    en: 'User booking modified',
    ja: 'User booking modified',
    et: 'User booking modified',
    lt: 'User booking modified',
    ch: 'User booking modified',
    ph: 'User booking modified',
  },
  Username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  'Username of user': {
    en: 'Username of user',
    ja: 'Username of user',
    et: 'Username of user',
    lt: 'Username of user',
    ch: 'Username of user',
    ph: 'Username of user',
  },
  Workshop: {
    en: 'Workshop',
    ja: 'Workshop',
    et: 'Workshop',
    lt: 'Workshop',
    ch: 'Workshop',
    ph: 'Workshop',
  },
  'Workshop Response': {
    en: 'Workshop Response',
    ja: 'Workshop Response',
    et: 'Workshop Response',
    lt: 'Workshop Response',
    ch: 'Workshop Response',
    ph: 'Workshop Response',
  },
  'Workshop Title': {
    en: 'Workshop Title',
    ja: 'Workshop Title',
    et: 'Workshop Title',
    lt: 'Workshop Title',
    ch: 'Workshop Title',
    ph: 'Workshop Title',
  },
  Yes: {
    en: 'Yes',
    ja: 'はい',
    et: 'jah',
    lt: 'Yes',
    ch: 'Yes',
    ph: 'Yes',
  },
  free_flow: {
    en: 'free flow',
    ja: 'free flow',
    et: 'free flow',
    lt: 'free flow',
    ch: 'free flow',
    ph: 'free flow',
  },
  hours: {
    en: 'hours',
    ja: 'hours',
    et: 'hours',
    lt: 'hours',
    ch: 'hours',
    ph: 'hours',
  },
  multiple: {
    en: 'multiple',
    ja: 'multiple',
    et: 'multiple',
    lt: 'multiple',
    ch: 'multiple',
    ph: 'multiple',
  },
  single: {
    en: 'single',
    ja: 'single',
    et: 'single',
    lt: 'single',
    ch: 'single',
    ph: 'single',
  },
  slots: {
    en: 'slots',
    ja: 'slots',
    et: 'slots',
    lt: 'slots',
    ch: 'slots',
    ph: 'slots',
  },
  text: {
    en: 'text',
    ja: 'text',
    et: 'text',
    lt: 'text',
    ch: 'text',
    ph: 'text',
  },
  rating: {
    en: 'rating',
    ja: 'rating',
    et: 'rating',
    lt: 'rating',
    ch: 'rating',
    ph: 'rating',
  },
  'weekday slots and': {
    en: 'weekday slots and',
    ja: 'weekday slots and',
    et: 'weekday slots and',
    lt: 'weekday slots and',
    ch: 'weekday slots and',
    ph: 'weekday slots and',
  },
  'weekend slots.': {
    en: 'weekend slots.',
    ja: 'weekend slots.',
    et: 'weekend slots.',
    lt: 'weekend slots.',
    ch: 'weekend slots.',
    ph: 'weekend slots.',
  },
  workshop: {
    en: 'workshop',
    ja: 'workshop',
    et: 'workshop',
    lt: 'workshop',
    ch: 'workshop',
    ph: 'workshop',
  },
  Rating: {
    en: 'Rating',
    ja: 'Rating',
    et: 'Rating',
    lt: 'Rating',
    ch: 'Rating',
    ph: 'Rating',
  },
  Event: {
    en: 'Event',
    ja: 'Event',
    et: 'Event',
    lt: 'Event',
    ch: 'Event',
    ph: 'Event',
  },
  Date: {
    en: 'Date',
    ja: 'Date',
    et: 'Date',
    lt: 'Date',
    ch: 'Date',
    ph: 'Date',
  },
  'Check In Time': {
    en: 'Check In Time',
    ja: 'Check In Time',
    et: 'Check In Time',
    lt: 'Check In Time',
    ch: 'Check In Time',
    ph: 'Check In Time',
  },
  'Check Out Time': {
    en: 'Check Out Time',
    ja: 'Check Out Time',
    et: 'Check Out Time',
    lt: 'Check Out Time',
    ch: 'Check Out Time',
    ph: 'Check Out Time',
  },
  Name: {
    en: 'Name',
    ja: 'Name',
    et: 'Name',
    lt: 'Name',
    ch: 'Name',
    ph: 'Name',
  },
  Feedback: {
    en: 'Feedback',
    ja: 'Feedback',
    et: 'Feedback',
    lt: 'Feedback',
    ch: 'Feedback',
    ph: 'Feedback',
  },
  'Visitor Name': {
    en: 'Visitor Name',
    ja: 'Visitor Name',
    et: 'Visitor Name',
    lt: 'Visitor Name',
    ch: 'Visitor Name',
    ph: 'Visitor Name',
  },
  'No feedback received for the event': {
    en: 'No feedback received for the event',
    ja: 'No feedback received for the event',
    et: 'No feedback received for the event',
    lt: 'No feedback received for the event',
    ch: 'No feedback received for the event',
    ph: 'No feedback received for the event',
  },
  'Visitor Feedback Search Parameters (optional)': {
    en: 'Visitor Feedback Search Parameters (optional)',
    ja: 'Visitor Feedback Search Parameters (optional)',
    et: 'Visitor Feedback Search Parameters (optional)',
    lt: 'Visitor Feedback Search Parameters (optional)',
    ch: 'Visitor Feedback Search Parameters (optional)',
    ph: 'Visitor Feedback Search Parameters (optional)',
  },
  'User Feedback Search Parameters (optional)': {
    en: 'User Feedback Search Parameters (optional)',
    ja: 'User Feedback Search Parameters (optional)',
    et: 'User Feedback Search Parameters (optional)',
    lt: 'User Feedback Search Parameters (optional)',
    ch: 'User Feedback Search Parameters (optional)',
    ph: 'User Feedback Search Parameters (optional)',
  },
  'Workshop Feedback': {
    en: 'Workshop Feedback',
    ja: 'Workshop Feedback',
    et: 'Workshop Feedback',
    lt: 'Workshop Feedback',
    ch: 'Workshop Feedback',
    ph: 'Workshop Feedback',
  },
  'No visitor feedback...': {
    en: 'No visitor feedback...',
    ja: 'No visitor feedback...',
    et: 'No visitor feedback...',
    lt: 'No visitor feedback...',
    ch: 'No visitor feedback...',
    ph: 'No visitor feedback...',
  },
  'No check in record for this booking.': {
    en: 'No check in record for this booking.',
    ja: 'No check in record for this booking.',
    et: 'No check in record for this booking.',
    lt: 'No check in record for this booking.',
    ch: 'No check in record for this booking.',
    ph: 'No check in record for this booking.',
  },
  'Rating:': {
    en: 'Rating:',
    ja: 'Rating:',
    et: 'Rating:',
    lt: 'Rating:',
    ch: 'Rating:',
    ph: 'Rating:',
  },
  'No ratings given.': {
    en: 'No ratings given.',
    ja: 'No ratings given.',
    et: 'No ratings given.',
    lt: 'No ratings given.',
    ch: 'No ratings given.',
    ph: 'No ratings given.',
  },
  'Feedback:': {
    en: 'Feedback:',
    ja: 'Feedback:',
    et: 'Feedback:',
    lt: 'Feedback:',
    ch: 'Feedback:',
    ph: 'Feedback:',
  },
  'No feedback given.': {
    en: 'No feedback given.',
    ja: 'No feedback given.',
    et: 'No feedback given.',
    lt: 'No feedback given.',
    ch: 'No feedback given.',
    ph: 'No feedback given.',
  },
  'Admin Notes': {
    en: 'Admin Notes',
    ja: 'Admin Notes',
    et: 'Admin Notes',
    lt: 'Admin Notes',
    ch: 'Admin Notes',
    ph: 'Admin Notes',
  },
  'Given name': {
    en: 'Given name',
    ja: 'Given name',
    et: 'Given name',
    lt: 'Given name',
    ch: 'Given name',
    ph: 'Given name',
  },
  'Family name': {
    en: 'Family name',
    ja: 'Family name',
    et: 'Family name',
    lt: 'Family name',
    ch: 'Family name',
    ph: 'Family name',
  },
  'Phone number': {
    en: 'Phone number',
    ja: 'Phone number',
    et: 'Phone number',
    lt: 'Phone number',
    ch: 'Phone number',
    ph: 'Phone number',
  },
  Phone: {
    en: 'Phone',
    ja: 'Phone',
    et: 'Phone',
    lt: 'Phone',
    ch: 'Phone',
    ph: 'Phone',
  },
  'Guardian Name': {
    en: 'Guardian Name',
    et: 'Guardian Name',
    ja: 'Guardian Name',
    lt: 'Guardian Name',
    ch: 'Guardian Name',
    ph: 'Guardian Name',
  },
};
