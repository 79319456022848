export default {
  Account: {
    en: 'Account',
    ja: 'Account',
    et: 'Account',
    lt: 'Account',
    ch: 'Account',
    ph: 'Account',
  },
  'Account Status': {
    en: 'Account Status',
    ja: 'Account Status',
    et: 'Account Status',
    lt: 'Account Status',
    ch: 'Account Status',
    ph: 'Account Status',
  },
  ACTIVE: {
    en: 'ACTIVE',
    ja: 'ACTIVE',
    et: 'ACTIVE',
    lt: 'ACTIVE',
    ch: 'ACTIVE',
    ph: 'ACTIVE',
  },
  Address: {
    en: 'Address',
    ja: 'Address',
    et: 'Address',
    lt: 'Address',
    ch: 'Address',
    ph: 'Address',
  },
  Add: {
    en: 'Add',
    ja: 'Add',
    et: 'Add',
    lt: 'Add',
    ch: 'Add',
    ph: 'Add',
  },
  'Add all badges': {
    en: 'Add all badges',
    ja: 'Add all badges',
    et: 'Add all badges',
    lt: 'Add all badges',
    ch: 'Add all badges',
    ph: 'Add all badges',
  },
  'Add all challenges': {
    en: 'Add all challenges',
    ja: 'Add all challenges',
    et: 'Add all challenges',
    lt: 'Add all challenges',
    ch: 'Add all challenges',
    ph: 'Add all challenges',
  },
  'Add starter badge': {
    en: 'Add starter badge',
    ja: 'Add starter badge',
    et: 'Add starter badge',
    lt: 'Add starter badge',
    ch: 'Add starter badge',
    ph: 'Add starter badge',
  },
  'Add starter challenge': {
    en: 'Add starter challenge',
    ja: 'Add starter challenge',
    et: 'Add starter challenge',
    lt: 'Add starter challenge',
    ch: 'Add starter challenge',
    ph: 'Add starter challenge',
  },
  'Add User': {
    en: 'Add User',
    ja: 'Add User',
    et: 'Add User',
    lt: 'Add User',
    ch: 'Add User',
    ph: 'Add User',
  },
  Admin: {
    en: 'Admin',
    ja: 'Admin',
    et: 'Admin',
    lt: 'Admin',
    ch: 'Admin',
    ph: 'Admin',
  },
  'Admin Notes': {
    en: 'Admin Notes',
    ja: 'Admin Notes',
    et: 'Admin Notes',
    lt: 'Admin Notes',
    ch: 'Admin Notes',
    ph: 'Admin Notes',
  },
  Ago: {
    en: 'Ago',
    ja: 'Ago',
    et: 'Ago',
    lt: 'Ago',
    ch: 'Ago',
    ph: 'Ago',
  },
  All: {
    en: 'All',
    ja: '全て',
    et: 'Kõik',
    lt: 'All',
    ch: 'All',
    ph: 'All',
  },
  "all crews' access granted by this staff role!": {
    en: "all crews' access granted by this staff role!",
    ja: "all crews' access granted by this staff role!",
    et: "all crews' access granted by this staff role!",
    lt: "all crews' access granted by this staff role!",
    ch: "all crews' access granted by this staff role!",
    ph: "all crews' access granted by this staff role!",
  },
  'All Members': {
    en: 'All Members',
    ja: 'All Members',
    et: 'All Members',
    lt: 'All Members',
    ch: 'All Members',
    ph: 'All Members',
  },
  'All Staff': {
    en: 'All Staff',
    ja: 'All Staff',
    et: 'All Staff',
    lt: 'All Staff',
    ch: 'All Staff',
    ph: 'All Staff',
  },
  'Allow users to book events through VIVIBOOM': {
    en: 'Allow users to book events through VIVIBOOM',
    ja: 'Allow users to book events through VIVIBOOM',
    et: 'Allow users to book events through VIVIBOOM',
    ch: 'Allow users to book events through VIVIBOOM',
    ph: 'Allow users to book events through VIVIBOOM',
  },
  'Allow users to earn rewards': {
    en: 'Allow users to earn rewards',
    ja: 'Allow users to earn rewards',
    et: 'Allow users to earn rewards',
    lt: 'Allow users to earn rewards',
    ch: 'Allow users to earn rewards',
    ph: 'Allow users to earn rewards',
  },
  'Allow visitors to visit branch': {
    en: 'Allow visitors to visit branch',
    ja: 'Allow visitors to visit branch',
    et: 'Allow visitors to visit branch',
    lt: 'Allow visitors to visit branch',
    ch: 'Allow visitors to visit branch',
    ph: 'Allow visitors to visit branch',
  },
  Amount: {
    en: 'Amount',
    ja: 'Amount',
    et: 'Amount',
    lt: 'Amount',
    ch: 'Amount',
    ph: 'Amount',
  },
  'Are you sure you want to ban this user?': {
    en: 'Are you sure you want to ban this user?',
    ja: 'Are you sure you want to ban this user?',
    et: 'Are you sure you want to ban this user?',
    lt: 'Are you sure you want to ban this user?',
    ch: 'Are you sure you want to ban this user?',
    ph: 'Are you sure you want to ban this user?',
  },
  'Are you sure you want to change the Google client ID and client secret pair?': {
    en: 'Are you sure you want to change the Google client ID and client secret pair?',
    ja: 'Are you sure you want to change the Google client ID and client secret pair?',
    et: 'Are you sure you want to change the Google client ID and client secret pair?',
    lt: 'Are you sure you want to change the Google client ID and client secret pair?',
    ch: 'Are you sure you want to change the Google client ID and client secret pair?',
    ph: 'Are you sure you want to change the Google client ID and client secret pair?',
  },
  'Are you sure you want to delete this role?': {
    en: 'Are you sure you want to delete this role?',
    ja: 'Are you sure you want to delete this role?',
    et: 'Are you sure you want to delete this role?',
    lt: 'Are you sure you want to delete this role?',
    ch: 'Are you sure you want to delete this role?',
    ph: 'Are you sure you want to delete this role?',
  },
  'Are you sure you want to delete this user?': {
    en: 'Are you sure you want to delete this user?',
    ja: 'Are you sure you want to delete this user?',
    et: 'Are you sure you want to delete this user?',
    lt: 'Are you sure you want to delete this user?',
    ch: 'Are you sure you want to delete this user?',
    ph: 'Are you sure you want to delete this user?',
  },
  'Are you sure you want to unban this user?': {
    en: 'Are you sure you want to unban this user?',
    ja: 'Are you sure you want to unban this user?',
    et: 'Are you sure you want to unban this user?',
    lt: 'Are you sure you want to unban this user?',
    ch: 'Are you sure you want to unban this user?',
    ph: 'Are you sure you want to unban this user?',
  },
  ASC: {
    en: 'ASC',
    ja: 'ASC',
    et: 'ASC',
    lt: 'ASC',
    ch: 'ASC',
    ph: 'ASC',
  },
  'Assign Users': {
    en: 'Assign Users',
    ja: 'Assign Users',
    et: 'Assign Users',
    lt: 'Assign Users',
    ch: 'Assign Users',
    ph: 'Assign Users',
  },
  'Assign staff role to a user': {
    en: 'Assign staff role to a user',
    ja: 'Assign staff role to a user',
    et: 'Assign staff role to a user',
    lt: 'Assign staff role to a user',
    ch: 'Assign staff role to a user',
    ph: 'Assign staff role to a user',
  },
  Badge: {
    en: 'Badge',
    ja: 'Badge',
    et: 'Badge',
    lt: 'Badge',
    ch: 'Badge',
    ph: 'Badge',
  },
  Branch: {
    en: 'Branch',
    ja: 'Branch',
    et: 'Branch',
    lt: 'Branch',
    ch: 'Branch',
    ph: 'Branch',
  },
  'Badge Image': {
    en: 'Badge Image',
    ja: 'Badge Image',
    et: 'Badge Image',
    lt: 'Badge Image',
    ch: 'Badge Image',
    ph: 'Badge Image',
  },
  'Starter Badge Requirements': {
    en: 'Starter Badge Requirements',
    ja: 'Starter Badge Requirements',
    et: 'Starter Badge Requirements',
    lt: 'Starter Badge Requirements',
    ch: 'Starter Badge Requirements',
    ph: 'Starter Badge Requirements',
  },
  Banned: {
    en: 'Banned',
    ja: 'Banned',
    et: 'Banned',
    lt: 'Banned',
    ch: 'Banned',
    ph: 'Banned',
  },
  'Ban User': {
    en: 'Ban User',
    ja: 'Ban User',
    et: 'Ban User',
    lt: 'Ban User',
    ch: 'Ban User',
    ph: 'Ban User',
  },
  BANNED: {
    en: 'BANNED',
    ja: 'BANNED',
    et: 'BANNED',
    lt: 'BANNED',
    ch: 'BANNED',
    ph: 'BANNED',
  },
  'Branch Details': {
    en: 'Branch Details',
    ja: 'Branch Details',
    et: 'Branch Details',
    lt: 'Branch Details',
    ch: 'Branch Details',
    ph: 'Branch Details',
  },
  'Branch Name': {
    en: 'Branch Name',
    ja: 'Branch Name',
    et: 'Branch Name',
    lt: 'Branch Name',
    ch: 'Branch Name',
    ph: 'Branch Name',
  },
  'Branch Sign-Up Code': {
    en: 'Branch Sign-Up Code',
    ja: 'Branch Sign-Up Code',
    et: 'Branch Sign-Up Code',
    lt: 'Branch Sign-Up Code',
    ch: 'Branch Sign-Up Code',
    ph: 'Branch Sign-Up Code',
  },
  'Branch Staff Roles': {
    en: 'Branch Staff Roles',
    ja: 'Branch Staff Roles',
    et: 'Branch Staff Roles',
    lt: 'Branch Staff Roles',
    ch: 'Branch Staff Roles',
    ph: 'Branch Staff Roles',
  },
  'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria': {
    en: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
    ja: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
    et: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
    lt: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
    ch: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
    ph: 'By updating the criterias below, all existing Explorers will be promoted to Vivinaut status if they meet the criteria',
  },
  Cancel: {
    en: 'Cancel',
    ja: 'Cancel',
    et: 'Cancel',
    lt: 'Cancel',
    ch: 'Cancel',
    ph: 'Cancel',
  },
  'Cannot remove yourself.': {
    en: 'Cannot remove yourself.',
    ja: 'Cannot remove yourself.',
    et: 'Cannot remove yourself.',
    lt: 'Cannot remove yourself.',
    ch: 'Cannot remove yourself.',
    ph: 'Cannot remove yourself.',
  },
  'Starter Challenge Requirement': {
    en: 'Starter Challenge Requirement',
    ja: 'Starter Challenge Requirement',
    et: 'Starter Challenge Requirement',
    lt: 'Starter Challenge Requirement',
    ch: 'Starter Challenge Requirement',
    ph: 'Starter Challenge Requirement',
  },
  'Change Password': {
    en: 'Change Password',
    ja: 'Change Password',
    et: 'Change Password',
    lt: 'Change Password',
    ch: 'Change Password',
    ph: 'Change Password',
  },
  'Choose a user': {
    en: 'Choose a user',
    ja: 'Choose a user',
    et: 'Choose a user',
    lt: 'Choose a user',
    ch: 'Choose a user',
    ph: 'Choose a user',
  },
  'Choose here': {
    en: 'Choose here',
    ja: 'Choose here',
    et: 'Choose here',
    lt: 'Choose here',
    ch: 'Choose here',
    ph: 'Choose here',
  },
  'Confirm Edit': {
    en: 'Confirm Edit',
    ja: 'Confirm Edit',
    et: 'Confirm Edit',
    lt: 'Confirm Edit',
    ch: 'Confirm Edit',
    ph: 'Confirm Edit',
  },
  'Country ISO': {
    en: 'Country ISO',
    ja: 'Country ISO',
    et: 'Country ISO',
    lt: 'Country ISO',
    ch: 'Country ISO',
    ph: 'Country ISO',
  },
  CreateAndDelete: {
    en: 'CreateAndDelete',
    ja: 'CreateAndDelete',
    et: 'CreateAndDelete',
    lt: 'CreateAndDelete',
    ch: 'CreateAndDelete',
    ph: 'CreateAndDelete',
  },
  'Created Date': {
    en: 'Created Date',
    ja: '作成日',
    et: 'Loomise kuupäev',
    lt: 'Created Date',
    ch: 'Created Date',
    ph: 'Created Date',
  },
  'Create staff role before adding users': {
    en: 'Create staff role before adding users',
    ja: 'Create staff role before adding users',
    et: 'Create staff role before adding users',
    lt: 'Create staff role before adding users',
    ch: 'Create staff role before adding users',
    ph: 'Create staff role before adding users',
  },
  'Create User Wallet': {
    en: 'Create User Wallet',
    ja: 'Create User Wallet',
    et: 'Create User Wallet',
    lt: 'Create User Wallet',
    ch: 'Create User Wallet',
    ph: 'Create User Wallet',
  },
  'Create Wallet': {
    en: 'Create Wallet',
    ja: 'Create Wallet',
    et: 'Create Wallet',
    lt: 'Create Wallet',
    ch: 'Create Wallet',
    ph: 'Create Wallet',
  },
  "Creator's Username": {
    en: "Creator's Username",
    ja: "Creator's Username",
    et: "Creator's Username",
    lt: "Creator's Username",
    ch: "Creator's Username",
    ph: "Creator's Username",
  },
  Crew: {
    en: 'Crew',
    ja: 'Crew',
    et: 'Crew',
    lt: 'Crew',
    ch: 'Crew',
    ph: 'Crew',
  },
  'Danger Zone': {
    en: 'Danger Zone',
    ja: 'Danger Zone',
    et: 'Danger Zone',
    lt: 'Danger Zone',
    ch: 'Danger Zone',
    ph: 'Danger Zone',
  },
  'Date of Birth': {
    en: 'Date of Birth',
    ja: 'Date of Birth',
    et: 'Date of Birth',
    lt: 'Date of Birth',
    ch: 'Date of Birth',
    ph: 'Date of Birth',
  },
  Day: {
    en: 'Day',
    ja: 'Day',
    et: 'Day',
    lt: 'Day',
    ch: 'Day',
    ph: 'Day',
  },
  Days: {
    en: 'Days',
    ja: 'Days',
    et: 'Days',
    lt: 'Days',
    ch: 'Days',
    ph: 'Days',
  },
  Delete: {
    en: 'Delete',
    ja: 'Delete',
    et: 'Delete',
    lt: 'Delete',
    ch: 'Delete',
    ph: 'Delete',
  },
  'Delete Staff Role': {
    en: 'Delete Staff Role',
    ja: 'Delete Staff Role',
    et: 'Delete Staff Role',
    lt: 'Delete Staff Role',
    ch: 'Delete Staff Role',
    ph: 'Delete Staff Role',
  },
  'Delete success': {
    en: 'Delete success',
    ja: 'Delete success',
    et: 'Delete success',
    lt: 'Delete success',
    ch: 'Delete success',
    ph: 'Delete success',
  },
  'Delete User': {
    en: 'Delete User',
    ja: 'Delete User',
    et: 'Delete User',
    lt: 'Delete User',
    ch: 'Delete User',
    ph: 'Delete User',
  },
  'Deleting this staff role will remove': {
    en: 'Deleting this staff role will remove',
    ja: 'Deleting this staff role will remove',
    et: 'Deleting this staff role will remove',
    lt: 'Deleting this staff role will remove',
    ch: 'Deleting this staff role will remove',
    ph: 'Deleting this staff role will remove',
  },
  DESC: {
    en: 'DESC',
    ja: 'DESC',
    et: 'DESC',
    lt: 'DESC',
    ch: 'DESC',
    ph: 'DESC',
  },
  'Disable Membership Criteria': {
    en: 'Disable Membership Criteria',
    ja: 'Disable Membership Criteria',
    et: 'Disable Membership Criteria',
    lt: 'Disable Membership Criteria',
    ch: 'Disable Membership Criteria',
    ph: 'Disable Membership Criteria',
  },
  'Education Level': {
    en: 'Education Level',
    ja: 'Education Level',
    et: 'Education Level',
    lt: 'Education Level',
    ch: 'Education Level',
    ph: 'Education Level',
  },
  Email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-maili aadress',
    lt: 'Email',
    ch: 'Email',
    ph: 'Email',
  },
  'Email Not Verified': {
    en: 'Email Not Verified',
    ja: 'Email Not Verified',
    et: 'Email Not Verified',
    lt: 'Email Not Verified',
    ch: 'Email Not Verified',
    ph: 'Email Not Verified',
  },
  'Email Verification Reset': {
    en: 'Email Verification Reset',
    ja: 'Email Verification Reset',
    et: 'Email Verification Reset',
    lt: 'Email Verification Reset',
    ch: 'Email Verification Reset',
    ph: 'Email Verification Reset',
  },
  'Email Verification Skipped': {
    en: 'Email Verification Skipped',
    ja: 'Email Verification Skipped',
    et: 'Email Verification Skipped',
    lt: 'Email Verification Skipped',
    ch: 'Email Verification Skipped',
    ph: 'Email Verification Skipped',
  },
  'Email Verified': {
    en: 'Email Verified',
    ja: 'Email Verified',
    et: 'Email Verified',
    lt: 'Email Verified',
    ch: 'Email Verified',
    ph: 'Email Verified',
  },
  emailVerifySkipConfirm: {
    en: 'Are you sure you want to skip verification for this user?',
    ja: 'Are you sure you want to skip verification for this user?',
    et: 'Are you sure you want to skip verification for this user?',
    lt: 'Are you sure you want to skip verification for this user?',
    ch: 'Are you sure you want to skip verification for this user?',
    ph: 'Are you sure you want to skip verification for this user?',
  },
  emailVerifyRerequestConfirm: {
    en: 'Are you sure you want to re-request verification from this user?',
    ja: 'Are you sure you want to re-request verification from this user?',
    et: 'Are you sure you want to re-request verification from this user?',
    lt: 'Are you sure you want to re-request verification from this user?',
    ch: 'Are you sure you want to re-request verification from this user?',
    ph: 'Are you sure you want to re-request verification from this user?',
  },
  'Enable Membership Criteria': {
    en: 'Enable Membership Criteria',
    ja: 'Enable Membership Criteria',
    et: 'Enable Membership Criteria',
    lt: 'Enable Membership Criteria',
    ch: 'Enable Membership Criteria',
    ph: 'Enable Membership Criteria',
  },
  Event: {
    en: 'Event',
    ja: 'Event',
    et: 'Event',
    lt: 'Event',
    ch: 'Event',
    ph: 'Event',
  },
  'Events Requirement': {
    en: 'Events Requirement',
    ja: 'Events Requirement',
    et: 'Events Requirement',
    lt: 'Events Requirement',
    ch: 'Events Requirement',
    ph: 'Events Requirement',
  },
  explorer: {
    en: 'Explorer',
    ja: 'Explorer',
    et: 'Explorer',
    lt: 'Explorer',
    ch: 'Explorer',
    ph: 'Explorer',
  },
  Export: {
    en: 'Export',
    ja: 'Export',
    et: 'Export',
    lt: 'Export',
    ch: 'Export',
    ph: 'Export',
  },
  'Fail to fetch data': {
    en: 'Fail to fetch data',
    ja: 'Fail to fetch data',
    et: 'Fail to fetch data',
    lt: 'Fail to fetch data',
    ch: 'Fail to fetch data',
    ph: 'Fail to fetch data',
  },
  'Family Name': {
    en: 'Family Name',
    ja: 'Family Name',
    et: 'Family Name',
    lt: 'Family Name',
    ch: 'Family Name',
    ph: 'Family Name',
  },
  Father: {
    en: 'Father',
    ja: 'Father',
    et: 'Father',
    lt: 'Father',
    ch: 'Father',
    ph: 'Father',
  },
  Female: {
    en: 'Female',
    ja: 'Female',
    et: 'Female',
    lt: 'Female',
    ch: 'Female',
    ph: 'Female',
  },
  'File is too large. Max File size: 1MB': {
    en: 'File is too large. Max File size: 1MB',
    ja: 'File is too large. Max File size: 1MB',
    et: 'File is too large. Max File size: 1MB',
    lt: 'File is too large. Max File size: 1MB',
    ch: 'File is too large. Max File size: 1MB',
    ph: 'File is too large. Max File size: 1MB',
  },
  Gender: {
    en: 'Gender',
    ja: 'Gender',
    et: 'Gender',
    lt: 'Gender',
    ch: 'Gender',
    ph: 'Gender',
  },
  'Given Name': {
    en: 'Given Name',
    ja: 'Given Name',
    et: 'Given Name',
    lt: 'Given Name',
    ch: 'Given Name',
    ph: 'Given Name',
  },
  'Google Admin': {
    en: 'Google Admin',
    ja: 'Google Admin',
    et: 'Google Admin',
    lt: 'Google Admin',
    ch: 'Google Admin',
    ph: 'Google Admin',
  },
  'Google Calendar': {
    en: 'Google Calendar',
    ja: 'Google Calendar',
    et: 'Google Calendar',
    lt: 'Google Calendar',
    ch: 'Google Calendar',
    ph: 'Google Calendar',
  },
  'Google Calendar Client ID': {
    en: 'Google Calendar Client ID',
    ja: 'Google Calendar Client ID',
    et: 'Google Calendar Client ID',
    lt: 'Google Calendar Client ID',
    ch: 'Google Calendar Client ID',
    ph: 'Google Calendar Client ID',
  },
  'Google Admin Client ID': {
    en: 'Google Admin Client ID',
    et: 'Google Admin Client ID',
    ja: 'Google Admin Client ID',
    lt: 'Google Admin Client ID',
  },
  'Google Admin Settings': {
    en: 'Google Admin Settings',
    ja: 'Google Admin Settings',
    et: 'Google Admin Settings',
    lt: 'Google Admin Settings',
    ch: 'Google Admin Settings',
    ph: 'Google Admin Settings',
  },
  'Google Calendar Settings': {
    en: 'Google Calendar Settings',
    ja: 'Google Calendar Settings',
    et: 'Google Calendar Settings',
    lt: 'Google Calendar Settings',
    ch: 'Google Calendar Settings',
    ph: 'Google Calendar Settings',
  },
  'Google Client ID': {
    en: 'Google Client ID',
    et: 'Google Client ID',
    ja: 'Google Client ID',
    lt: 'Google Client ID',
  },
  'Google Client Secret': {
    en: 'Google Client Secret',
    ja: 'Google Client Secret',
    et: 'Google Client Secret',
    lt: 'Google Client Secret',
    ch: 'Google Client Secret',
    ph: 'Google Client Secret',
  },
  'Google Client ID (Admin)': {
    en: 'Google Client ID (Admin)',
    ja: 'Google Client ID (Admin)',
    et: 'Google Client ID (Admin)',
    lt: 'Google Client ID (Admin)',
    ch: 'Google Client ID (Admin)',
    ph: 'Google Client ID (Admin)',
  },
  'Google Drive': {
    en: 'Google Drive',
    ja: 'Google Drive',
    et: 'Google Drive',
    lt: 'Google Drive',
    ch: 'Google Drive',
    ph: 'Google Drive',
  },
  "Guardian's Email": {
    en: "Guardian's Email",
    ja: "Guardian's Email",
    et: "Guardian's Email",
    lt: "Guardian's Email",
    ch: "Guardian's Email",
    ph: "Guardian's Email",
  },
  'Guardian Info': {
    en: 'Guardian Info',
    ja: 'Guardian Info',
    et: 'Guardian Info',
    lt: 'Guardian Info',
    ch: 'Guardian Info',
    ph: 'Guardian Info',
  },
  "Guardian's Name": {
    en: "Guardian's Name",
    ja: "Guardian's Name",
    et: "Guardian's Name",
    lt: "Guardian's Name",
    ch: "Guardian's Name",
    ph: "Guardian's Name",
  },
  "Guardian's Phone Number": {
    en: "Guardian's Phone Number",
    ja: "Guardian's Phone Number",
    et: "Guardian's Phone Number",
    lt: "Guardian's Phone Number",
    ch: "Guardian's Phone Number",
    ph: "Guardian's Phone Number",
  },
  "Guardian's Relationship": {
    en: "Guardian's Relationship",
    ja: "Guardian's Relationship",
    et: "Guardian's Relationship",
    lt: "Guardian's Relationship",
    ch: "Guardian's Relationship",
    ph: "Guardian's Relationship",
  },
  'how-to guide': {
    en: 'how-to guide',
    ja: 'how-to guide',
    et: 'how-to guide',
    lt: 'how-to guide',
    ch: 'how-to guide',
    ph: 'how-to guide',
  },
  Hour: {
    en: 'Hour',
    ja: 'Hour',
    et: 'Hour',
    lt: 'Hour',
    ch: 'Hour',
    ph: 'Hour',
  },
  Hours: {
    en: 'Hours',
    ja: 'Hours',
    et: 'Hours',
    lt: 'Hours',
    ch: 'Hours',
    ph: 'Hours',
  },
  'IANA Timezone': {
    en: 'IANA Timezone',
    ja: 'IANA Timezone',
    et: 'IANA Timezone',
    lt: 'IANA Timezone',
    ch: 'IANA Timezone',
    ph: 'IANA Timezone',
  },
  INACTIVE: {
    en: 'INACTIVE',
    ja: 'INACTIVE',
    et: 'INACTIVE',
    lt: 'INACTIVE',
    ch: 'INACTIVE',
    ph: 'INACTIVE',
  },
  'Institution Details': {
    en: 'Institution Details',
    ja: 'Institution Details',
    et: 'Institution Details',
    lt: 'Institution Details',
    ch: 'Institution Details',
    ph: 'Institution Details',
  },
  'Institution Name': {
    en: 'Institution Name',
    ja: 'Institution Name',
    et: 'Institution Name',
    lt: 'Institution Name',
    ch: 'Institution Name',
    ph: 'Institution Name',
  },
  'Institution Saved!': {
    en: 'Institution Saved!',
    ja: 'Institution Saved!',
    et: 'Institution Saved!',
    lt: 'Institution Saved!',
    ch: 'Institution Saved!',
    ph: 'Institution Saved!',
  },
  'Institution Settings': {
    en: 'Institution Settings',
    ja: 'Institution Settings',
    et: 'Institution Settings',
    lt: 'Institution Settings',
    ch: 'Institution Settings',
    ph: 'Institution Settings',
  },
  'Insufficient permission level': {
    en: 'Insufficient permission level',
    ja: 'Insufficient permission level',
    et: 'Insufficient permission level',
    lt: 'Insufficient permission level',
    ch: 'Insufficient permission level',
    ph: 'Insufficient permission level',
  },
  Intern: {
    en: 'Intern',
    ja: 'Intern',
    et: 'Intern',
    lt: 'Intern',
    ch: 'Intern',
    ph: 'Intern',
  },
  'Last Active': {
    en: 'Last Active',
    ja: 'Last Active',
    et: 'Last Active',
    lt: 'Last Active',
    ch: 'Last Active',
    ph: 'Last Active',
  },
  'Legal guardian': {
    en: 'Legal guardian',
    ja: 'Legal guardian',
    et: 'Legal guardian',
    lt: 'Legal guardian',
    ch: 'Legal guardian',
    ph: 'Legal guardian',
  },
  'Loading...': {
    en: 'Loading...',
    ja: 'Loading...',
    et: 'Loading...',
    lt: 'Loading...',
    ch: 'Loading...',
    ph: 'Loading...',
  },
  Male: {
    en: 'Male',
    ja: 'Male',
    et: 'Male',
    lt: 'Male',
    ch: 'Male',
    ph: 'Male',
  },
  'Membership Criteria': {
    en: 'Membership Criteria',
    ja: 'Membership Criteria',
    et: 'Membership Criteria',
    lt: 'Membership Criteria',
    ch: 'Membership Criteria',
    ph: 'Membership Criteria',
  },
  'Membership Criteria Description': {
    en: 'Membership Criteria Description',
    ja: 'Membership Criteria Description',
    et: 'Membership Criteria Description',
    lt: 'Membership Criteria Description',
    ch: 'Membership Criteria Description',
    ph: 'Membership Criteria Description',
  },
  'Membership Criteria Disabled': {
    en: 'Membership Criteria Disabled',
    ja: 'Membership Criteria Disabled',
    et: 'Membership Criteria Disabled',
    lt: 'Membership Criteria Disabled',
    ch: 'Membership Criteria Disabled',
    ph: 'Membership Criteria Disabled',
  },
  'Membership Criteria Edited Successfully': {
    en: 'Membership Criteria Edited Successfully',
    ja: 'Membership Criteria Edited Successfully',
    et: 'Membership Criteria Edited Successfully',
    lt: 'Membership Criteria Edited Successfully',
    ch: 'Membership Criteria Edited Successfully',
    ph: 'Membership Criteria Edited Successfully',
  },
  'Membership Criteria have been disabled for this branch': {
    en: 'Membership Criteria have been disabled for this branch',
    ja: 'Membership Criteria have been disabled for this branch',
    et: 'Membership Criteria have been disabled for this branch',
    lt: 'Membership Criteria have been disabled for this branch',
    ch: 'Membership Criteria have been disabled for this branch',
    ph: 'Membership Criteria have been disabled for this branch',
  },
  'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!': {
    en: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
    ja: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
    et: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
    lt: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
    ch: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
    ph: 'Minimum number of badge required is currently higher than the number of badges added. Please add more badges!',
  },
  'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!': {
    en: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
    ja: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
    et: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
    lt: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
    ch: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
    ph: 'Minimum number of challenge required is currently higher than the number of challenges added. Please add more challenges!',
  },
  Minute: {
    en: 'Minute',
    ja: 'Minute',
    et: 'Minute',
    lt: 'Minute',
    ch: 'Minute',
    ph: 'Minute',
  },
  Minutes: {
    en: 'Minutes',
    ja: 'Minutes',
    et: 'Minutes',
    lt: 'Minutes',
    ch: 'Minutes',
    ph: 'Minutes',
  },
  Mother: {
    en: 'Mother',
    ja: 'Mother',
    et: 'Mother',
    lt: 'Mother',
    ch: 'Mother',
    ph: 'Mother',
  },
  Name: {
    en: 'Name',
    ja: 'Name',
    et: 'Name',
    lt: 'Name',
    ch: 'Name',
    ph: 'Name',
  },
  'Name of Siblings who are VIVINAUTs': {
    en: 'Name of Siblings who are VIVINAUTs',
    ja: 'Name of Siblings who are VIVINAUTs',
    et: 'Name of Siblings who are VIVINAUTs',
    lt: 'Name of Siblings who are VIVINAUTs',
    ch: 'Name of Siblings who are VIVINAUTs',
    ph: 'Name of Siblings who are VIVINAUTs',
  },
  'New Staff Role': {
    en: 'New Staff Role',
    ja: 'New Staff Role',
    et: 'New Staff Role',
    lt: 'New Staff Role',
    ch: 'New Staff Role',
    ph: 'New Staff Role',
  },
  'New User': {
    en: 'New User',
    ja: 'New User',
    et: 'New User',
    lt: 'New User',
    ch: 'New User',
    ph: 'New User',
  },
  'No permission to modify staff role': {
    en: 'No permission to modify staff role',
    ja: 'No permission to modify staff role',
    et: 'No permission to modify staff role',
    lt: 'No permission to modify staff role',
    ch: 'No permission to modify staff role',
    ph: 'No permission to modify staff role',
  },
  'No starter badges selected': {
    en: 'No starter badges selected',
    ja: 'No starter badges selected',
    et: 'No starter badges selected',
    lt: 'No starter badges selected',
    ch: 'No starter badges selected',
    ph: 'No starter badges selected',
  },
  'No starter challenges selected': {
    en: 'No starter challenges selected',
    ja: 'No starter challenges selected',
    et: 'No starter challenges selected',
    lt: 'No starter challenges selected',
    ch: 'No starter challenges selected',
    ph: 'No starter challenges selected',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  'No, do not allow users to book events through VIVIBOOM': {
    en: 'No, do not allow users to book events through VIVIBOOM',
    ja: 'No, do not allow users to book events through VIVIBOOM',
    et: 'No, do not allow users to book events through VIVIBOOM',
    ch: 'No, do not allow users to book events through VIVIBOOM',
    ph: 'No, do not allow users to book events through VIVIBOOM',
  },
  'No, do not allow users to earn rewards': {
    en: 'No, do not allow users to earn rewards',
    ja: 'No, do not allow users to earn rewards',
    et: 'No, do not allow users to earn rewards',
    lt: 'No, do not allow users to earn rewards',
    ch: 'No, do not allow users to earn rewards',
    ph: 'No, do not allow users to earn rewards',
  },
  'No, we are unable to accommodate visitors.': {
    en: 'No, we are unable to accommodate visitors.',
    ja: 'No, we are unable to accommodate visitors.',
    et: 'No, we are unable to accommodate visitors.',
    lt: 'No, we are unable to accommodate visitors.',
    ch: 'No, we are unable to accommodate visitors.',
    ph: 'No, we are unable to accommodate visitors.',
  },
  None: {
    en: 'None',
    ja: 'None',
    et: 'None',
    lt: 'None',
    ch: 'None',
    ph: 'None',
  },
  'Non-binary': {
    en: 'Non-binary',
    ja: 'Non-binary',
    et: 'Non-binary',
    lt: 'Non-binary',
    ch: 'Non-binary',
    ph: 'Non-binary',
  },
  'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria': {
    en: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
    ja: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
    et: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
    lt: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
    ch: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
    ph: 'Note: Existing Vivinauts WILL NOT be demoted to Explorer status if they did not meet the criteria',
  },
  "Note: if you are an Admin, you will make changes to your branch's google calender integration.": {
    en: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
    ja: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
    et: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
    lt: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
    ch: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
    ph: "Note: if you are an Admin, you will make changes to your branch's google calender integration.",
  },
  "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.": {
    en: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
    ja: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
    et: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
    lt: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
    ch: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
    ph: "Note: if you are an Admin, you will make changes to your branch's admin-only google API integration.",
  },
  'Note: VIVIBOOM does not handle any monetary transaction at the moment.': {
    en: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
    ja: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
    et: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
    lt: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
    ch: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
    ph: 'Note: VIVIBOOM does not handle any monetary transaction at the moment.',
  },
  Now: {
    en: 'Now',
    ja: 'Now',
    et: 'Now',
    lt: 'Now',
    ch: 'Now',
    ph: 'Now',
  },
  'Number of Attended Events Required': {
    en: 'Number of Attended Events Required',
    ja: 'Number of Attended Events Required',
    et: 'Number of Attended Events Required',
    lt: 'Number of Attended Events Required',
    ch: 'Number of Attended Events Required',
    ph: 'Number of Attended Events Required',
  },
  'Number of badges Explorers must earn before becoming a Vivinaut': {
    en: 'Number of badges Explorers must earn before becoming a Vivinaut',
    ja: 'Number of badges Explorers must earn before becoming a Vivinaut',
    et: 'Number of badges Explorers must earn before becoming a Vivinaut',
    lt: 'Number of badges Explorers must earn before becoming a Vivinaut',
    ch: 'Number of badges Explorers must earn before becoming a Vivinaut',
    ph: 'Number of badges Explorers must earn before becoming a Vivinaut',
  },
  'Number of challenges Explorers must earn before becoming a Vivinaut': {
    en: 'Number of challenges Explorers must earn before becoming a Vivinaut',
    ja: 'Number of challenges Explorers must earn before becoming a Vivinaut',
    et: 'Number of challenges Explorers must earn before becoming a Vivinaut',
    lt: 'Number of challenges Explorers must earn before becoming a Vivinaut',
    ch: 'Number of challenges Explorers must earn before becoming a Vivinaut',
    ph: 'Number of challenges Explorers must earn before becoming a Vivinaut',
  },
  'Number of Starter Badges Required': {
    en: 'Number of Starter Badges Required',
    ja: 'Number of Starter Badges Required',
    et: 'Number of Starter Badges Required',
    lt: 'Number of Starter Badges Required',
    ch: 'Number of Starter Badges Required',
    ph: 'Number of Starter Badges Required',
  },
  'Number of Starter Challenges Required': {
    en: 'Number of Starter Challenges Required',
    ja: 'Number of Starter Challenges Required',
    et: 'Number of Starter Challenges Required',
    lt: 'Number of Starter Challenges Required',
    ch: 'Number of Starter Challenges Required',
    ph: 'Number of Starter Challenges Required',
  },
  'Number of events Explorers must attend before becoming a Vivinaut': {
    en: 'Number of events Explorers must attend before becoming a Vivinaut',
    ja: 'Number of events Explorers must attend before becoming a Vivinaut',
    et: 'Number of events Explorers must attend before becoming a Vivinaut',
    lt: 'Number of events Explorers must attend before becoming a Vivinaut',
    ch: 'Number of events Explorers must attend before becoming a Vivinaut',
    ph: 'Number of events Explorers must attend before becoming a Vivinaut',
  },
  'Number of required {{countType}} cannot be less than 0': {
    en: 'Number of required {{countType}} cannot be less than 0',
    ja: 'Number of required {{countType}} cannot be less than 0',
    et: 'Number of required {{countType}} cannot be less than 0',
    lt: 'Number of required {{countType}} cannot be less than 0',
    ch: 'Number of required {{countType}} cannot be less than 0',
    ph: 'Number of required {{countType}} cannot be less than 0',
  },
  Other: {
    en: 'Other',
    ja: 'Other',
    et: 'Other',
    lt: 'Other',
    ch: 'Other',
    ph: 'Other',
  },
  Parent: {
    en: 'Parent',
    ja: 'Parent',
    et: 'Parent',
    lt: 'Parent',
    ch: 'Parent',
    ph: 'Parent',
  },
  Password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Parool',
    lt: 'Password',
    ch: 'Password',
    ph: 'Password',
  },
  'Password (leave blank for no changes)': {
    en: 'Password (leave blank for no changes)',
    ja: 'Password (leave blank for no changes)',
    et: 'Password (leave blank for no changes)',
    lt: 'Password (leave blank for no changes)',
    ch: 'Password (leave blank for no changes)',
    ph: 'Password (leave blank for no changes)',
  },
  'Phone Number': {
    en: 'Phone Number',
    ja: 'Phone Number',
    et: 'Phone Number',
    lt: 'Phone Number',
    ch: 'Phone Number',
    ph: 'Phone Number',
  },
  'Please add a comma between each sibling': {
    en: 'Please add a comma between each sibling',
    ja: 'Please add a comma between each sibling',
    et: 'Please add a comma between each sibling',
    lt: 'Please add a comma between each sibling',
    ch: 'Please add a comma between each sibling',
    ph: 'Please add a comma between each sibling',
  },
  'Please fill up your Google client ID.': {
    en: 'Please fill up your Google client ID.',
    ja: 'Please fill up your Google client ID.',
    et: 'Please fill up your Google client ID.',
    lt: 'Please fill up your Google client ID.',
    ch: 'Please fill up your Google client ID.',
    ph: 'Please fill up your Google client ID.',
  },
  'Please fill up your Google client secret.': {
    en: 'Please fill up your Google client secret.',
    ja: 'Please fill up your Google client secret.',
    et: 'Please fill up your Google client secret.',
    lt: 'Please fill up your Google client secret.',
    ch: 'Please fill up your Google client secret.',
    ph: 'Please fill up your Google client secret.',
  },
  "Please include the dash '-'": {
    en: "Please include the dash '-'",
    ja: "Please include the dash '-'",
    et: "Please include the dash '-'",
    lt: "Please include the dash '-'",
    ch: "Please include the dash '-'",
    ph: "Please include the dash '-'",
  },
  'Preferred Name': {
    en: 'Preferred Name',
    ja: 'Preferred Name',
    et: 'Preferred Name',
    lt: 'Preferred Name',
    ch: 'Preferred Name',
    ph: 'Preferred Name',
  },
  'Prefer not to respond': {
    en: 'Prefer not to respond',
    ja: 'Prefer not to respond',
    et: 'Prefer not to respond',
    lt: 'Prefer not to respond',
    ch: 'Prefer not to respond',
    ph: 'Prefer not to respond',
  },
  'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!':
    {
      en: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
      ja: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
      et: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
      lt: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
      ch: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
      ph: 'Please note that if you try to submit a new pair of client ID and client secret, it will erase the existing pair!',
    },
  'Please select a user to add': {
    en: 'Please select a user to add',
    ja: 'Please select a user to add',
    et: 'Please select a user to add',
    lt: 'Please select a user to add',
    ch: 'Please select a user to add',
    ph: 'Please select a user to add',
  },
  'Privilege Levels': {
    en: 'Privilege Levels',
    ja: 'Privilege Levels',
    et: 'Privilege Levels',
    lt: 'Privilege Levels',
    ch: 'Privilege Levels',
    ph: 'Privilege Levels',
  },
  Profile: {
    en: 'Profile',
    ja: 'Profile',
    et: 'Profile',
    lt: 'Profile',
    ch: 'Profile',
    ph: 'Profile',
  },
  'Profile Description': {
    en: 'Profile Description',
    ja: 'Profile Description',
    et: 'Profile Description',
    lt: 'Profile Description',
    ch: 'Profile Description',
    ph: 'Profile Description',
  },
  'Profile Details': {
    en: 'Profile Details',
    ja: 'Profile Details',
    et: 'Profile Details',
    lt: 'Profile Details',
    ch: 'Profile Details',
    ph: 'Profile Details',
  },
  'Profile saved!': {
    en: 'Profile saved!',
    ja: 'Profile saved!',
    et: 'Profile saved!',
    lt: 'Profile saved!',
    ch: 'Profile saved!',
    ph: 'Profile saved!',
  },
  Project: {
    en: 'Project',
    ja: 'Project',
    et: 'Project',
    lt: 'Project',
    ch: 'Project',
    ph: 'Project',
  },
  'Project Approval Emails': {
    en: 'Project Approval Emails',
    ja: 'Project Approval Emails',
    et: 'Project Approval Emails',
    lt: 'Project Approval Emails',
    ch: 'Project Approval Emails',
    ph: 'Project Approval Emails',
  },
  Read: {
    en: 'Read',
    ja: 'Read',
    et: 'Read',
    lt: 'Read',
    ch: 'Read',
    ph: 'Read',
  },
  'Remove All Badges': {
    en: 'Remove All Badges',
    ja: 'Remove All Badges',
    et: 'Remove All Badges',
    lt: 'Remove All Badges',
    ch: 'Remove All Badges',
    ph: 'Remove All Badges',
  },
  'Remove All Challenges': {
    en: 'Remove All Challenges',
    ja: 'Remove All Challenges',
    et: 'Remove All Challenges',
    lt: 'Remove All Challenges',
    ch: 'Remove All Challenges',
    ph: 'Remove All Challenges',
  },
  'Remove Badge': {
    en: 'Remove Badge',
    ja: 'Remove Badge',
    et: 'Remove Badge',
    lt: 'Remove Badge',
    ch: 'Remove Badge',
    ph: 'Remove Badge',
  },
  'Remove Challenge': {
    en: 'Remove Challenge',
    ja: 'Remove Challenge',
    et: 'Remove Challenge',
    lt: 'Remove Challenge',
    ch: 'Remove Challenge',
    ph: 'Remove Challenge',
  },
  'Remove this user from staff role?': {
    en: 'Remove this user from staff role?',
    ja: 'Remove this user from staff role?',
    et: 'Remove this user from staff role?',
    lt: 'Remove this user from staff role?',
    ch: 'Remove this user from staff role?',
    ph: 'Remove this user from staff role?',
  },
  'Re-request Verification': {
    en: 'Re-request Verification',
    ja: 'Re-request Verification',
    et: 'Re-request Verification',
    lt: 'Re-request Verification',
    ch: 'Re-request Verification',
    ph: 'Re-request Verification',
  },
  Restricted: {
    en: 'Restricted',
    ja: 'Restricted',
    et: 'Restricted',
    lt: 'Restricted',
    ch: 'Restricted',
    ph: 'Restricted',
  },
  RESTRICTED: {
    en: 'RESTRICTED',
    ja: 'RESTRICTED',
    et: 'RESTRICTED',
    lt: 'RESTRICTED',
    ch: 'RESTRICTED',
    ph: 'RESTRICTED',
  },
  'Restricted and Banned users are unable to login': {
    en: 'Restricted and Banned users are unable to login',
    ja: 'Restricted and Banned users are unable to login',
    et: 'Restricted and Banned users are unable to login',
    lt: 'Restricted and Banned users are unable to login',
    ch: 'Restricted and Banned users are unable to login',
    ph: 'Restricted and Banned users are unable to login',
  },
  'Rewards Function': {
    en: 'Rewards Function',
    ja: 'Rewards Function',
    et: 'Rewards Function',
    lt: 'Rewards Function',
    ch: 'Rewards Function',
    ph: 'Rewards Function',
  },
  'Google Drive Folder ID for User Registration Form': {
    en: 'Google Drive Folder ID for User Registration Form',
    ja: 'Google Drive Folder ID for User Registration Form',
    et: 'Google Drive Folder ID for User Registration Form',
    lt: 'Google Drive Folder ID for User Registration Form',
    ch: 'Google Drive Folder ID for User Registration Form',
    ph: 'Google Drive Folder ID for User Registration Form',
  },
  Save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  'Saved!': {
    en: 'Saved!',
    ja: 'Saved!',
    et: 'Saved!',
    lt: 'Saved!',
    ch: 'Saved!',
    ph: 'Saved!',
  },
  'Save changes': {
    en: 'Save changes',
    ja: 'Save changes',
    et: 'Save changes',
    lt: 'Save changes',
    ch: 'Save changes',
    ph: 'Save changes',
  },
  School: {
    en: 'School',
    ja: 'School',
    et: 'School',
    lt: 'School',
    ch: 'School',
    ph: 'School',
  },
  Search: {
    en: 'Search',
    ja: '探す',
    et: 'Otsing',
    lt: 'Search',
    ch: 'Search',
    ph: 'Search',
  },
  'Search Users': {
    en: 'Search Users',
    ja: 'Search Users',
    et: 'Search Users',
    lt: 'Search Users',
    ch: 'Search Users',
    ph: 'Search Users',
  },
  Second: {
    en: 'Second',
    ja: 'Second',
    et: 'Second',
    lt: 'Second',
    ch: 'Second',
    ph: 'Second',
  },
  "Second Guardian's Email": {
    en: "Second Guardian's Email",
    ja: "Second Guardian's Email",
    et: "Second Guardian's Email",
    lt: "Second Guardian's Email",
    ch: "Second Guardian's Email",
    ph: "Second Guardian's Email",
  },
  "Second Guardian's Name": {
    en: "Second Guardian's Name",
    ja: "Second Guardian's Name",
    et: "Second Guardian's Name",
    lt: "Second Guardian's Name",
    ch: "Second Guardian's Name",
    ph: "Second Guardian's Name",
  },
  "Second Guardian's Phone Number": {
    en: "Second Guardian's Phone Number",
    ja: "Second Guardian's Phone Number",
    et: "Second Guardian's Phone Number",
    lt: "Second Guardian's Phone Number",
    ch: "Second Guardian's Phone Number",
    ph: "Second Guardian's Phone Number",
  },
  "Second Guardian's Relationship": {
    en: "Second Guardian's Relationship",
    ja: "Second Guardian's Relationship",
    et: "Second Guardian's Relationship",
    lt: "Second Guardian's Relationship",
    ch: "Second Guardian's Relationship",
    ph: "Second Guardian's Relationship",
  },
  Seconds: {
    en: 'Seconds',
    ja: 'Seconds',
    et: 'Seconds',
    lt: 'Seconds',
    ch: 'Seconds',
    ph: 'Seconds',
  },
  'Selected badges Explorers can earn from before becoming a Vivinaut': {
    en: 'Selected badges Explorers can earn from before becoming a Vivinaut',
    ja: 'Selected badges Explorers can earn from before becoming a Vivinaut',
    et: 'Selected badges Explorers can earn from before becoming a Vivinaut',
    lt: 'Selected badges Explorers can earn from before becoming a Vivinaut',
    ch: 'Selected badges Explorers can earn from before becoming a Vivinaut',
    ph: 'Selected badges Explorers can earn from before becoming a Vivinaut',
  },
  'Selected challenges Explorers can earn from before becoming a Vivinaut': {
    en: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
    ja: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
    et: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
    lt: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
    ch: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
    ph: 'Selected challenges Explorers can earn from before becoming a Vivinaut',
  },
  'Selected Starter Badges': {
    en: 'Selected Starter Badges',
    ja: 'Selected Starter Badges',
    et: 'Selected Starter Badges',
    lt: 'Selected Starter Badges',
    ch: 'Selected Starter Badges',
    ph: 'Selected Starter Badges',
  },
  'Selected Starter Challenges': {
    en: 'Selected Starter Challenges',
    ja: 'Selected Starter Challenges',
    et: 'Selected Starter Challenges',
    lt: 'Selected Starter Challenges',
    ch: 'Selected Starter Challenges',
    ph: 'Selected Starter Challenges',
  },
  'Sign-Up Code': {
    en: 'Sign-Up Code',
    ja: 'Sign-Up Code',
    et: 'Sign-Up Code',
    lt: 'Sign-Up Code',
    ch: 'Sign-Up Code',
    ph: 'Sign-Up Code',
  },
  'Skip Verification': {
    en: 'Skip Verification',
    ja: 'Skip Verification',
    et: 'Skip Verification',
    lt: 'Skip Verification',
    ch: 'Skip Verification',
    ph: 'Skip Verification',
  },
  'Staff Role': {
    en: 'Staff Role',
    ja: 'スタッフの役割',
    et: 'Töötajate rollid',
    lt: 'Staff Role',
    ch: 'Staff Role',
    ph: 'Staff Role',
  },
  'Staff Role Assigned To': {
    en: 'Staff Role Assigned To',
    ja: 'Staff Role Assigned To',
    et: 'Staff Role Assigned To',
    lt: 'Staff Role Assigned To',
    ch: 'Staff Role Assigned To',
    ph: 'Staff Role Assigned To',
  },
  'Staff Role Name': {
    en: 'Staff Role Name',
    ja: 'Staff Role Name',
    et: 'Staff Role Name',
    lt: 'Staff Role Name',
    ch: 'Staff Role Name',
    ph: 'Staff Role Name',
  },
  'Staff Roles': {
    en: 'Staff Roles',
    ja: 'スタッフの役割',
    et: 'Töötajate rollid',
    lt: 'Staff Roles',
    ch: 'Staff Roles',
    ph: 'Staff Roles',
  },
  'Staff role is not assigned to any user yet.': {
    en: 'Staff role is not assigned to any user yet.',
    ja: 'Staff role is not assigned to any user yet.',
    et: 'Staff role is not assigned to any user yet.',
    lt: 'Staff role is not assigned to any user yet.',
    ch: 'Staff role is not assigned to any user yet.',
    ph: 'Staff role is not assigned to any user yet.',
  },
  StaffRole: {
    en: 'Staff Role',
    ja: 'スタッフの役割',
    et: 'Töötajate rollid',
    lt: 'Staff Role',
    ch: 'Staff Role',
    ph: 'Staff Role',
  },
  Status: {
    en: 'Status',
    ja: 'Status',
    et: 'Status',
    lt: 'Status',
    ch: 'Status',
    ph: 'Status',
  },
  SUSPENDED: {
    en: 'SUSPENDED',
    ja: 'SUSPENDED',
    et: 'SUSPENDED',
    lt: 'SUSPENDED',
    ch: 'SUSPENDED',
    ph: 'SUSPENDED',
  },
  'Sync Admin Google Services': {
    en: 'Sync Admin Google Services',
    ja: 'Sync Admin Google Services',
    et: 'Sync Admin Google Services',
    lt: 'Sync Admin Google Services',
    ch: 'Sync Admin Google Services',
    ph: 'Sync Admin Google Services',
  },
  'Sync Google Calendar': {
    en: 'Sync Google Calendar',
    ja: 'Sync Google Calendar',
    et: 'Sync Google Calendar',
    lt: 'Sync Google Calendar',
    ch: 'Sync Google Calendar',
    ph: 'Sync Google Calendar',
  },
  'Sync Google Calendar Credentials': {
    en: 'Sync Google Calendar Credentials',
    ja: 'Sync Google Calendar Credentials',
    et: 'Sync Google Calendar Credentials',
    lt: 'Sync Google Calendar Credentials',
    ch: 'Sync Google Calendar Credentials',
    ph: 'Sync Google Calendar Credentials',
  },
  'Sync Google Credentials (Admin)': {
    en: 'Sync Google Credentials (Admin)',
    ja: 'Sync Google Credentials (Admin)',
    et: 'Sync Google Credentials (Admin)',
    lt: 'Sync Google Credentials (Admin)',
    ch: 'Sync Google Credentials (Admin)',
    ph: 'Sync Google Credentials (Admin)',
  },
  'This is a new staff role': {
    en: 'This is a new staff role',
    ja: 'This is a new staff role',
    et: 'This is a new staff role',
    lt: 'This is a new staff role',
    ch: 'This is a new staff role',
    ph: 'This is a new staff role',
  },
  'This is a new user': {
    en: 'This is a new user',
    ja: 'This is a new user',
    et: 'This is a new user',
    lt: 'This is a new user',
    ch: 'This is a new user',
    ph: 'This is a new user',
  },
  'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.': {
    en: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
    ja: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
    et: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
    lt: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
    ch: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
    ph: 'There is a set of credentials (client ID, client secret, OAuth refresh token) for this branch.',
  },
  'Unban User': {
    en: 'Unban User',
    ja: 'Unban User',
    et: 'Unban User',
    lt: 'Unban User',
    ch: 'Unban User',
    ph: 'Unban User',
  },
  Update: {
    en: 'Update',
    ja: 'Update',
    et: 'Update',
    lt: 'Update',
    ch: 'Update',
    ph: 'Update',
  },
  'Update of Google credentials cancelled.': {
    en: 'Update of Google credentials cancelled.',
    ja: 'Update of Google credentials cancelled.',
    et: 'Update of Google credentials cancelled.',
    lt: 'Update of Google credentials cancelled.',
    ch: 'Update of Google credentials cancelled.',
    ph: 'Update of Google credentials cancelled.',
  },
  'Upload Cover Picture': {
    en: 'Upload Cover Picture',
    ja: 'Upload Cover Picture',
    et: 'Upload Cover Picture',
    lt: 'Upload Cover Picture',
    ch: 'Upload Cover Picture',
    ph: 'Upload Cover Picture',
  },
  'Upload Profile Picture': {
    en: 'Upload Profile Picture',
    ja: 'Upload Profile Picture',
    et: 'Upload Profile Picture',
    lt: 'Upload Profile Picture',
    ch: 'Upload Profile Picture',
    ph: 'Upload Profile Picture',
  },
  User: {
    en: 'User',
    ja: 'User',
    et: 'User',
    lt: 'User',
    ch: 'User',
    ph: 'User',
  },
  'User Added': {
    en: 'User Added',
    ja: 'User Added',
    et: 'User Added',
    lt: 'User Added',
    ch: 'User Added',
    ph: 'User Added',
  },
  'User Consent to Media Release': {
    en: 'User Consent to Media Release',
    ja: 'User Consent to Media Release',
    et: 'User Consent to Media Release',
    lt: 'User Consent to Media Release',
    ch: 'User Consent to Media Release',
    ph: 'User Consent to Media Release',
  },
  'User has not been awarded badge yet.': {
    en: 'User has not been awarded badge yet.',
    ja: 'User has not been awarded badge yet.',
    et: 'User has not been awarded badge yet.',
    lt: 'User has not been awarded badge yet.',
    ch: 'User has not been awarded badge yet.',
    ph: 'User has not been awarded badge yet.',
  },
  'User Info': {
    en: 'User Info',
    ja: 'User Info',
    et: 'User Info',
    lt: 'User Info',
    ch: 'User Info',
    ph: 'User Info',
  },
  'User removed': {
    en: 'User removed',
    ja: 'User removed',
    et: 'User removed',
    lt: 'User removed',
    ch: 'User removed',
    ph: 'User removed',
  },
  'User Status': {
    en: 'User Status',
    ja: 'User Status',
    et: 'User Status',
    lt: 'User Status',
    ch: 'User Status',
    ph: 'User Status',
  },
  Username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Username',
    ch: 'Username',
    ph: 'Username',
  },
  Users: {
    en: 'Users',
    ja: 'ユーザー',
    et: 'Kasutajad',
    lt: 'Users',
    ch: 'Users',
    ph: 'Users',
  },
  'Verify User Email': {
    en: 'Verify User Email',
    ja: 'Verify User Email',
    et: 'Verify User Email',
    lt: 'Verify User Email',
    ch: 'Verify User Email',
    ph: 'Verify User Email',
  },
  'View Wallet': {
    en: 'View Wallet',
    ja: 'View Wallet',
    et: 'View Wallet',
    lt: 'View Wallet',
    ch: 'View Wallet',
    ph: 'View Wallet',
  },
  'Visitor Settings': {
    en: 'Visitor Settings',
    ja: 'Visitor Settings',
    et: 'Visitor Settings',
    lt: 'Visitor Settings',
    ch: 'Visitor Settings',
    ph: 'Visitor Settings',
  },
  vivinaut: {
    en: 'Vivinaut',
    ja: 'Vivinaut',
    et: 'Vivinaut',
    lt: 'Vivinaut',
    ch: 'Vivinaut',
    ph: 'Vivinaut',
  },
  'VIVINAUT Details': {
    en: 'VIVINAUT Details',
    ja: 'VIVINAUT Details',
    et: 'VIVINAUT Details',
    lt: 'VIVINAUT Details',
    ch: 'VIVINAUT Details',
    ph: 'VIVINAUT Details',
  },
  "VIVINAUT's Email": {
    en: "VIVINAUT's Email",
    ja: "VIVINAUT's Email",
    et: "VIVINAUT's Email",
    lt: "VIVINAUT's Email",
    ch: "VIVINAUT's Email",
    ph: "VIVINAUT's Email",
  },
  'VIVINAUT Info': {
    en: 'VIVINAUT Info',
    ja: 'VIVINAUT Info',
    et: 'VIVINAUT Info',
    lt: 'VIVINAUT Info',
    ch: 'VIVINAUT Info',
    ph: 'VIVINAUT Info',
  },
  "VIVINAUT's Phone Number": {
    en: "VIVINAUT's Phone Number",
    ja: "VIVINAUT's Phone Number",
    et: "VIVINAUT's Phone Number",
    lt: "VIVINAUT's Phone Number",
    ch: "VIVINAUT's Phone Number",
    ph: "VIVINAUT's Phone Number",
  },
  "VIVINAUT's School": {
    en: "VIVINAUT's School",
    ja: "VIVINAUT's School",
    et: "VIVINAUT's School",
    lt: "VIVINAUT's School",
    ch: "VIVINAUT's School",
    ph: "VIVINAUT's School",
  },
  'VIVISTOP Branch': {
    en: 'VIVISTOP Branch',
    ja: 'VIVISTOP Branch',
    et: 'VIVISTOP Branch',
    lt: 'VIVISTOP Branch',
    ch: 'VIVISTOP Branch',
    ph: 'VIVISTOP Branch',
  },
  Wallet: {
    en: 'Wallet',
    ja: 'Wallet',
    et: 'Wallet',
    lt: 'Wallet',
    ch: 'Wallet',
    ph: 'Wallet',
  },
  weekdays: {
    en: 'weekdays',
    ja: '平日',
    et: 'tööpäevad',
    lt: 'weekdays',
    ch: 'weekdays',
    ph: 'weekdays',
  },
  weekends: {
    en: 'weekends',
    ja: '週末',
    et: 'nädalavahetustel',
    lt: 'weekends',
    ch: 'weekends',
    ph: 'weekends',
  },
  'Yes! I would like users to be able to earn rewards': {
    en: 'Yes! I would like users to be able to earn rewards',
    ja: 'Yes! I would like users to be able to earn rewards',
    et: 'Yes! I would like users to be able to earn rewards',
    lt: 'Yes! I would like users to be able to earn rewards',
    ch: 'Yes! I would like users to be able to earn rewards',
    ph: 'Yes! I would like users to be able to earn rewards',
  },
  'Yes! I would like users to book events through VIVIBOOM': {
    en: 'Yes! I would like users to book events through VIVIBOOM',
    ja: 'Yes! I would like users to book events through VIVIBOOM',
    et: 'Yes! I would like users to book events through VIVIBOOM',
    lt: 'Yes! I would like users to book events through VIVIBOOM',
    ch: 'Yes! I would like users to book events through VIVIBOOM',
    ph: 'Yes! I would like users to book events through VIVIBOOM',
  },
  'Yes, I would love for visitors to drop by!': {
    en: 'Yes, I would love for visitors to drop by!',
    ja: 'Yes, I would love for visitors to drop by!',
    et: 'Yes, I would love for visitors to drop by!',
    lt: 'Yes, I would love for visitors to drop by!',
    ch: 'Yes, I would love for visitors to drop by!',
    ph: 'Yes, I would love for visitors to drop by!',
  },
  'Your account status is insufficient to view this page. Please contact a local admin.': {
    en: 'Your account status is insufficient to view this page. Please contact a local admin.',
    ja: 'Your account status is insufficient to view this page. Please contact a local admin.',
    et: 'Your account status is insufficient to view this page. Please contact a local admin.',
    lt: 'Your account status is insufficient to view this page. Please contact a local admin.',
    ch: 'Your account status is insufficient to view this page. Please contact a local admin.',
    ph: 'Your account status is insufficient to view this page. Please contact a local admin.',
  },
};
