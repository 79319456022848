export default {
  Badges: {
    en: 'Badges',
    ja: 'バッジ',
    et: 'Märgid',
    lt: 'Badges',
    ch: 'Badges',
    ph: 'Badges',
  },
  Bookings: {
    en: 'Bookings',
    ja: 'Bookings',
    et: 'Bookings',
    lt: 'Bookings',
    ch: 'Bookings',
    ph: 'Bookings',
  },
  'Bookings Slots': {
    en: 'Bookings Slots',
    ja: 'イベント',
    et: 'Sündmused',
    lt: 'Bookings Slots',
    ch: 'Bookings Slots',
    ph: 'Bookings Slots',
  },
  Branches: {
    en: 'Branches',
    ja: 'Branches',
    et: 'Branches',
    lt: 'Branches',
    ch: 'Branches',
    ph: 'Branches',
  },
  changeLanguage: {
    en: 'Change Language',
    ja: '言語の変更',
    et: 'Muuda keelt',
    lt: 'Change Language',
    ch: 'Change Language',
    ph: 'Change Language',
  },
  Challenges: {
    en: 'Challenges',
    ja: '挑戦',
    et: 'Väljakutsed',
    lt: 'Challenges',
    ch: 'Challenges',
    ph: 'Challenges',
  },
  Comments: {
    en: 'Comments',
    ja: 'コメント',
    et: 'Kommentaarid',
    lt: 'Comments',
    ch: 'Comments',
    ph: 'Comments',
  },
  Classes: {
    en: 'Classes',
    ja: 'Classes',
    et: 'Classes',
    lt: 'Classes',
    ch: 'Classes',
    ph: 'Classes',
  },
  createAccount: {
    en: 'Create Account',
    ja: 'アカウント登録',
    et: 'Loo konto',
    lt: 'Create Account',
    ch: 'Create Account',
    ph: 'Create Account',
  },
  Dashboard: {
    en: 'Dashboard',
    ja: 'ダッシュボード',
    et: 'Armatuurlaud',
    lt: 'Dashboard',
    ch: 'Dashboard',
    ph: 'Dashboard',
  },
  Events: {
    en: 'Events',
    ja: 'Events',
    et: 'Events',
    lt: 'Events',
    ch: 'Events',
    ph: 'Events',
  },
  language: {
    en: 'English',
    ja: '日本語',
    et: 'Estonian',
    lt: 'Lithuanian',
    ch: '华语',
    ph: 'Tagalog',
  },
  login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Login',
    ch: 'Login',
    ph: 'Login',
  },
  logout: {
    en: 'Logout',
    ja: 'ログアウト',
    et: 'Logi välja',
    lt: 'Logout',
    ch: 'Logout',
    ph: 'Logout',
  },
  mail: {
    en: 'Mail Templates',
    ja: 'メールテンプレート',
    et: 'E-maili mustandid',
    lt: 'Mail Templates',
    ch: 'Mail Templates',
    ph: 'Mail Templates',
  },
  myAccount: {
    en: 'My Account',
    ja: 'マイアカウント',
    et: 'Minu konto',
    lt: 'My Account',
    ch: 'My Account',
    ph: 'My Account',
  },
  'My Branch': {
    en: 'My Branch',
    ja: '私の支店',
    et: 'Minu filiaal',
    lt: 'My Branch',
    ch: 'My Branch',
    ph: 'My Branch',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    ja: 'プライバシーポリシー',
    et: 'Privaatsuspoliitika',
    lt: 'Privacy Policy',
    ch: 'Privacy Policy',
    ph: 'Privacy Policy',
  },
  Projects: {
    en: 'Projects',
    ja: 'プロジェクト',
    et: 'Projektid',
    lt: 'Projects',
    ch: 'Projects',
    ph: 'Projects',
  },
  'Public Portfolio': {
    en: 'Public Portfolio',
    ja: 'Public Portfolio',
    et: 'Public Portfolio',
    lt: 'Public Portfolio',
    ch: 'Public Portfolio',
    ph: 'Public Portfolio',
  },
  'Quota Allocation': {
    en: 'Quota Allocation',
    ja: 'クォータ',
    et: 'Kvoot',
    lt: 'Quota Allocation',
    ch: 'Quota Allocation',
    ph: 'Quota Allocation',
  },
  terms: {
    en: 'Terms and Conditions',
    ja: '規約と条件',
    et: 'Kasutustingimused',
    lt: 'Terms and Conditions',
    ch: 'Terms and Conditions',
    ph: 'Terms and Conditions',
  },
  users: {
    en: 'Users',
    ja: 'ユーザー',
    et: 'Kasutajad',
    lt: 'Users',
    ch: 'Users',
    ph: 'Users',
  },
  Users: {
    en: 'Users',
    ja: 'ユーザー',
    et: 'Kasutajad',
    lt: 'Users',
    ch: 'Users',
    ph: 'Users',
  },
  'User Bookings': {
    en: 'User Bookings',
    ja: 'ユーザー予約',
    et: 'Broneeringud',
    lt: 'User Bookings',
    ch: 'User Bookings',
    ph: 'User Bookings',
  },
  Vivicoin: {
    en: 'Vivicoin',
    ja: 'Vivicoin',
    et: 'Vivicoin',
    lt: 'Vivicoin',
    ch: 'Vivicoin',
    ph: 'Vivicoin',
  },
  Vivivault: {
    en: 'Vivivault',
    ja: 'Vivivault',
    et: 'Vivivault',
    lt: 'Vivivault',
    ch: 'Vivivault',
    ph: 'Vivivault',
  },
};
