export default {
  'Public Portfolio': {
    en: 'Public Portfolio',
    ja: 'Public Portfolio',
    et: 'Public Portfolio',
    lt: 'Public Portfolio',
    ch: 'Public Portfolio',
    ph: 'Public Portfolio',
  },
  'New Portfolio Page': {
    en: 'New Portfolio Page',
    ja: 'New Portfolio Page',
    et: 'New Portfolio Page',
    lt: 'New Portfolio Page',
    ch: 'New Portfolio Page',
    ph: 'New Portfolio Page',
  },
  'Edit Portfolio Page': {
    en: 'Edit Portfolio Page',
    ja: 'Edit Portfolio Page',
    et: 'Edit Portfolio Page',
    lt: 'Edit Portfolio Page',
    ch: 'Edit Portfolio Page',
    ph: 'Edit Portfolio Page',
  },
  All: {
    en: 'All',
    ja: 'All',
    et: 'All',
    lt: 'All',
    ch: 'All',
    ph: 'All',
  },
  Live: {
    en: 'Live',
    ja: 'Live',
    et: 'Live',
    lt: 'Live',
    ch: 'Live',
    ph: 'Live',
  },
  Drafts: {
    en: 'Drafts',
    ja: 'Drafts',
    et: 'Drafts',
    lt: 'Drafts',
    ch: 'Drafts',
    ph: 'Drafts',
  },
  Page: {
    en: 'Page',
    ja: 'Page',
    et: 'Page',
    lt: 'Page',
    ch: 'Page',
    ph: 'Page',
  },
  'Created By': {
    en: 'Created By',
    ja: 'Created By',
    et: 'Created By',
    lt: 'Created By',
    ch: 'Created By',
    ph: 'Created By',
  },
  'Search Portfolios': {
    en: 'Search Portfolios',
    ja: 'Search Portfolios',
    et: 'Search Portfolios',
    lt: 'Search Portfolios',
    ch: 'Search Portfolios',
    ph: 'Search Portfolios',
  },
  'Search Projects': {
    en: 'Search Projects',
    ja: 'Search Projects',
    et: 'Search Projects',
    lt: 'Search Projects',
    ch: 'Search Projects',
    ph: 'Search Projects',
  },
  Search: {
    en: 'Search',
    ja: 'Search',
    et: 'Search',
    lt: 'Search',
    ch: 'Search',
    ph: 'Search',
  },
  'No project found': {
    en: 'No project found',
    ja: 'No project found',
    et: 'No project found',
    lt: 'No project found',
    ch: 'No project found',
    ph: 'No project found',
  },
  'Add a heading for the project section (optional)': {
    en: 'Add a heading for the project section (optional)',
    ja: 'Add a heading for the project section (optional)',
    et: 'Add a heading for the project section (optional)',
    lt: 'Add a heading for the project section (optional)',
    ch: 'Add a heading for the project section (optional)',
    ph: 'Add a heading for the project section (optional)',
  },
  'Public Page': {
    en: 'Public Page',
    ja: 'Public Page',
    et: 'Public Page',
    lt: 'Public Page',
    ch: 'Public Page',
    ph: 'Public Page',
  },
  'No Public Pages Created': {
    en: 'No Public Pages Created',
    ja: 'No Public Pages Created',
    et: 'No Public Pages Created',
    lt: 'No Public Pages Created',
    ch: 'No Public Pages Created',
    ph: 'No Public Pages Created',
  },
  'Public link to this page': {
    en: 'Public link to this page',
    ja: 'Public link to this page',
    et: 'Public link to this page',
    lt: 'Public link to this page',
    ch: 'Public link to this page',
    ph: 'Public link to this page',
  },
  'Add a unique Viviboom URL here': {
    en: 'Add a unique Viviboom URL here',
    ja: 'Add a unique Viviboom URL here',
    et: 'Add a unique Viviboom URL here',
    lt: 'Add a unique Viviboom URL here',
    ch: 'Add a unique Viviboom URL here',
    ph: 'Add a unique Viviboom URL here',
  },
  'Add a title for this public page': {
    en: 'Add a title for this public page',
    ja: 'Add a title for this public page',
    et: 'Add a title for this public page',
    lt: 'Add a title for this public page',
    ch: 'Add a title for this public page',
    ph: 'Add a title for this public page',
  },
  Title: {
    en: 'Title',
    ja: 'Title',
    et: 'Title',
    lt: 'Title',
    ch: 'Title',
    ph: 'Title',
  },
  'About this page': {
    en: 'About this page',
    ja: 'About this page',
    et: 'About this page',
    lt: 'About this page',
    ch: 'About this page',
    ph: 'About this page',
  },
  'Introduce this page to the public (optional)': {
    en: 'Introduce this page to the public (optional)',
    ja: 'Introduce this page to the public (optional)',
    et: 'Introduce this page to the public (optional)',
    lt: 'Introduce this page to the public (optional)',
    ch: 'Introduce this page to the public (optional)',
    ph: 'Introduce this page to the public (optional)',
  },
  'Only completed projects are shown here': {
    en: 'Only completed projects are shown here',
    ja: 'Only completed projects are shown here',
    et: 'Only completed projects are shown here',
    lt: 'Only completed projects are shown here',
    ch: 'Only completed projects are shown here',
    ph: 'Only completed projects are shown here',
  },
  'Remove Project': {
    en: 'Remove Project',
    ja: 'Remove Project',
    et: 'Remove Project',
    lt: 'Remove Project',
    ch: 'Remove Project',
    ph: 'Remove Project',
  },
  'Selected! Scroll up to rearrange / remove project!': {
    en: 'Selected! Scroll up to rearrange / remove project!',
    ja: 'Selected! Scroll up to rearrange / remove project!',
    et: 'Selected! Scroll up to rearrange / remove project!',
    lt: 'Selected! Scroll up to rearrange / remove project!',
    ch: 'Selected! Scroll up to rearrange / remove project!',
    ph: 'Selected! Scroll up to rearrange / remove project!',
  },
  'All Projects': {
    en: 'All Projects',
    ja: 'All Projects',
    et: 'All Projects',
    lt: 'All Projects',
    ch: 'All Projects',
    ph: 'All Projects',
  },
  'Name of Public Page': {
    en: 'Name of Public Page',
    ja: 'Name of Public Page',
    et: 'Name of Public Page',
    lt: 'Name of Public Page',
    ch: 'Name of Public Page',
    ph: 'Name of Public Page',
  },
  'Description of Public Page': {
    en: 'Description of Public Page',
    ja: 'Description of Public Page',
    et: 'Description of Public Page',
    lt: 'Description of Public Page',
    ch: 'Description of Public Page',
    ph: 'Description of Public Page',
  },
  'Page Details': {
    en: 'Page Details',
    ja: 'Page Details',
    et: 'Page Details',
    lt: 'Page Details',
    ch: 'Page Details',
    ph: 'Page Details',
  },
  Link: {
    en: 'Link',
    ja: 'Link',
    et: 'Link',
    lt: 'Link',
    ch: 'Link',
    ph: 'Link',
  },
  'Upload Cover Image': {
    en: 'Upload Cover Image',
    ja: 'Upload Cover Image',
    et: 'Upload Cover Image',
    lt: 'Upload Cover Image',
    ch: 'Upload Cover Image',
    ph: 'Upload Cover Image',
  },
  'Cover Photo': {
    en: 'Cover Photo',
    ja: 'Cover Photo',
    et: 'Cover Photo',
    lt: 'Cover Photo',
    ch: 'Cover Photo',
    ph: 'Cover Photo',
  },
  'Upload Profile Picture': {
    en: 'Upload Profile Picture',
    ja: 'Upload Profile Picture',
    et: 'Upload Profile Picture',
    lt: 'Upload Profile Picture',
    ch: 'Upload Profile Picture',
    ph: 'Upload Profile Picture',
  },
  'Profile Picture': {
    en: 'Profile Picture',
    ja: 'Profile Picture',
    et: 'Profile Picture',
    lt: 'Profile Picture',
    ch: 'Profile Picture',
    ph: 'Profile Picture',
  },
  'Display Options': {
    en: 'Display Options',
    ja: 'Display Options',
    et: 'Display Options',
    lt: 'Display Options',
    ch: 'Display Options',
    ph: 'Display Options',
  },
  'Show Full Name': {
    en: 'Show Full Name',
    ja: 'Show Full Name',
    et: 'Show Full Name',
    lt: 'Show Full Name',
    ch: 'Show Full Name',
    ph: 'Show Full Name',
  },
  'Show Profile Image': {
    en: 'Show Profile Image',
    ja: 'Show Profile Image',
    et: 'Show Profile Image',
    lt: 'Show Profile Image',
    ch: 'Show Profile Image',
    ph: 'Show Profile Image',
  },
  'Show Country Flag': {
    en: 'Show Country Flag',
    ja: 'Show Country Flag',
    et: 'Show Country Flag',
    lt: 'Show Country Flag',
    ch: 'Show Country Flag',
    ph: 'Show Country Flag',
  },
  'Selected Projects': {
    en: 'Selected Projects',
    ja: 'Selected Projects',
    et: 'Selected Projects',
    lt: 'Selected Projects',
    ch: 'Selected Projects',
    ph: 'Selected Projects',
  },
  'Drag and drop the projects to change the order they appear on your public portfolio page':
    {
      en: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ja: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      et: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      lt: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ch: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ph: 'Drag and drop the projects to change the order they appear on your public portfolio page',
    },
  'No projects has been selected for this page yet': {
    en: 'No projects has been selected for this page yet',
    ja: 'No projects has been selected for this page yet',
    et: 'No projects has been selected for this page yet',
    lt: 'No projects has been selected for this page yet',
    ch: 'No projects has been selected for this page yet',
    ph: 'No projects has been selected for this page yet',
  },
  Save: {
    en: 'Save',
    ja: 'Save',
    et: 'Save',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  'View Public Portfolio': {
    en: 'View Public Portfolio',
    ja: 'View Public Portfolio',
    et: 'View Public Portfolio',
    lt: 'View Public Portfolio',
    ch: 'View Public Portfolio',
    ph: 'View Public Portfolio',
  },
  'Select and reorder projects to put in the public page!': {
    en: 'Select and reorder projects to put in the public page!',
    ja: 'Select and reorder projects to put in the public page!',
    et: 'Select and reorder projects to put in the public page!',
    lt: 'Select and reorder projects to put in the public page!',
    ch: 'Select and reorder projects to put in the public page!',
    ph: 'Select and reorder projects to put in the public page!',
  },
  'Select Project': {
    en: 'Select Project',
    ja: 'Select Project',
    et: 'Select Project',
    lt: 'Select Project',
    ch: 'Select Project',
    ph: 'Select Project',
  },
  'Selected Badges': {
    en: 'Selected Badges',
    ja: 'Selected Badges',
    et: 'Selected Badges',
    lt: 'Selected Badges',
    ch: 'Selected Badges',
    ph: 'Selected Badges',
  },
  'Select Badge': {
    en: 'Select Badge',
    ja: 'Select Badge',
    et: 'Select Badge',
    lt: 'Select Badge',
    ch: 'Select Badge',
    ph: 'Select Badge',
  },
  'Drag and drop the badges to change the order they appear on your public portfolio page': {
    en: 'Drag and drop the badges to change the order they appear on your public portfolio page',
    ja: 'Drag and drop the badges to change the order they appear on your public portfolio page',
    et: 'Drag and drop the badges to change the order they appear on your public portfolio page',
    lt: 'Drag and drop the badges to change the order they appear on your public portfolio page',
    ch: 'Drag and drop the badges to change the order they appear on your public portfolio page',
    ph: 'Drag and drop the badges to change the order they appear on your public portfolio page',
  },
  'No badges has been selected for this page yet': {
    en: 'No badges has been selected for this page yet',
    ja: 'No badges has been selected for this page yet',
    et: 'No badges has been selected for this page yet',
    lt: 'No badges has been selected for this page yet',
    ch: 'No badges has been selected for this page yet',
    ph: 'No badges has been selected for this page yet',
  },
  'Select and reorder badges to put in the public page!': {
    en: 'Select and reorder badges to put in the public page!',
    ja: 'Select and reorder badges to put in the public page!',
    et: 'Select and reorder badges to put in the public page!',
    lt: 'Select and reorder badges to put in the public page!',
    ch: 'Select and reorder badges to put in the public page!',
    ph: 'Select and reorder badges to put in the public page!',
  },
  'Search Badges': {
    ja: 'Search Badges',
    en: 'Search Badges',
    et: 'Search Badges',
    lt: 'Search Badges',
    ch: 'Search Badges',
    ph: 'Search Badges',
  },
  'No badge found': {
    en: 'No badge found',
    ja: 'No badge found',
    et: 'No badge found',
    lt: 'No badge found',
    ch: 'No badge found',
    ph: 'No badge found',
  },
  'Add a heading for the badge section (optional)': {
    en: 'Add a heading for the badge section (optional)',
    ja: 'Add a heading for the badge section (optional)',
    et: 'Add a heading for the badge section (optional)',
    lt: 'Add a heading for the badge section (optional)',
    ch: 'Add a heading for the badge section (optional)',
    ph: 'Add a heading for the badge section (optional)',
  },
  'Select from all Viviboom badges': {
    en: 'Select from all Viviboom badges',
    ja: 'Select from all Viviboom badges',
    et: 'Select from all Viviboom badges',
    lt: 'Select from all Viviboom badges',
    ch: 'Select from all Viviboom badges',
    ph: 'Select from all Viviboom badges',
  },
  'Remove Badge': {
    en: 'Remove Badge',
    ja: 'Remove Badge',
    et: 'Remove Badge',
    lt: 'Remove Badge',
    ch: 'Remove Badge',
    ph: 'Remove Badge',
  },
  'Selected! Scroll up to rearrange / remove badge!': {
    en: 'Selected! Scroll up to rearrange / remove badge!',
    ja: 'Selected! Scroll up to rearrange / remove badge!',
    et: 'Selected! Scroll up to rearrange / remove badge!',
    lt: 'Selected! Scroll up to rearrange / remove badge!',
    ch: 'Selected! Scroll up to rearrange / remove badge!',
    ph: 'Selected! Scroll up to rearrange / remove badge!',
  },
  'All Badges': {
    en: 'All Badges',
    ja: 'All Badges',
    et: 'All Badges',
    lt: 'All Badges',
    ch: 'All Badges',
    ph: 'All Badges',
  },
  'Selected Challenges': {
    en: 'Selected Challenges',
    ja: 'Selected Challenges',
    et: 'Selected Challenges',
    lt: 'Selected Challenges',
    ch: 'Selected Challenges',
    ph: 'Selected Challenges',
  },
  'Select Challenge': {
    en: 'Select Challenge',
    ja: 'Select Challenge',
    et: 'Select Challenge',
    lt: 'Select Challenge',
    ch: 'Select Challenge',
    ph: 'Select Challenge',
  },
  'Drag and drop the challenges to change the order they appear on your public portfolio page': {
    en: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
    ja: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
    et: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
    lt: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
    ch: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
    ph: 'Drag and drop the challenges to change the order they appear on your public portfolio page',
  },
  'No challenges has been selected for this page yet': {
    en: 'No challenges has been selected for this page yet',
    ja: 'No challenges has been selected for this page yet',
    et: 'No challenges has been selected for this page yet',
    lt: 'No challenges has been selected for this page yet',
    ch: 'No challenges has been selected for this page yet',
    ph: 'No challenges has been selected for this page yet',
  },
  'Select and reorder challenges to put in the public page!': {
    en: 'Select and reorder challenges to put in the public page!',
    ja: 'Select and reorder challenges to put in the public page!',
    et: 'Select and reorder challenges to put in the public page!',
    lt: 'Select and reorder challenges to put in the public page!',
    ch: 'Select and reorder challenges to put in the public page!',
    ph: 'Select and reorder challenges to put in the public page!',
  },
  'Search Challenges': {
    en: 'Search Challenges',
    ja: 'Search Challenges',
    et: 'Search Challenges',
    lt: 'Search Challenges',
    ch: 'Search Challenges',
    ph: 'Search Challenges',
  },
  'No challenge found': {
    en: 'No challenge found',
    ja: 'No challenge found',
    et: 'No challenge found',
    lt: 'No challenge found',
    ch: 'No challenge found',
    ph: 'No challenge found',
  },
  'Add a heading for the challenge section (optional)': {
    en: 'Add a heading for the challenge section (optional)',
    ja: 'Add a heading for the challenge section (optional)',
    et: 'Add a heading for the challenge section (optional)',
    lt: 'Add a heading for the challenge section (optional)',
    ch: 'Add a heading for the challenge section (optional)',
    ph: 'Add a heading for the challenge section (optional)',
  },
  'Select from all Viviboom challenges': {
    en: 'Select from all Viviboom challenges',
    ja: 'Select from all Viviboom challenges',
    et: 'Select from all Viviboom challenges',
    lt: 'Select from all Viviboom challenges',
    ch: 'Select from all Viviboom challenges',
    ph: 'Select from all Viviboom challenges',
  },
  'Remove Challenge': {
    en: 'Remove Challenge',
    ja: 'Remove Challenge',
    et: 'Remove Challenge',
    lt: 'Remove Challenge',
    ch: 'Remove Challenge',
    ph: 'Remove Challenge',
  },
  'Selected! Scroll up to rearrange / remove challenge!': {
    en: 'Selected! Scroll up to rearrange / remove challenge!',
    ja: 'Selected! Scroll up to rearrange / remove challenge!',
    et: 'Selected! Scroll up to rearrange / remove challenge!',
    lt: 'Selected! Scroll up to rearrange / remove challenge!',
    ch: 'Selected! Scroll up to rearrange / remove challenge!',
    ph: 'Selected! Scroll up to rearrange / remove challenge!',
  },
  'All Challenges': {
    en: 'All Challenges',
    ja: 'All Challenges',
    et: 'All Challenges',
    lt: 'All Challenges',
    ch: 'All Challenges',
    ph: 'All Challenges',
  },
  'Selected Events': {
    en: 'Selected Events',
    ja: 'Selected Events',
    et: 'Selected Events',
    lt: 'Selected Events',
    ch: 'Selected Events',
    ph: 'Selected Events',
  },
  'Select Event': {
    en: 'Select Event',
    ja: 'Select Event',
    et: 'Select Event',
    lt: 'Select Event',
    ch: 'Select Event',
    ph: 'Select Event',
  },
  'Drag and drop the events to change the order they appear on your public portfolio page': {
    en: 'Drag and drop the events to change the order they appear on your public portfolio page',
    ja: 'Drag and drop the events to change the order they appear on your public portfolio page',
    et: 'Drag and drop the events to change the order they appear on your public portfolio page',
    lt: 'Drag and drop the events to change the order they appear on your public portfolio page',
    ch: 'Drag and drop the events to change the order they appear on your public portfolio page',
    ph: 'Drag and drop the events to change the order they appear on your public portfolio page',
  },
  'No events has been selected for this page yet': {
    en: 'No events has been selected for this page yet',
    ja: 'No events has been selected for this page yet',
    et: 'No events has been selected for this page yet',
    lt: 'No events has been selected for this page yet',
    ch: 'No events has been selected for this page yet',
    ph: 'No events has been selected for this page yet',
  },
  'Select and reorder events to put in the public page!': {
    en: 'Select and reorder events to put in the public page!',
    ja: 'Select and reorder events to put in the public page!',
    et: 'Select and reorder events to put in the public page!',
    lt: 'Select and reorder events to put in the public page!',
    ch: 'Select and reorder events to put in the public page!',
    ph: 'Select and reorder events to put in the public page!',
  },
  'Search Events': {
    en: 'Search Events',
    ja: 'Search Events',
    et: 'Search Events',
    lt: 'Search Events',
    ch: 'Search Events',
    ph: 'Search Events',
  },
  'No event found': {
    en: 'No event found',
    ja: 'No event found',
    et: 'No event found',
    lt: 'No event found',
    ch: 'No event found',
    ph: 'No event found',
  },
  'Add a heading for the event section (optional)': {
    en: 'Add a heading for the event section (optional)',
    ja: 'Add a heading for the event section (optional)',
    et: 'Add a heading for the event section (optional)',
    lt: 'Add a heading for the event section (optional)',
    ch: 'Add a heading for the event section (optional)',
    ph: 'Add a heading for the event section (optional)',
  },
  'Only public events are shown here': {
    en: 'Only public events are shown here',
    ja: 'Only public events are shown here',
    et: 'Only public events are shown here',
    lt: 'Only public events are shown here',
    ch: 'Only public events are shown here',
    ph: 'Only public events are shown here',
  },
  'Remove Event': {
    en: 'Remove Event',
    ja: 'Remove Event',
    et: 'Remove Event',
    lt: 'Remove Event',
    ch: 'Remove Event',
    ph: 'Remove Event',
  },
  'Selected! Scroll up to rearrange / remove event!': {
    en: 'Selected! Scroll up to rearrange / remove event!',
    ja: 'Selected! Scroll up to rearrange / remove event!',
    et: 'Selected! Scroll up to rearrange / remove event!',
    lt: 'Selected! Scroll up to rearrange / remove event!',
    ch: 'Selected! Scroll up to rearrange / remove event!',
    ph: 'Selected! Scroll up to rearrange / remove event!',
  },
  'All Events': {
    en: 'All Events',
    ja: 'All Events',
    et: 'All Events',
    lt: 'All Events',
    ch: 'All Events',
    ph: 'All Events',
  },
  'Selected User Profiles': {
    en: 'Selected User Profiles',
    ja: 'Selected User Profiles',
    et: 'Selected User Profiles',
    lt: 'Selected User Profiles',
    ch: 'Selected User Profiles',
    ph: 'Selected User Profiles',
  },
  'Select User': {
    en: 'Select User',
    ja: 'Select User',
    et: 'Select User',
    lt: 'Select User',
    ch: 'Select User',
    ph: 'Select User',
  },
  'Drag and drop the users to change the order they appear on your public portfolio page': {
    en: 'Drag and drop the users to change the order they appear on your public portfolio page',
    ja: 'Drag and drop the users to change the order they appear on your public portfolio page',
    et: 'Drag and drop the users to change the order they appear on your public portfolio page',
    lt: 'Drag and drop the users to change the order they appear on your public portfolio page',
    ch: 'Drag and drop the users to change the order they appear on your public portfolio page',
    ph: 'Drag and drop the users to change the order they appear on your public portfolio page',
  },
  'No users has been selected for this page yet': {
    ja: 'No users has been selected for this page yet',
    en: 'No users has been selected for this page yet',
    et: 'No users has been selected for this page yet',
    lt: 'No users has been selected for this page yet',
    ch: 'No users has been selected for this page yet',
    ph: 'No users has been selected for this page yet',
  },
  'Select and reorder users to put in the public page!': {
    en: 'Select and reorder users to put in the public page!',
    ja: 'Select and reorder users to put in the public page!',
    et: 'Select and reorder users to put in the public page!',
    lt: 'Select and reorder users to put in the public page!',
    ch: 'Select and reorder users to put in the public page!',
    ph: 'Select and reorder users to put in the public page!',
  },
  'Search Users': {
    en: 'Search Users',
    ja: 'Search Users',
    et: 'Search Users',
    lt: 'Search Users',
    ch: 'Search Users',
    ph: 'Search Users',
  },
  'No user found': {
    en: 'No user found',
    ja: 'No user found',
    et: 'No user found',
    lt: 'No user found',
    ch: 'No user found',
    ph: 'No user found',
  },
  'Add a heading for the user section (optional)': {
    en: 'Add a heading for the user section (optional)',
    ja: 'Add a heading for the user section (optional)',
    et: 'Add a heading for the user section (optional)',
    lt: 'Add a heading for the user section (optional)',
    ch: 'Add a heading for the user section (optional)',
    ph: 'Add a heading for the user section (optional)',
  },
  'Only users with public portfolio activated are shown here': {
    en: 'Only users with public portfolio activated are shown here',
    ja: 'Only users with public portfolio activated are shown here',
    et: 'Only users with public portfolio activated are shown here',
    lt: 'Only users with public portfolio activated are shown here',
    ch: 'Only users with public portfolio activated are shown here',
    ph: 'Only users with public portfolio activated are shown here',
  },
  'Remove User': {
    en: 'Remove User',
    ja: 'Remove User',
    et: 'Remove User',
    lt: 'Remove User',
    ch: 'Remove User',
    ph: 'Remove User',
  },
  'Selected! Scroll up to rearrange / remove user!': {
    en: 'Selected! Scroll up to rearrange / remove user!',
    ja: 'Selected! Scroll up to rearrange / remove user!',
    et: 'Selected! Scroll up to rearrange / remove user!',
    lt: 'Selected! Scroll up to rearrange / remove user!',
    ch: 'Selected! Scroll up to rearrange / remove user!',
    ph: 'Selected! Scroll up to rearrange / remove user!',
  },
  'All Users': {
    en: 'All Users',
    ja: 'All Users',
    et: 'All Users',
    lt: 'All Users',
    ch: 'All Users',
    ph: 'All Users',
  },
  'File is too large. Max File size: 8MB': {
    en: 'File is too large. Max File size: 8MB',
    ja: 'File is too large. Max File size: 8MB',
    et: 'File is too large. Max File size: 8MB',
    lt: 'File is too large. Max File size: 8MB',
    ch: 'File is too large. Max File size: 8MB',
    ph: 'File is too large. Max File size: 8MB',
  },
  'Delete Page': {
    en: 'Delete Page',
    ja: 'Delete Page',
    et: 'Delete Page',
    lt: 'Delete Page',
    ch: 'Delete Page',
    ph: 'Delete Page',
  },
  'Portfolio page deleted': {
    en: 'Portfolio page deleted',
    ja: 'Portfolio page deleted',
    et: 'Portfolio page deleted',
    lt: 'Portfolio page deleted',
    ch: 'Portfolio page deleted',
    ph: 'Portfolio page deleted',
  },
  'The portfolio page is not published yet': {
    en: 'The portfolio page is not published yet',
    ja: 'The portfolio page is not published yet',
    et: 'The portfolio page is not published yet',
    lt: 'The portfolio page is not published yet',
    ch: 'The portfolio page is not published yet',
    ph: 'The portfolio page is not published yet',
  },
  'Show author country flag on listed projects': {
    en: 'Show author country flag on listed projects',
    ja: 'Show author country flag on listed projects',
    et: 'Show author country flag on listed projects',
    lt: 'Show author country flag on listed projects',
    ch: 'Show author country flag on listed projects',
    ph: 'Show author country flag on listed projects',
  },
  'Show user full name instead of shortened name': {
    en: 'Show user full name instead of shortened name',
    ja: 'Show user full name instead of shortened name',
    et: 'Show user full name instead of shortened name',
    lt: 'Show user full name instead of shortened name',
    ch: 'Show user full name instead of shortened name',
    ph: 'Show user full name instead of shortened name',
  },
  'Show author profile on listed project': {
    en: 'Show author profile on listed project',
    ja: 'Show author profile on listed project',
    et: 'Show author profile on listed project',
    lt: 'Show author profile on listed project',
    ch: 'Show author profile on listed project',
    ph: 'Show author profile on listed project',
  },
};
